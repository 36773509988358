import { modals } from "@mantine/modals";
import React from "react";
import {
  createSearchParams,
  useLocation,
  useNavigate,
  useSearchParams,
} from "react-router-dom";
import { SPLIT_OPTIONS } from "src/components/EstimationView/Proposal/constants";
import EstimationVersionHistory from "src/components/Frames/EstimationVersionHistory";
import { Paths } from "src/constants/paths";
import { api } from "src/data/api/api";
import { TAG } from "src/data/api/utils";
import { useAppDispatch } from "src/data/store";

export const useEstimationVersionHistory = (
  estimationId: string,
  isEstimation: boolean = false,
) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const location = useLocation();
  const [params] = useSearchParams();

  const handleEstimationStart = React.useCallback(
    (newEstimationId: string) => {
      let pathname = Paths.ESTIMATIONS + Paths.SLASH + newEstimationId;

      if (isEstimation === true) {
        pathname =
          location.pathname.split("estimations")[0] +
          Paths.ESTIMATIONS +
          Paths.SLASH +
          newEstimationId;
      } else {
        //invalidate data
        dispatch(
          api.util.invalidateTags([
            TAG.BUNDLE,
            TAG.PACKAGE,
            TAG.COST_TYPES,
            TAG.PROFIT,
            TAG.TOTALS,
            TAG.ASSEMBLY,
            TAG.PROPOSAL,
            TAG.ATTACHMENT,
          ]),
        );

        params.append("split", SPLIT_OPTIONS.mid);
      }

      navigate({
        pathname,
        search: createSearchParams(params).toString(),
      });
    },
    [dispatch, isEstimation, location.pathname, navigate, params],
  );

  return React.useCallback(
    () =>
      modals.open({
        title: "Version history",
        size: "xl",
        children: (
          <EstimationVersionHistory
            estimationId={estimationId}
            openEstimation={handleEstimationStart}
          />
        ),
      }),
    [estimationId, handleEstimationStart],
  );
};
