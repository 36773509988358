import { DataTable } from "mantine-datatable";
import React, { useCallback, useMemo, useState } from "react";
import {
  EXPANDABLE,
  useCostTypeColumns,
} from "src/components/EstimationSummaryView/hooks/useCostTypeColumns";
import { useGroupColumns } from "src/components/EstimationSummaryView/hooks/useGroupColumns";
import { useMainColumns } from "src/components/EstimationSummaryView/hooks/useMainColumns";
import { useTradeColumns } from "src/components/EstimationSummaryView/hooks/useTradeColumns";
import { useGetPackageTotalsQuery } from "src/data/api/api";
import { PackageTotal } from "src/data/api/types/getPackageTotals";
import CostTypeInnerTable from "../CostTypeInnerTable";
import GroupsInnerTable from "../GroupInnerTable";
import TradesInnerTable from "../TradesInnerTable";
import styles from "./MarginViewTable.module.scss";

interface Props {
  selectedPackageId: string;
  distribution: string;
}

const MarginViewTable = ({ selectedPackageId, distribution }: Props) => {
  const [expandedLineItemNames, setExpandedLineItemNames] = useState<string[]>(
    [],
  );

  const { data, isLoading } = useGetPackageTotalsQuery({
    packageId: selectedPackageId,
    distribution,
  });

  const records = useMemo(() => {
    if (data == null) {
      return [];
    }

    if (distribution === "main") {
      return data.totals
        .filter((total) => total.name === "package")
        .map((total) => ({
          ...total,
          subtotal: (
            Number(total.subtotal) + Number(total.other_expenses || 0)
          ).toString(),
        }));
    }

    return data.totals.filter(
      (total) => total.name !== "package" && total.name !== "formula",
    );
  }, [data, distribution]);

  const costTypesColumns = useCostTypeColumns(records, expandedLineItemNames);
  const tradeColumns = useTradeColumns(records, expandedLineItemNames);
  const mainColumns = useMainColumns();
  const groupColumns = useGroupColumns(records, expandedLineItemNames);

  const columns = useMemo(() => {
    if (distribution === "cost_type") {
      return costTypesColumns;
    }

    if (distribution === "trade") {
      return tradeColumns;
    }

    if (distribution === "main") {
      return mainColumns;
    }

    if (distribution === "group") {
      return groupColumns;
    }

    return [];
  }, [costTypesColumns, distribution, groupColumns, mainColumns, tradeColumns]);

  const expandable = useCallback(
    ({ record }: { record: PackageTotal }) => {
      if (distribution === "cost_type") {
        return EXPANDABLE.includes(record.name);
      }

      if (["trade", "group"].includes(distribution)) {
        return true;
      }

      return false;
    },
    [distribution],
  );

  const handleInnerTable = useCallback(
    ({ record }: { record: PackageTotal }) => {
      if (distribution === "cost_type") {
        return <CostTypeInnerTable record={record} records={records} />;
      }

      if (distribution === "trade") {
        return (
          <TradesInnerTable packageId={selectedPackageId} record={record} />
        );
      }

      if (distribution === "group") {
        return <GroupsInnerTable record={record} />;
      }
    },
    [distribution, records, selectedPackageId],
  );

  return (
    <DataTable
      borderColor="var(--mantine-color-gray-2)"
      borderRadius="md"
      className=""
      classNames={{ header: styles.header }}
      columns={columns}
      defaultColumnProps={{
        textAlign: "right",
      }}
      fetching={isLoading}
      idAccessor={"name"}
      minHeight="80px"
      noRecordsIcon={<React.Fragment />}
      records={records}
      rowExpansion={{
        expanded: {
          recordIds: expandedLineItemNames,
          onRecordIdsChange: setExpandedLineItemNames,
        },
        expandable,
        allowMultiple: true,
        content: handleInnerTable,
      }}
      withColumnBorders
      withTableBorder
    />
  );
};

export default MarginViewTable;
