import { Droppable } from "@hello-pangea/dnd";
import { Loader, Stack } from "@mantine/core";
import { memo, useMemo } from "react";
import DragAndDropProvider from "src/context/DragAndDropProvider";
import ExpandableLineItemsProvider from "src/context/ExpandableLineItemsProvider";
import HoveredLineItemProvider from "src/context/HoveredLineItemProvider";
import ScopeProvider from "src/context/ScopeProvider";
import { useGetBundlesQuery } from "src/data/api/api";
import { BundleLineItem, PackageBundle } from "src/data/api/types/getBundles";
import { PackageId } from "src/data/api/types/getPackagesArgs";
import { isExpandable } from "src/utils/isExpandable";
import { SCROLLABLE_ELEMENT } from "src/utils/scrollableElement";
import EstimationBundle from "../EstimationBundle";
import StickyTableHeaders from "../StickyTableHeaders";
import styles from "./EstimationPackage.module.scss";
import { getId } from "./utils";

interface Props {
  packageId: string;
  packages: PackageId[];
}

const buildBundle =
  (lineItemsByBundle: Record<number, BundleLineItem[]>) =>
  (bundle: PackageBundle) => ({
    ...bundle,
    children: bundle.children
      .map((child) => ({
        ...child,
        attributes: {
          ...child.attributes,
          line_items: (lineItemsByBundle[child.attributes.id] ?? []).slice(),
        },
      }))
      .filter((child) => child.attributes.line_items.length > 0),
    line_items: (lineItemsByBundle[bundle.id] ?? []).slice(),
  });

const EstimationPackage = memo(({ packageId, packages }: Props) => {
  const { data, isLoading } = useGetBundlesQuery(packageId);

  const bundles = useMemo(() => {
    if (data == null) {
      return [];
    }

    return data.bundles
      .map((bundle) => bundle.attributes)
      .map(buildBundle(data.lineItemsByBundle))
      .filter(
        (bundle) => bundle.line_items.length > 0 || bundle.children?.length > 0,
      );
  }, [data]);

  const expandableGroups = useMemo(() => {
    return bundles
      .filter((bundle) => bundle.bundle_type === "group")
      .map(getId);
  }, [bundles]);

  const expandableItems = useMemo(() => {
    return Object.values(data?.lineItemsByBundle ?? {})
      .flat()
      .filter(isExpandable)
      .map(getId);
  }, [data?.lineItemsByBundle]);

  return (
    <ExpandableLineItemsProvider
      key={expandableGroups.concat(expandableItems).join(",")}
      initialGroups={expandableGroups}
      initialItems={expandableItems}
    >
      <Stack className={styles.root}>
        <StickyTableHeaders />
        {isLoading ? (
          <Loader />
        ) : (
          <ScopeProvider target={{ id: Number(packageId), type: "Package" }}>
            <HoveredLineItemProvider>
              <DragAndDropProvider bundles={bundles} packageId={packageId}>
                <Droppable droppableId={packageId} type="Bundle">
                  {(provider) => {
                    return (
                      <Stack
                        {...provider.droppableProps}
                        ref={provider.innerRef}
                        gap="6px"
                      >
                        {bundles.map((bundle, idx) => (
                          <EstimationBundle
                            key={bundle.id}
                            bundle={bundle}
                            currentIndex={idx}
                            packageId={packageId}
                            packages={packages}
                          />
                        ))}
                        {provider.placeholder}
                      </Stack>
                    );
                  }}
                </Droppable>
                <div id={SCROLLABLE_ELEMENT} />
              </DragAndDropProvider>
            </HoveredLineItemProvider>
          </ScopeProvider>
        )}
      </Stack>
    </ExpandableLineItemsProvider>
  );
});

export default EstimationPackage;
