import React, { createContext } from "react";
import { useGetPackageCrewMixesQuery } from "src/data/api/api";
import { LoadedCrewMix } from "src/data/api/types/shared/crewMix";
import { asMutableArray } from "src/utils/asMutableArray";
import { EMPTY_ARRAY } from "src/utils/empty";

interface ContextType {
  crewMixes: Array<LoadedCrewMix>;
  getCrewMix: (arg: string | null) => LoadedCrewMix | undefined;
}

export const PackageCrewMixContext = createContext<ContextType>({
  crewMixes: asMutableArray(EMPTY_ARRAY),
  getCrewMix: () => undefined,
});

interface Props {
  children: React.ReactNode;
  packageId: string;
}

const PackageCrewMixProvider = ({ children, packageId }: Props) => {
  const { currentData } = useGetPackageCrewMixesQuery(packageId);
  const value = React.useMemo(() => {
    return currentData?.collection.data ?? [];
  }, [currentData?.collection.data]);

  const getCrewMix = React.useCallback(
    (trade: string | null) =>
      value.find((crewMix) => trade === crewMix?.attributes.trade),
    [value],
  );

  return (
    <PackageCrewMixContext.Provider value={{ crewMixes: value, getCrewMix }}>
      {children}
    </PackageCrewMixContext.Provider>
  );
};

export default PackageCrewMixProvider;
