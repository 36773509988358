import { Button, Group } from "@mantine/core";
import { notifications } from "@mantine/notifications";
import React from "react";
import { EstimationResourceType } from "src/components/EstimationView/CrewMix/util/utils";
import { useAttachments } from "src/components/EstimationView/Table/hooks/useAttachments";
import { EMPTY_STRING } from "src/utils/empty";
import styles from "./AttachmentNotificationMessage.module.scss";
import { AttachmentParent } from "./utils";

interface Props extends Partial<AttachmentParent> {
  fileAmount: number;
  notificationId: string;
  successAmount?: number;
}

export const AttachmentNotificationMessage = React.memo<Props>(
  function _AttachmentNotificationMessage({
    fileAmount,
    notificationId,
    objectId,
    objectType,
    successAmount,
  }) {
    const [text, setText] = React.useState<string>(EMPTY_STRING);
    const openManageAttachments = useAttachments();

    React.useEffect(() => {
      if (successAmount == null) {
        setText(
          `Uploading ${fileAmount} ${fileAmount === 1 ? "file" : "files"}...`,
        );
      } else {
        setText(
          `${successAmount} ${successAmount === 1 ? "file" : "files"} successfully uploaded`,
        );
      }
    }, [fileAmount, successAmount]);

    const handleViewAttachment = React.useCallback(() => {
      notifications.hide(notificationId);
      if (objectId != null && objectType != null) {
        openManageAttachments({ objectId, objectType });
      }
    }, [notificationId, objectId, objectType, openManageAttachments]);

    return (
      <Group className={styles.root}>
        <span className={styles.mainText}>{text}</span>
        {successAmount != null &&
        objectType ===
          EstimationResourceType.LineItem /* don't show "View" if on profile views */ ? (
          <Button
            className={styles.navText}
            onClick={handleViewAttachment}
            variant="default"
          >
            View
          </Button>
        ) : null}
      </Group>
    );
  },
);
