import { Text } from "@mantine/core";
import classNames from "classnames";
import React from "react";
import { DEFAULT_PLACEHOLDER } from "src/components/EstimationView/Table/constants";
import { PackageTotal } from "src/data/api/types/getPackageTotals";
import { calculatePercent } from "src/utils/calculatePercent";
import { formatCurrency } from "src/utils/formatCurrency";
import { formatNumberHundredths } from "src/utils/formatNumberHundredths";
import styles from "./MarginCell.module.scss";

interface Props {
  record: PackageTotal;
  priceMethod: string | null;
}

export const MarginCell = React.memo<Props>(function _MarginCell({
  record,
  priceMethod,
}) {
  const formula = priceMethod ?? record.formula;

  const value: JSX.Element = React.useMemo(() => {
    if (record.apply_margin !== true) {
      return <>{DEFAULT_PLACEHOLDER}</>;
    }

    if (formula === record.formula) {
      const percent = calculatePercent(
        Number(record.margin),
        Number(record.grand_total),
      );

      return (
        <>
          {formatCurrency(Number(record.margin))}{" "}
          <span className={styles.subtext}>
            ({formatNumberHundredths(percent)} %)
          </span>
        </>
      );
    }

    return <>0.0 %</>;
  }, [
    formula,
    record.apply_margin,
    record.formula,
    record.grand_total,
    record.margin,
  ]);

  return (
    <Text
      className={classNames(
        styles.root,
        record.apply_margin !== true ? styles.placeholder : null,
      )}
    >
      {value}
    </Text>
  );
});
