import { NumberInput, NumberInputProps } from "@mantine/core";
import React from "react";
import { BundleLineItem } from "src/data/api/types/getBundles";
import { LineItem } from "src/data/api/types/updateBundleArgs";
import { EMPTY_STRING } from "src/utils/empty";
import { DEFAULT_PLACEHOLDER, VALUE_CELL_UNIT_DISPLAYS } from "./constants";
import { useCellKeystrokes } from "./hooks/useCellKeystrokes";
import styles from "./UnitCostCell.module.scss";

const UNIT_COST_CELL_INPUT_STYLES: NumberInputProps["styles"] = {
  input: {
    padding: "0",
    lineHeight: "1.5",
    textAlign: "right",
    fontSize: "var(--mantine-font-size-xs)",
    color: "var(--mantine-color-dark-6)",
    backgroundColor: "transparent",
    borderRadius: 0,
  },
};

interface Props {
  handleUpdate: (bundleId: number, lineItem: LineItem) => void;
  isEditable: boolean;
  record: BundleLineItem;
}

export const UnitCostCell = React.memo<Props>(function _UnitCostCell({
  handleUpdate,
  isEditable,
  record,
}) {
  const inputRef = React.useRef<HTMLInputElement>(null);
  const [value, setValue] = React.useState<string | number>(
    record.cost ? record.cost.toString() : EMPTY_STRING,
  );

  const { handleEnterEscapeBlur: handleKeyPresses } = useCellKeystrokes({
    ref: inputRef,
  });

  const disabled = React.useMemo(() => {
    return (
      isEditable === false ||
      ["% of subtotal", "calculated"].includes(
        record.quantity_unit ?? EMPTY_STRING,
      )
    );
  }, [isEditable, record.quantity_unit]);

  const updateUnitCost = React.useCallback(
    (costValue: string | number) => {
      handleUpdate(record.bundle_id, {
        id: record.id,
        cost: Number(costValue),
      });
    },
    [handleUpdate, record.bundle_id, record.id],
  );

  const handleBlur = React.useCallback(() => {
    updateUnitCost(value);
  }, [updateUnitCost, value]);

  return (
    <NumberInput
      ref={inputRef}
      className={styles.editMode}
      decimalScale={2}
      disabled={disabled}
      hideControls={true}
      onBlur={handleBlur}
      onChange={setValue}
      onKeyDown={handleKeyPresses}
      placeholder={`${VALUE_CELL_UNIT_DISPLAYS.DOLLAR}  ${DEFAULT_PLACEHOLDER}`}
      prefix="$ "
      styles={UNIT_COST_CELL_INPUT_STYLES}
      thousandSeparator=","
      value={value}
      variant="unstyled"
    />
  );
});
