import {
  Button,
  Group,
  SegmentedControl,
  Stack,
  Textarea,
  TextInput,
} from "@mantine/core";
import React from "react";
import { useForm } from "react-hook-form";
import { useCreateNewVersionMutation } from "src/data/api/api";
import { SelectEstimationVersion } from "./SelectEstimationVersion";

interface Props {
  estimationId: string;
  onClose: () => void;
  onSuccess?: (estimationId: string) => void;
}

type FormValue = {
  name: string;
  notes: string;
  version: string | null;
};

const CreateNewVersionForm = ({ onClose, estimationId, onSuccess }: Props) => {
  const [createVersion, { isLoading }] = useCreateNewVersionMutation();
  const [fromScratch, setFromScratch] = React.useState("true");
  const { register, handleSubmit, control } = useForm<FormValue>({
    defaultValues: {
      name: "",
      notes: "",
      version: null,
    },
  });

  const onSubmit = React.useCallback(
    (values: FormValue) => {
      const { version, name, notes } = values;

      createVersion({
        estimationId: version ? version : estimationId,
        version: {
          estimation: {
            title: name,
            as_current: true,
            as_clone: fromScratch === "true",
            quick_notes: notes,
          },
        },
      })
        .unwrap()
        .then(({ estimation }) => {
          onSuccess?.(estimation.data.id);
        })
        .then(() => {
          onClose();
        });
    },
    [createVersion, estimationId, fromScratch, onClose, onSuccess],
  );

  return (
    <Stack component="form" onSubmit={handleSubmit(onSubmit)}>
      <SegmentedControl
        color="var(--mantine-primary-color-9)"
        data={[
          { label: "Start from existing version", value: "true" },
          { label: "Start from blank state", value: "false" },
        ]}
        onChange={setFromScratch}
        value={fromScratch}
      />
      {fromScratch === "true" ? (
        <SelectEstimationVersion
          control={control}
          estimationId={estimationId}
        />
      ) : null}
      <TextInput label="Version name" required {...register("name")} />
      <Textarea
        autosize
        label="Add notes to the version (optional)"
        minRows={3}
        {...register("notes")}
      />
      <Group justify="end">
        <Button onClick={onClose} variant="outline">
          Cancel
        </Button>
        <Button loading={isLoading} type="submit">
          Create
        </Button>
      </Group>
    </Stack>
  );
};

export default CreateNewVersionForm;
