import { skipToken } from "@reduxjs/toolkit/query";
import React from "react";
import { api } from "src/data/api/api";
import { formatNumber } from "src/utils/formatNumber";
import { HOUR_TYPE_LOOKUP } from "../../CrewMix/constants";
import { LaborTypeTime } from "../../CrewMix/util/utils";

export function useBuildCrewMixLabels(crewMixId: number | null): Array<string> {
  const crewMix = api.endpoints.getCrewMix.useQuery(crewMixId ?? skipToken)
    .currentData?.crew_mix.data;

  const laborSource = api.endpoints.getLaborSource.useQuery(
    crewMix?.attributes.labor_source.id.toString() ?? skipToken,
  ).currentData?.labor_source.data;

  const labels = React.useMemo(() => {
    const percentageObj = crewMix?.attributes.crew_mix_percentages.find(
      (obj) => obj.percentage === 1,
    );

    return {
      classification: laborSource?.attributes.labor_types.find(
        (laborType) => laborType.id === percentageObj?.labor_type_id,
      )?.name,
      hourType:
        HOUR_TYPE_LOOKUP[percentageObj?.labor_type_time as LaborTypeTime],
      differential:
        parseFloat(crewMix?.attributes.differential ?? "0") === 0
          ? null
          : `shift diff ${formatNumber(parseFloat(crewMix?.attributes.differential ?? "0") * 100, 0, 1)}%`,
    };
  }, [crewMix, laborSource]);

  return Object.values(labels).filter((value) => value != null);
}
