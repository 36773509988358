import {
  CheckIcon,
  Combobox,
  Group,
  Pill,
  PillsInput,
  SelectProps,
  useCombobox,
} from "@mantine/core";
import React from "react";
import { api } from "src/data/api/api";
import { ById } from "src/types/byId";
import { EMPTY_ARRAY, EMPTY_STRING } from "src/utils/empty";

interface Props extends Omit<SelectProps, "value" | "onChange"> {
  readonly onChange: (value: ReadonlyArray<string>) => void;
  readonly value: ReadonlyArray<string>;
}

export const JobTypesMultiSelectField = React.memo(
  function SearchableMultiSelect({
    label,
    onChange,
    required,
    value,
    leftSection,
    error,
  }: Props) {
    const { currentData, isLoading } = api.endpoints.getBidOptions.useQuery();

    const jobTypeLabelByValue = React.useMemo(() => {
      const returns: ById<string> = {};
      for (const entry of currentData?.jobTypes ?? EMPTY_ARRAY) {
        // eslint-disable-next-line immutable/no-mutation
        returns[entry.value] = entry.label;
      }
      return returns;
    }, [currentData?.jobTypes]);

    const combobox = useCombobox({
      onDropdownClose: () => combobox.resetSelectedOption(),
      onDropdownOpen: () => combobox.updateSelectedOptionIndex("active"),
    });

    const [search, setSearch] = React.useState(EMPTY_STRING);

    const handleValueSelect = (selectedValue: string) => {
      onChange(
        value.includes(selectedValue)
          ? value.filter((entry) => entry !== selectedValue)
          : [...value, selectedValue],
      );
    };

    const handleValueRemove = (removedValue: string | undefined) => {
      onChange(value.filter((entry) => entry !== removedValue));
    };

    const filteredOptions =
      currentData?.jobTypes.filter((item) =>
        item.label.toLowerCase().includes(search.trim().toLowerCase()),
      ) ?? EMPTY_ARRAY;

    return (
      <Combobox onOptionSubmit={handleValueSelect} store={combobox}>
        <Combobox.DropdownTarget>
          <PillsInput
            disabled={isLoading}
            error={error}
            label={label}
            leftSection={leftSection}
            onClick={() => combobox.openDropdown()}
            required={required}
          >
            <Pill.Group>
              {value.map((item) => (
                <Pill
                  key={item}
                  onRemove={() => handleValueRemove(item)}
                  withRemoveButton
                >
                  {jobTypeLabelByValue[item]}
                </Pill>
              ))}

              <Combobox.EventsTarget>
                <PillsInput.Field
                  onBlur={() => combobox.closeDropdown()}
                  onChange={(event) => {
                    combobox.updateSelectedOptionIndex();
                    setSearch(event.currentTarget.value);
                  }}
                  onFocus={() => combobox.openDropdown()}
                  onKeyDown={(event) => {
                    if (event.key === "Backspace" && search.length === 0) {
                      event.preventDefault();
                      handleValueRemove(value[value.length - 1]);
                    }
                  }}
                  placeholder="Search values"
                  value={search}
                />
              </Combobox.EventsTarget>
            </Pill.Group>
          </PillsInput>
        </Combobox.DropdownTarget>

        <Combobox.Dropdown>
          <Combobox.Options>
            {filteredOptions.length > 0 ? (
              filteredOptions.map((item) => {
                const isSelected = value.includes(item.value);

                return (
                  <Combobox.Option
                    key={item.value}
                    active={isSelected}
                    value={item.value}
                  >
                    <Group gap="sm">
                      {isSelected ? <CheckIcon size={12} /> : undefined}
                      <span>{item.label}</span>
                    </Group>
                  </Combobox.Option>
                );
              })
            ) : (
              <Combobox.Empty>Nothing found...</Combobox.Empty>
            )}
          </Combobox.Options>
        </Combobox.Dropdown>
      </Combobox>
    );
  },
);
