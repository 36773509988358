import { Button, Group, Modal, TextInput } from "@mantine/core";
import { useInputState } from "@mantine/hooks";
import React from "react";
import { useParams } from "react-router-dom";
import { MatchParam } from "src/constants/matches";
import { useCreatePackageMutation } from "src/data/api/api";
import { EMPTY_STRING } from "src/utils/empty";
import { FormFooter } from "../Frames/FormFooter";

interface Props {
  onClose: () => void;
  onNewPackage: (v: string | null) => void;
}

const NewPackageDialog = React.memo<Props>(function _NewViewDialog({
  onClose,
  onNewPackage,
}) {
  const { estimationId } = useParams<MatchParam<"ESTIMATION_ID">>();
  const [packageName, setPackageName] = useInputState(EMPTY_STRING);
  const [createPackage, { isLoading }] = useCreatePackageMutation();

  const handleDialogClose = React.useCallback(() => {
    setPackageName(EMPTY_STRING);
    onClose();
  }, [onClose, setPackageName]);

  const handleViewSave = React.useCallback(() => {
    if (estimationId != null) {
      createPackage({
        package: {
          estimation_id: estimationId,
          title: packageName,
        },
      })
        .unwrap()
        .then((data) => {
          onNewPackage(data.package.data.id);
          onClose();
        });
    }
  }, [estimationId, createPackage, packageName, onNewPackage, onClose]);

  return (
    <Modal onClose={handleDialogClose} opened={true} title="Create new package">
      <TextInput
        label="Package name"
        onChange={setPackageName}
        value={packageName}
      />

      <FormFooter
        rightSection={
          <Group gap={10}>
            <Button onClick={handleDialogClose} variant="outline">
              Cancel
            </Button>
            <Button
              disabled={packageName.trim().length === 0}
              loading={isLoading}
              onClick={handleViewSave}
            >
              Save
            </Button>
          </Group>
        }
      />
    </Modal>
  );
});

export default NewPackageDialog;
