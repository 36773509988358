import CryptoJS from "crypto-js";

export function getMd5Checksum(file: File): Promise<string> {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    // eslint-disable-next-line immutable/no-mutation
    reader.onload = () => {
      const wordArray = CryptoJS.lib.WordArray.create(
        reader.result as ArrayBuffer,
      );

      const md5Hash = CryptoJS.MD5(wordArray).toString(CryptoJS.enc.Base64);
      resolve(md5Hash);
    };

    // eslint-disable-next-line immutable/no-mutation
    reader.onerror = (error) => reject(error);
    reader.readAsArrayBuffer(file);
  });
}
