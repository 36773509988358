import { useContext } from "react";
import { FeatureFlagContext, type ContextType } from "./FeatureFlagProvider";

export const useFeatureFlags = <T>(selector: (flags: ContextType) => T) => {
  const flags = useContext(FeatureFlagContext);
  if (flags == null) {
    throw new Error(
      "useFeatureFlags must be used within a FeatureFlagProvider",
    );
  }

  return selector(flags);
};
