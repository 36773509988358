import React, { createContext } from "react";
import { useQueryStringParams } from "src/hooks/useQueryStringParams";

export type ContextType = {
  dev: boolean;
  versions: boolean;
};

export const FeatureFlagContext = createContext<ContextType>({
  dev: false,
  versions: false,
});

interface Props {
  children: React.ReactNode;
}

const FeatureFlagProvider = ({ children }: Props) => {
  const [dev] = useQueryStringParams("dev");

  const value: ContextType = React.useMemo(() => {
    return {
      dev: dev === "true",
      versions: true,
    };
  }, [dev]);

  return (
    <FeatureFlagContext.Provider value={value}>
      {children}
    </FeatureFlagContext.Provider>
  );
};

export default FeatureFlagProvider;
