import { skipToken } from "@reduxjs/toolkit/query";
import { createContext } from "react";
import { useParams } from "react-router-dom";
import { LoadingScreen } from "src/components/Frames/LoadingScreen";
import { MatchParam } from "src/constants/matches";
import { api } from "src/data/api/api";
import { LoadedBid } from "src/data/api/types/shared/bid";
import { LoadingScreenType } from "src/types/loadingScreenType";

export const BidContext = createContext<LoadedBid | null>(null);

interface Props {
  children: React.ReactNode;
}

const BidProvider = ({ children }: Props) => {
  const { bidId } = useParams<MatchParam<"BID_ID">>();
  const bid = api.endpoints.getBid.useQuery(bidId ?? skipToken);

  if (bid.currentData == null) {
    return <LoadingScreen loadingScreenType={LoadingScreenType.Profile} />;
  }

  return (
    <BidContext.Provider value={bid.currentData.bid.data}>
      {children}
    </BidContext.Provider>
  );
};

export default BidProvider;
