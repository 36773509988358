import { useContext } from "react";
import { Estimation } from "src/data/api/types/shared/bid";
import { EstimationContext } from "./EstimationProvider";

export const useEstimationSelector = <T>(
  selector: (estimation: Estimation) => T,
) => {
  const estimation = useContext(EstimationContext);
  if (estimation == null) {
    throw new Error(
      "useEstimationSelector must be used within a EstimationProvider",
    );
  }

  return selector(estimation);
};
