import { Box } from "@mantine/core";

interface Props {
  version: number;
  padding?: string;
  color?: string;
  borderRadius?: string;
}

export const EstimationVersionNumber = ({
  version,
  padding = "2px 8px",
  color = "white",
  borderRadius = "8px",
}: Props) => {
  return (
    <Box
      style={{
        border: `1px solid ${color}`,
        padding,
        borderRadius,
      }}
    >
      V{version}
    </Box>
  );
};
