import { Button, Group } from "@mantine/core";
import { modals } from "@mantine/modals";
import { IconPlus } from "@tabler/icons-react";
import React from "react";
import { AttachmentParent } from "src/components/Frames/Attachments/utils";
import { EstimationResourceType } from "../../CrewMix/util/utils";
import styles from "../Attachments.module.scss";
import { ManageAttachmentsContent } from "../ManageAttachmentsContent";
import { useAddAttachment } from "./useAddAttachment";

export function useAttachments() {
  const addAttachment = useAddAttachment();
  return React.useCallback(
    ({ objectId, objectType }: AttachmentParent) => {
      modals.open({
        title: (
          <Group className={styles.header}>
            Attachments
            <Button
              className={styles.button}
              leftSection={<IconPlus size={16} />}
              onClick={() => addAttachment({ objectId, objectType })}
              styles={{
                inner: {
                  justifyContent: "flex-start",
                },
                section: {
                  marginRight: "4px",
                },
              }}
              variant="filled"
            >
              Upload file(s)
            </Button>
          </Group>
        ),
        centered: true,
        children: (
          <ManageAttachmentsContent
            className={styles.wrapper}
            objectId={objectId}
            objectType={EstimationResourceType.LineItem}
          />
        ),
        styles: {
          title: {
            width: "100%",
            marginRight: 12,
          },
          body: {
            padding: 0,
          },
        },
        closeOnEscape: false,
        transitionProps: {
          transition: "pop",
        },
        size: "100%",
      });
    },
    [addAttachment],
  );
}
