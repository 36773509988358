import { ActionIcon, Modal } from "@mantine/core";
import { IconDownload } from "@tabler/icons-react";
import classNames from "classnames";
import React from "react";
import { Attachment } from "src/data/api/types/shared/attachment";
import styles from "./AttachmentViewer.module.scss";
import { useMutateAttachment } from "./Table/hooks/useMutateAttachment";

interface Props {
  readonly attachment: Attachment;
  readonly onClose: () => void;
}

export const AttachmentViewer = React.memo<Props>(function _AttachmentViewer({
  attachment,
  onClose,
}) {
  const { onDownload } = useMutateAttachment();

  const handleDownloadAttachment = React.useCallback(() => {
    onDownload(attachment);
  }, [attachment, onDownload]);

  const { isImage, isPdf } = React.useMemo(() => {
    return {
      isImage: [
        "image/png",
        "image/jpeg",
        "image/bmp",
        "image/tiff",
        "image/webp",
      ].includes(attachment.content_type),
      isPdf: attachment.content_type === "application/pdf",
    };
  }, [attachment.content_type]);

  return (
    <Modal.Root
      closeOnClickOutside={false}
      fullScreen={true}
      onClose={onClose}
      opened={isImage || isPdf}
      zIndex={9999}
    >
      <Modal.Overlay>
        <Modal.Content className={styles.content}>
          <Modal.Header className={styles.header}>
            {!isPdf ? (
              <ActionIcon
                className={styles.downloadIcon}
                onClick={handleDownloadAttachment}
                variant="subtle"
              >
                <IconDownload size={20} />
              </ActionIcon>
            ) : null}
            <Modal.CloseButton className={styles.closeIcon} />
          </Modal.Header>

          <div
            className={classNames(
              styles.previewWrapper,
              isImage ? styles.imageWrapper : null,
            )}
          >
            {isPdf ? (
              <iframe
                className={styles.previewPdf}
                src={attachment.url}
              ></iframe>
            ) : null}

            {isImage ? (
              <img className={styles.previewImage} src={attachment.url} />
            ) : null}
          </div>
        </Modal.Content>
      </Modal.Overlay>
    </Modal.Root>
  );
});
