import { NumberInputProps } from "@mantine/core";
import { LaborTypeTime } from "./util/utils";

export const UNITS = {
  hour: "hr",
  hours: "hrs",
  people: "people",
  person: "person",
} as const;

export const ESCALATION_TOOLTIP_LABEL =
  "Rate escalation enables you to apply a fixed amount or percentage increase to the current composite rate, accommodating annual changes in union rates";

export const ESCALATION_TYPES = {
  percentage: "percentage",
  fixed: "fixed",
} as const;

export const TRADE_OPTIONS_FOR_LABOR = {
  electrical: "electrical",
  control: "control",
  piping: "piping",
  plumbing: "plumbing",
  projectManagement: "project_management",
  quote: "quote",
  service: "service",
  sheetMetal: "sheet_metal",
  sheetMetalFabrication: "sheet_metal_fabrication",
  startUp: "start_up",
  warehouse: "warehouse",
} as const;

export const DIFFERENTIAL_INPUT_STYLES: NumberInputProps["styles"] = {
  input: {
    textAlign: "right",
    paddingInlineEnd: "calc(42px + var(--mantine-spacing-xs))",
    fontSize: "var(--mantine-font-size-xs)",
  },
};

export const DEFAULT_CLASSIFICATION = "Default crew mix";

export const HOUR_TYPE_OPTIONS = [
  "Default hour allocation",
  "Straight time",
  "Over time",
  "Double time",
];

export const HOUR_TYPE_LOOKUP = {
  [LaborTypeTime.StraightTime]: "Straight time",
  [LaborTypeTime.OverTime]: "Over time",
  [LaborTypeTime.DoubleTime]: "Double time",
};
