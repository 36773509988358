import { Button, Group, Modal } from "@mantine/core";
import React from "react";
import { useForm } from "react-hook-form";
import { NewJobSiteFormContent } from "src/components/Forms/NewJobSiteFormContent";
import {
  DEFAULT_NEW_JOB_SITE_VALUE,
  NewJobSite,
} from "src/components/Forms/types/jobSite";
import { PersistenceType } from "src/components/Forms/types/persistenceType";
import { FormFooter } from "src/components/Frames/FormFooter";
import { api } from "src/data/api/api";
import {
  ACCOUNT_MANAGER_ROLE,
  Association,
  AssociationType,
  ResourceType,
} from "src/data/api/types/bulkTransaction";
import { selectCurrentCompanyId } from "src/data/selectors/auth";
import { useAppSelector } from "src/data/store";
import { showBulkTransactionNotifications } from "src/utils/notifications/showBulkTransactionNotifications";

type FormData = NewJobSite["data"];

interface Props {
  readonly onClose: () => void;
}

export const NewJobSiteDialog = React.memo<Props>(function _NewJobSiteDialog({
  onClose,
}) {
  const currentCompanyId = useAppSelector(selectCurrentCompanyId);
  const [trigger, mutationState] = api.endpoints.bulkTransaction.useMutation();

  const { control, handleSubmit } = useForm<FormData>({
    defaultValues: DEFAULT_NEW_JOB_SITE_VALUE,
  });

  const handleJobSiteCreate = React.useCallback(
    async (submittedValue: FormData) => {
      try {
        const jobSiteId = crypto.randomUUID();

        if (submittedValue.address == null) {
          throw new Error();
        }

        const associations: Array<Association> = [];
        for (const siteContact of submittedValue.siteContacts) {
          if (siteContact.contact == null) {
            return;
          }

          const contactId =
            siteContact.contact.type === PersistenceType.EXISTING
              ? siteContact.contact.data.uuid
              : siteContact.contact.uuid;

          associations.push({
            type: AssociationType.ContactRole,
            contact_id: contactId,
            resource_type: ResourceType.JobSite,
            resource_id: jobSiteId,
            metadata: {
              name: siteContact.influence,
            },
          });
        }

        if (submittedValue.accountManager != null) {
          associations.push({
            type: AssociationType.Role,
            user_id: submittedValue.accountManager,
            resource_type: ResourceType.JobSite,
            resource_id: jobSiteId,
            metadata: {
              name: ACCOUNT_MANAGER_ROLE,
            },
          });
        }

        await trigger({
          bulk_transaction: {
            associations,
            job_sites_attributes: [
              {
                uuid: jobSiteId,
                build_class: submittedValue.buildingClass,
                company_id: currentCompanyId,
                address_attributes: {
                  full_address: submittedValue.address.text,
                  google_id: submittedValue.address.id,
                },
              },
            ],
          },
        }).unwrap();
        onClose();
      } catch (error) {
        showBulkTransactionNotifications(error);
      }
    },
    [currentCompanyId, onClose, trigger],
  );

  return (
    <Modal
      centered={true}
      onClose={onClose}
      opened={true}
      size="lg"
      title="Create new job site"
    >
      <NewJobSiteFormContent control={control} shouldRenderContacts={true} />

      <FormFooter
        rightSection={
          <Group gap={10}>
            <Button onClick={onClose} variant="outline">
              Cancel
            </Button>
            <Button
              loading={mutationState.isLoading}
              onClick={handleSubmit(handleJobSiteCreate)}
            >
              Create job site
            </Button>
          </Group>
        }
      />
    </Modal>
  );
});
