import { DataTable } from "mantine-datatable";
import React from "react";
import { asMutableArray } from "src/utils/asMutableArray";
import { EMPTY_ARRAY, EMPTY_STRING } from "src/utils/empty";
import styles from "./CrewMixTable.module.scss";
import { useCrewMixColumnDefById } from "./hooks/useCrewMixColumnDefById";
import {
  CrewMixEntry,
  SetClassificationEntryArgs,
  SetCrewMixEntryArgs,
} from "./hooks/useCrewMixData";
import { CrewMixMethodType, HoursMethodType } from "./util/utils";

interface Props {
  readonly crewMixMethod: CrewMixMethodType;
  readonly displayTooltips: boolean;
  readonly getCrewMixEntry: (arg: string) => CrewMixEntry | undefined;
  readonly hoursMethod: HoursMethodType;
  readonly setClassificationEntry: (arg: SetClassificationEntryArgs) => void;
  readonly setCrewMixEntry: (arg: SetCrewMixEntryArgs) => void;
  readonly trade: string;
}

export const CrewMixTable = React.memo<Props>(function _CrewMixTable({
  crewMixMethod,
  displayTooltips,
  getCrewMixEntry,
  hoursMethod,
  setClassificationEntry,
  setCrewMixEntry,
  trade,
}) {
  const crewMix = getCrewMixEntry(trade);
  const columns = useCrewMixColumnDefById({
    crewMixMethod,
    displayTooltips,
    getCrewMixEntry,
    hoursMethod,
    setClassificationEntry,
    setCrewMixEntry,
    trade,
  });
  return (
    <DataTable
      classNames={{
        header: styles.header,
        table: styles.table,
      }}
      columns={columns}
      defaultColumnProps={{ textAlign: "right", width: "15%" }}
      height="fit-content"
      idAccessor="classificationId"
      noRecordsIcon={<div style={{ display: "none" }} />}
      noRecordsText={EMPTY_STRING}
      records={asMutableArray(crewMix?.classificationEntries ?? EMPTY_ARRAY)}
      rowClassName={styles.row}
      withColumnBorders={true}
      withRowBorders={true}
    />
  );
});
