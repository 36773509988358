import { Select, SelectProps } from "@mantine/core";
import React from "react";
import { api } from "src/data/api/api";
import { LabelValue } from "src/types/util/labelValue";
import { EMPTY_ARRAY } from "src/utils/empty";
import { DEFAULT_SELECT_PLACEHOLDER } from "./constants";

type FieldValue = string | null;

interface Props extends Omit<SelectProps, "onChange" | "value"> {
  readonly onChange: (value: FieldValue) => void;
  readonly value: FieldValue;
}

export const BranchField = React.memo<Props>(function _BranchField({
  onChange,
  value,
  ...props
}) {
  const company = api.endpoints.getCompany.useQuery();

  const branchOptions = React.useMemo((): ReadonlyArray<LabelValue> => {
    return (
      company.currentData?.company.data.attributes.branches.map((entry) => {
        return {
          label: entry.name,
          value: entry.id.toString(),
        };
      }) ?? EMPTY_ARRAY
    );
  }, [company.currentData?.company.data.attributes.branches]);

  return (
    <Select
      checkIconPosition="right"
      clearable={true}
      data={branchOptions}
      disabled={company.isLoading || company.isFetching}
      label="Branch"
      onChange={onChange}
      placeholder={DEFAULT_SELECT_PLACEHOLDER}
      required={true}
      value={value}
      withScrollArea={false}
      {...props}
    />
  );
});
