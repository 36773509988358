import { Button, Text } from "@mantine/core";
import { modals } from "@mantine/modals";
import { notifications } from "@mantine/notifications";
import React, { useCallback, useMemo } from "react";
import {
  createSearchParams,
  useNavigate,
  useSearchParams,
} from "react-router-dom";
import { SPLIT_OPTIONS } from "src/components/EstimationView/Proposal/constants";
import { Paths } from "src/constants/paths";
import { api, useUpdateEstimationMutation } from "src/data/api/api";
import { CalculatedByType, Estimation } from "src/data/api/types/shared/bid";
import { TAG } from "src/data/api/utils";
import { useAppDispatch } from "src/data/store";

interface Props {
  readonly estimation: Estimation;
}

export const EstimationButton = React.memo(function _EstimationButton({
  estimation,
}: Props) {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [params] = useSearchParams();
  const [updateEstimation, { isLoading }] = useUpdateEstimationMutation();

  const handleEstimationStart = useCallback(() => {
    //invalidate data
    dispatch(
      api.util.invalidateTags([
        TAG.BUNDLE,
        TAG.PACKAGE,
        TAG.COST_TYPES,
        TAG.PROFIT,
        TAG.TOTALS,
        TAG.ASSEMBLY,
        TAG.PROPOSAL,
        TAG.ATTACHMENT,
      ]),
    );

    params.append("split", SPLIT_OPTIONS.mid);

    navigate({
      pathname: Paths.ESTIMATIONS + Paths.SLASH + estimation.id,
      search: createSearchParams(params).toString(),
    });
  }, [dispatch, estimation.id, navigate, params]);

  const handleEstimationUpdate = useCallback(async () => {
    try {
      estimation.attributes.calculated_by !== CalculatedByType.System &&
        (await updateEstimation({
          id: estimation.attributes.id,
          estimation: {
            calculated_by: CalculatedByType.System,
          },
        }));

      handleEstimationStart();
    } catch (err) {
      notifications.show({
        title: "Error",
        message: "Could not update",
      });
    }
  }, [
    estimation.attributes.calculated_by,
    estimation.attributes.id,
    handleEstimationStart,
    updateEstimation,
  ]);

  const showConfirmation = useCallback(() => {
    modals.openConfirmModal({
      title: "Confirmation",
      children: (
        <Text>
          You are about to re-activate estimation. This will tie your Total Sell
          Price to the estimation’s Base Sell Price. Do you want to continue?
        </Text>
      ),
      labels: { confirm: "Confirm", cancel: "Cancel" },
      onConfirm: handleEstimationUpdate,
    });
  }, [handleEstimationUpdate]);

  const handleClick = useCallback(() => {
    if (estimation.attributes.calculated_by === CalculatedByType.Inactive) {
      showConfirmation();
    } else {
      handleEstimationUpdate();
    }
  }, [
    estimation.attributes.calculated_by,
    handleEstimationUpdate,
    showConfirmation,
  ]);

  const buttonName = useMemo(() => {
    if (estimation.attributes.calculated_by === CalculatedByType.System) {
      return "Open";
    }

    if (estimation.attributes.calculated_by === CalculatedByType.Inactive) {
      return "Inactive";
    }

    return "Start";
  }, [estimation.attributes.calculated_by]);

  return (
    <Button
      color={
        buttonName === "Inactive"
          ? "grey"
          : "var(--mantine-primary-color-filled)"
      }
      loading={isLoading}
      onClick={handleClick}
      opacity={buttonName === "Inactive" ? 0.5 : 1}
      variant="outline"
    >
      {buttonName}
    </Button>
  );
});
