/* eslint-disable no-param-reassign */
/* eslint-disable immutable/no-mutation */
import { type FetchBaseQueryMeta } from "@reduxjs/toolkit/query/react";
import { MaybeDrafted } from "node_modules/@reduxjs/toolkit/dist/query/core/buildThunks";
import { buildLineItems } from "src/utils/transfromGetBundlesResponseV3";
import {
  BundleLineItem,
  GetBundlesReturns,
  LineItemsUpdatesData,
  PackageBundleReturnData,
} from "./types/getBundles";
import { UpdateBundleArgs } from "./types/updateBundleArgs";

export function getAuthToken(
  _baseQueryReturnValue: unknown,
  meta: FetchBaseQueryMeta,
): string | undefined {
  const authHeader = meta?.response?.headers.get("Authorization") ?? undefined;

  if (authHeader == null) {
    return;
  }

  return authHeader.split("Bearer ")[1];
}

export async function setAuthToken(
  queryFulfilled: Promise<{ data: string | undefined }>,
) {
  const { data: token } = await queryFulfilled;

  if (token != null) {
    localStorage.setItem("token", token);
  }
}

export function removeAuthToken() {
  localStorage.removeItem("token");
}

const handleUpdate = (
  draft: MaybeDrafted<GetBundlesReturns>,
  record: BundleLineItem,
) => {
  draft.lineItemsByBundle[record.bundle_id] = (
    draft.lineItemsByBundle[record.bundle_id] || []
  ).map((item) => {
    if (item.id === record.id) {
      return {
        ...item,
        ...record,
      };
    }

    return {
      ...item,
      children: item.children.map((child) => {
        if (child.id === record.id) {
          return {
            ...child,
            ...record,
          };
        }

        return {
          ...child,
          children: child.children.map((grandChild) => {
            if (grandChild.id === record.id) {
              return {
                ...child,
                ...record,
              };
            }

            return grandChild;
          }),
        };
      }),
    };
  });
};

const handleDelete = (
  draft: MaybeDrafted<GetBundlesReturns>,
  record: BundleLineItem,
) => {
  if (record.parent_id == null) {
    draft.lineItemsByBundle[record.bundle_id] = (
      draft.lineItemsByBundle[record.bundle_id] || []
    ).filter((item) => {
      item.id !== record.id;
    });
  }

  if (record.parent_id != null) {
    draft.lineItemsByBundle[record.bundle_id] = (
      draft.lineItemsByBundle[record.bundle_id] || []
    ).map((item) => {
      if (item.id === record.parent_id) {
        return {
          ...item,
          children: item.children.filter((child) => child.id !== record.id),
        };
      }

      return {
        ...item,
        children: item.children.map((child) => {
          if (child.id === record.parent_id) {
            return {
              ...child,
              children: child.children.filter(
                (grandChild) => grandChild.id !== record.id,
              ),
            };
          }

          return child;
        }),
      };
    });

    draft.bundles = draft.bundles.filter((bundle) => {
      if (bundle.attributes.bundle_type === "group") {
        return (
          bundle.attributes.children.filter(
            (innerBundle) =>
              (draft.lineItemsByBundle[innerBundle.attributes.id]?.length ??
                0) > 0,
          ).length > 0
        );
      }

      return (draft.lineItemsByBundle[bundle.attributes.id]?.length ?? 0) > 0;
    });
  }
};

const handleInsert = (
  draft: MaybeDrafted<GetBundlesReturns>,
  record: BundleLineItem,
) => {
  const lineItems = draft.lineItemsByBundle[record.bundle_id];
  if (lineItems == null) return;

  if (record.parent_id == null) {
    draft.lineItemsByBundle[record.bundle_id] = lineItems.concat({
      ...record,
      children: [],
    });
  }

  if (record.parent_id != null) {
    draft.lineItemsByBundle[record.bundle_id] = lineItems.map((item) => {
      //handle child
      if (item.id === record.parent_id) {
        return {
          ...item,
          children: item.children.concat({
            ...record,
            children: [],
          }),
        };
      }

      return {
        ...item,
        children: item.children.map((child) => {
          //handle inline labor
          if (child.id === record.parent_id) {
            return {
              ...child,
              children: child.children.concat({
                ...record,
                children: [],
              }),
            };
          }

          return child;
        }),
      };
    });
  }
};

export const updateBundlesCacheData = (
  draft: MaybeDrafted<GetBundlesReturns>,
  data: LineItemsUpdatesData,
) => {
  if (data.action === "INSERT") {
    handleInsert(draft, data.record);
  }

  if (data.action === "UPDATE") {
    handleUpdate(draft, data.record);
  }

  if (data.action === "DELETE") {
    handleDelete(draft, data.old_record);
  }
};

export const updateCacheOnCreateLineItem = (
  draft: MaybeDrafted<GetBundlesReturns>,
  bundleData: PackageBundleReturnData,
) => {
  if (bundleData.attributes.parent_id != null) {
    draft.bundles.forEach((bundle, index, bundles) => {
      if (bundle.attributes.id === bundleData.attributes.parent_id) {
        bundles[index] = {
          ...bundle,
          attributes: {
            ...bundle.attributes,
            children: bundle.attributes.children.concat(bundleData),
          },
        };
      }
    });
  } else {
    draft.bundles.push(bundleData);
  }

  draft.lineItemsByBundle = {
    ...draft.lineItemsByBundle,
    ...buildLineItems({
      collection: {
        data: [bundleData],
      },
    }),
  };
};

export const updateCacheOnUpdateBundle = (
  draft: MaybeDrafted<GetBundlesReturns>,
  bundleData: PackageBundleReturnData,
) => {
  draft.bundles.forEach((bundle, index, bundles) => {
    if (bundle.id === bundleData.id) {
      bundles[index] = bundleData;
    }
  });
};

export const TAG = {
  BID: "Bid",
  CONTACT: "Contact",
  COST_TYPES: "CostTypes",
  CREW_MIX: "CrewMix",
  CUSTOMER: "Customer",
  JOB_SITE: "JobSite",
  LIST_VIEW: "ListView",
  PACKAGE: "Package",
  BUNDLE: "Bundle",
  PROFIT: "Profit",
  TOTALS: "Totals",
  USER: "User",
  ASSEMBLY: "Assembly",
  COST_TYPE_BY_TRADE: "CostTypesByTrade",
  PROPOSAL: "Proposal",
  ATTACHMENT: "Attachment",
  ESTIMATION_VERSIONS: "VersionsPerEstimation",
} as const;

type TagValue = (typeof TAG)[keyof typeof TAG];

export const invalidatesTagsOnUpdateBundle = (payload: UpdateBundleArgs) => {
  const tags: TagValue[] = [TAG.TOTALS, TAG.PACKAGE];

  if (
    payload.line_item.line_items_attributes?.some((lineItem) =>
      ["_destroy", "cost_type"].some((key) => key in lineItem),
    )
  ) {
    tags.push(TAG.COST_TYPES);
  }
  return tags;
};

export const updateCacheOnAssemblyAttach = (
  draft: MaybeDrafted<GetBundlesReturns>,
  bundleData: PackageBundleReturnData[],
) => {
  Object.assign(draft, {
    ...draft,
    lineItemsByBundle: {
      ...draft.lineItemsByBundle,
      ...buildLineItems({
        collection: { data: bundleData },
      }),
    },
    bundles: draft.bundles.concat(bundleData),
  });
};

export const updateCacheOnDeleteBundle = (
  draft: MaybeDrafted<GetBundlesReturns>,
  bundleData: PackageBundleReturnData,
) => {
  Object.assign(draft, {
    ...draft,
    bundles: draft.bundles.filter((bundle) => bundle.id !== bundleData.id),
  });
};
