import React, { createContext } from "react";
import { useParams } from "react-router-dom";
import { MatchParam } from "src/constants/matches";
import { Estimation } from "src/data/api/types/shared/bid";
import { useBidSelector } from "../BidProvider";

export const EstimationContext = createContext<Estimation | null>(null);

interface Props {
  children: React.ReactNode;
}

const EstimationProvider = ({ children }: Props) => {
  const bid = useBidSelector((theBid) => theBid);
  const { estimationId } = useParams<MatchParam<"ESTIMATION_ID">>();

  const estimation = React.useMemo(() => {
    return bid.attributes.estimations.find(
      (_estimation) => _estimation.id === estimationId,
    );
  }, [bid?.attributes.estimations, estimationId]);

  if (estimation == null) {
    return null;
  }

  return (
    <EstimationContext.Provider value={estimation}>
      {children}
    </EstimationContext.Provider>
  );
};

export default EstimationProvider;
