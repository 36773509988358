import { Button, Group, Menu } from "@mantine/core";
import { IconPlus } from "@tabler/icons-react";
import React from "react";
import { ResourceCacheType } from "src/data/types/resourceCacheType";
import { ById } from "src/types/byId";
import { LabelValue } from "src/types/util/labelValue";
import { FilterMenu } from "./FilterMenu";

interface Props {
  readonly filterSetById: ById<ReadonlySet<string>>;
  readonly labelById: ById<string>;
  readonly onFilterSetByIdChange: React.Dispatch<
    React.SetStateAction<ById<ReadonlySet<string>>>
  >;
  readonly optionsOrResourceTypeById: ById<
    ReadonlyArray<LabelValue> | ResourceCacheType
  >;
}

export const FilterHeader = React.memo<Props>(function _FilterHeader({
  filterSetById,
  labelById,
  onFilterSetByIdChange,
  optionsOrResourceTypeById,
}) {
  const handleClearAll = React.useCallback(() => {
    onFilterSetByIdChange((prev) => {
      const output: ById<Set<string>> = {};
      for (const [key, value] of Object.entries(prev)) {
        if (value != null) {
          // eslint-disable-next-line immutable/no-mutation
          output[key] = new Set();
        }
      }
      return output;
    });
  }, [onFilterSetByIdChange]);

  return (
    <Group justify="space-between" p="16px 20px">
      <Group gap={10}>
        {Object.entries(filterSetById).map(([key, filterSet]) => {
          return (
            <FilterMenu
              key={key}
              filterSet={filterSet}
              keyName={key}
              label={labelById[key]}
              onFilterSetByIdChange={onFilterSetByIdChange}
              optionsOrResourceType={optionsOrResourceTypeById[key]}
            />
          );
        })}
        <Menu shadow="md" width={200}>
          <Menu.Target>
            <Button
              disabled={
                Object.keys(filterSetById).length >=
                Object.keys(optionsOrResourceTypeById).length
              }
              leftSection={<IconPlus size={14} />}
              size="xs"
              variant="subtle"
            >
              Add Filter
            </Button>
          </Menu.Target>

          <Menu.Dropdown>
            {Object.keys(optionsOrResourceTypeById)?.map((key) => {
              if (filterSetById[key] != null) {
                return undefined;
              }

              return (
                <Menu.Item
                  key={key}
                  onClick={() => {
                    onFilterSetByIdChange((prev) => ({
                      ...prev,
                      [key]: new Set(),
                    }));
                  }}
                >
                  {labelById[key]}
                </Menu.Item>
              );
            })}
          </Menu.Dropdown>
        </Menu>
        <Button onClick={handleClearAll} size="xs" variant="subtle">
          Clear all
        </Button>
      </Group>
    </Group>
  );
});
