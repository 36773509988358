import { Button } from "@mantine/core";
import { IconStar } from "@tabler/icons-react";
import React from "react";
import { api } from "src/data/api/api";
import { SortState } from "src/types/sortState";
import {
  ALL_BIDS_VIEW,
  BID_VIEW_TYPE,
  BidColumnId,
  MY_BIDS_VIEW,
} from "../constants";
import styles from "./SaveViewButton.module.scss";

interface Props {
  readonly columnOrder: ReadonlyArray<string>;
  readonly currentViewId?: string;
  readonly filterSetById: Partial<Record<BidColumnId, ReadonlySet<string>>>;
  readonly sortState: SortState<string> | undefined;
}

export const SaveViewButton = React.memo<Props>(function _SaveViewButton({
  columnOrder,
  currentViewId,
  filterSetById,
  sortState,
}) {
  const [updateListView, { isLoading }] =
    api.endpoints.updateListView.useMutation();

  const handleViewUpdate = React.useCallback(() => {
    if (currentViewId == null) {
      return;
    }

    const filtersById: Partial<Record<BidColumnId, ReadonlyArray<string>>> = {};

    Object.entries(filterSetById).forEach(([columnId, filterSet]) => {
      if (filterSet == null || filterSet.size === 0) {
        return;
      }

      // eslint-disable-next-line immutable/no-mutation
      filtersById[columnId as BidColumnId] = Array.from(filterSet);
    });

    updateListView({
      listViewId: currentViewId,
      body: {
        list_view: {
          filters: { filtersById, sort: sortState },
          elements: columnOrder,
          view: BID_VIEW_TYPE,
        },
      },
    });
  }, [columnOrder, currentViewId, filterSetById, sortState, updateListView]);

  return (
    <Button
      classNames={{ label: styles.button }}
      disabled={
        currentViewId === MY_BIDS_VIEW || currentViewId === ALL_BIDS_VIEW
      }
      loading={isLoading}
      onClick={handleViewUpdate}
      variant="outline"
    >
      <IconStar size={16} /> Save current view
    </Button>
  );
});
