import React from "react";
import { useCreateLineItemMutation } from "src/data/api/api";
import { useScrollIntoView } from "src/hooks/useScrollIntoView";
import { SCROLLABLE_ELEMENT } from "src/utils/scrollableElement";

interface Returns {
  createGroupBundle: () => void;
  createLineItemBundle: () => void;
}

export function useCreateBundle(packageId: string | null): Returns {
  const [createBundle] = useCreateLineItemMutation();
  const scrollIntoView = useScrollIntoView(SCROLLABLE_ELEMENT, 200);
  const createGroupBundle = React.useCallback(() => {
    if (packageId != null) {
      createBundle({
        bundle: {
          package_id: packageId,
          bundle_type: "group",
        },
      }).then(scrollIntoView);
    }
  }, [createBundle, packageId, scrollIntoView]);

  const createLineItemBundle = React.useCallback(() => {
    if (packageId != null) {
      createBundle({
        bundle: {
          package_id: packageId,
          bundle_type: "line_item",
        },
      }).then(scrollIntoView);
    }
  }, [createBundle, packageId, scrollIntoView]);

  return { createGroupBundle, createLineItemBundle };
}
