import { notifications } from "@mantine/notifications";
import {
  IconCheck,
  IconClipboardCopy,
  IconCopy,
  IconCut,
  IconLogout,
  IconPackages,
  IconPaperclip,
  IconSettings,
  IconSitemap,
  IconSubtask,
  IconTrash,
  IconUsers,
} from "@tabler/icons-react";
import { ContextMenuContent } from "mantine-contextmenu";
import React from "react";
import { useSelector } from "react-redux";
import { useExpandableLineItems } from "src/context/ExpandableLineItemsProvider";
import { useSelectedLineItems } from "src/context/SelectedLineItemsProvider";
import {
  api,
  useCloneItemsMutation,
  useDeleteLineItemsMutation,
} from "src/data/api/api";
import {
  BundleLineItem,
  PackageBundleReturnData,
} from "src/data/api/types/getBundles";
import { PackageId } from "src/data/api/types/getPackagesArgs";
import { UpdateBundleArgs } from "src/data/api/types/updateBundleArgs";
import { useQueryStringParams } from "src/hooks/useQueryStringParams";
import { EMPTY_STRING } from "src/utils/empty";
import { useLineItemCrewMix } from "../../CrewMix/hooks/useLineItemCrewMix";
import { EstimationResourceType } from "../../CrewMix/util/utils";
import {
  CONTEXT_MENU_ICON_SIZE,
  CONTEXT_MENU_KEYSTROKE_STYLES,
  KEYSTROKES,
  PACKAGE_PARAM,
} from "../constants";
import { useAddAttachment } from "./useAddAttachment";
import { useAttachments } from "./useAttachments";
import { useCreateAssembly } from "./useCreateAssembly";

const isLinable = (costType: string) => {
  const inlinable = ["material", "equipment"];

  return inlinable.includes(costType);
};

const findBundleById = (data: PackageBundleReturnData[], id: number) => {
  const found = data.find((item) => item.attributes.id === id);

  if (found != null) return found;

  return data
    .filter((item) => item.attributes.bundle_type === "group")
    .map((item) => item.attributes.children)
    .flat()
    .find((item) => item.attributes.id === id);
};

export const useLineItemsContextMenu = (
  packages: PackageId[],
  updateLineItem: (arg: UpdateBundleArgs) => void,
) => {
  const [packageId] = useQueryStringParams(PACKAGE_PARAM);
  const { data } = useSelector(
    api.endpoints.getBundles.select(packageId ?? ""),
  );
  const openCreateAssembly = useCreateAssembly();
  const { setAction, setSelectedItems, selectedItems, action } =
    useSelectedLineItems();
  const { setExpandedItems } = useExpandableLineItems();
  const [cloneItems] = useCloneItemsMutation();
  const [deleteLineItems] = useDeleteLineItemsMutation();
  const openManageAttachments = useAttachments();
  const addAttachment = useAddAttachment();
  const { setLineItemCrewMix, deleteLineItemCrewMix } = useLineItemCrewMix();

  const deleteSelected = React.useCallback(() => {
    if (packageId == null) return;

    deleteLineItems({
      packageId,
      lineItems: {
        line_item_ids: selectedItems.map((item) => item.id),
      },
    });

    setSelectedItems([]);
  }, [deleteLineItems, packageId, selectedItems, setSelectedItems]);

  const deleteLineItem = React.useCallback(
    (record: BundleLineItem) => {
      updateLineItem({
        bundle_id: record.bundle_id,
        line_item: {
          line_items_attributes: [
            {
              id: record.id,
              _destroy: true,
            },
          ],
        },
      });

      setSelectedItems((items) =>
        items.filter((item) => item.id !== record.id),
      );
    },
    [setSelectedItems, updateLineItem],
  );

  const addSubItem = React.useCallback(
    (record: BundleLineItem) => {
      updateLineItem({
        bundle_id: record.bundle_id,
        line_item: {
          line_items_attributes: [
            {
              id: undefined,
              parent_id: record.id,
              package_id: record.package_id,
            },
          ],
        },
      });
      setExpandedItems((items) =>
        items.filter((item) => item !== record.id).concat(record.id),
      );
    },
    [setExpandedItems, updateLineItem],
  );

  const addInlineItem = React.useCallback(
    (record: BundleLineItem) => {
      updateLineItem({
        bundle_id: record.bundle_id,
        line_item: {
          line_items_attributes: [
            {
              id: undefined,
              parent_id: record.id,
              package_id: record.package_id,
              cost_type: "labor",
              display_attributes: {
                display: "inline",
              },
            },
          ],
        },
      });
    },
    [updateLineItem],
  );

  const handleCopy = React.useCallback(() => {
    setAction("copy");
    notifications.show({
      title: `Copied to clipboard`,
      message: "",
      position: "top-center",
      autoClose: 2000,
      icon: <IconCheck />,
    });
  }, [setAction]);

  const handleCut = React.useCallback(() => {
    setAction("cut");
    notifications.show({
      title: `Copied to clipboard`,
      message: "",
      position: "top-center",
      autoClose: 2000,
      icon: <IconCheck />,
    });
  }, [setAction]);

  const handleMove = React.useCallback(
    (locationId: number) => {
      const originalPackageId = selectedItems[0]?.package_id.toString();

      if (originalPackageId == null) return;

      cloneItems({
        action_type: "cut",
        bundle_ids: [],
        package_ids: [],
        line_item_ids: selectedItems.map((selected) => ({
          id: selected.id,
          bundle_id: selected.bundle_id,
        })),
        meta: {
          packageId: originalPackageId,
        },
        location: {
          type: "Package",
          id: locationId,
        },
      });
      setAction(null);
      setSelectedItems([]);
    },
    [cloneItems, selectedItems, setAction, setSelectedItems],
  );

  const handlePaste = React.useCallback(
    (record: BundleLineItem) => {
      if (action == null) return;
      if (packageId == null) return;

      const originalPackageId = selectedItems[0]?.package_id.toString();

      if (originalPackageId == null) return;

      const bundle = findBundleById(data?.bundles ?? [], record.bundle_id);

      if (bundle == null) return;

      const position = bundle.attributes.position ?? 0;
      const location = {
        type: bundle.attributes.parent_id ? "Bundle" : "Package",
        id: bundle.attributes.parent_id
          ? bundle.attributes.parent_id
          : Number(packageId),
      };

      const unitBundles = [
        ...new Set(selectedItems.map((selected) => selected.bundle_id)),
      ];

      cloneItems({
        action_type: action,
        bundle_ids: unitBundles.map((uniq, index) => ({
          id: uniq,
          position: position + index + 1,
        })),
        meta: {
          packageId: originalPackageId,
          placeBelowBundleId: record.bundle_id,
        },
        package_ids: [],
        line_item_ids: [],
        location,
      });
      setAction(null);
      setSelectedItems([]);
    },
    [
      action,
      cloneItems,
      data?.bundles,
      packageId,
      selectedItems,
      setAction,
      setSelectedItems,
    ],
  );

  return React.useCallback(
    (
      record: BundleLineItem,
      selectedRecords: BundleLineItem[],
    ): ContextMenuContent => {
      const isClickedItemSelected =
        selectedRecords.findIndex((r) => r.id === record.id) > -1;

      const isClickedItemNotSelected =
        selectedRecords.findIndex((r) => r.id === record.id) === -1;

      const isSelectionNonEmpty = selectedRecords.length > 0;
      const isSelectionEmpty = selectedRecords.length < 1;
      const isLaborClicked = record.cost_type === "labor";

      return [
        {
          key: "add_labor",
          icon: <IconSitemap size={CONTEXT_MENU_ICON_SIZE} />,
          title: "Add Labor",
          color: "green",
          hidden: isSelectionNonEmpty && isClickedItemSelected,
          disabled: record.parent_id != null || !isLinable(record.cost_type),
          onClick: () => addInlineItem(record),
        },
        {
          key: "add_sub_item",
          icon: <IconSubtask size={CONTEXT_MENU_ICON_SIZE} />,
          title: "Add sub-item",
          color: "blue",
          hidden: isSelectionNonEmpty && isClickedItemSelected,
          disabled: record.parent_id != null || !isLinable(record.cost_type),
          onClick: () => addSubItem(record),
        },
        {
          key: "divider01",
          hidden: isSelectionNonEmpty && isClickedItemSelected, // hide if above items hidden
        },
        {
          key: "add_attachments",
          icon: <IconPaperclip size={CONTEXT_MENU_ICON_SIZE} />,
          title: "Add attachment",
          color: "var(--mantine-color-dark-6)",
          hidden: isSelectionNonEmpty,
          onClick: () =>
            addAttachment({
              objectId: record.id,
              objectType: EstimationResourceType.LineItem,
            }),
        },
        {
          key: "manage_attachments",
          icon: <IconSettings size={CONTEXT_MENU_ICON_SIZE} />,
          title: "Manage attachments",
          color: "var(--mantine-color-dark-6)",
          hidden: isSelectionNonEmpty,
          onClick: () =>
            openManageAttachments({
              objectId: record.id,
              objectType: EstimationResourceType.LineItem,
            }),
        },
        { key: "divider02", hidden: isSelectionNonEmpty },
        {
          key: "specify_crew_and_hour_type",
          icon: <IconUsers size={CONTEXT_MENU_ICON_SIZE} />,
          title: "Specify crew and hour type",
          color: "var(--mantine-color-dark-6)",
          hidden: !isLaborClicked || isSelectionNonEmpty,
          disabled: record.trade === EMPTY_STRING || record.trade == null,
          onClick: () => setLineItemCrewMix(record),
        },
        {
          key: "remove_crew_and_hour_type",
          icon: <IconTrash size={CONTEXT_MENU_ICON_SIZE} />,
          title: "Remove crew and hour type",
          color: "var(--mantine-color-dark-6)",
          hidden:
            !isLaborClicked ||
            isSelectionNonEmpty ||
            record.crew_mix_id == null,
          onClick: () => deleteLineItemCrewMix(record),
        },
        {
          key: "divider03",
          hidden: !isLaborClicked || isSelectionNonEmpty,
        },
        {
          key: "save_as_assembly",
          icon: <IconPackages size={CONTEXT_MENU_ICON_SIZE} />,
          title: `Save as assembly ${selectedRecords.length} item(s)`,
          color: "blue",
          hidden: isSelectionEmpty || isClickedItemNotSelected,
          onClick: openCreateAssembly,
        },
        {
          key: "divider04",
          hidden: isSelectionEmpty || isClickedItemNotSelected,
        },
        {
          key: "copy",
          icon: <IconCopy size={CONTEXT_MENU_ICON_SIZE} />,
          title: `Copy ${selectedRecords.length} item(s)`,
          color: "green",
          hidden: isSelectionEmpty || isClickedItemNotSelected,
          onClick: handleCopy,
        },
        {
          key: "cut",
          icon: <IconCut size={CONTEXT_MENU_ICON_SIZE} />,
          title: `Cut ${selectedRecords.length} item(s)`,
          color: "blue",
          hidden: isSelectionEmpty || isClickedItemNotSelected,
          onClick: handleCut,
        },
        {
          key: "move_to",
          icon: <IconLogout size={CONTEXT_MENU_ICON_SIZE} />,
          title: `Move ${selectedRecords.length} item(s) to`,
          hidden: isSelectionEmpty || isClickedItemNotSelected,
          items: packages
            .filter((_package) => _package.id !== Number(packageId))
            .map((_package) => ({
              key: _package.id.toString(),
              title: _package.title,
              style: {
                width: "241px",
              },
              onClick: () => handleMove(_package.id),
            })),
        },
        {
          key: "paste_below",
          icon: <IconClipboardCopy size={CONTEXT_MENU_ICON_SIZE} />,
          title: `Paste below ${selectedRecords.length} item(s)`,
          hidden: isSelectionEmpty || isClickedItemSelected,
          onClick: () => handlePaste(record),
        },
        {
          key: "divider05",
          hidden:
            (isSelectionEmpty || isClickedItemNotSelected) &&
            (isSelectionEmpty ||
              isClickedItemSelected ||
              (isSelectionNonEmpty && isClickedItemSelected)),
        }, // hide if above or below sections are hidden
        {
          key: "delete_selected",
          icon: <IconTrash size={CONTEXT_MENU_ICON_SIZE} />,
          title: `Delete ${selectedRecords.length} item(s)`,
          color: "red",
          hidden: isSelectionEmpty || isClickedItemNotSelected,
          iconRight: (
            <span style={CONTEXT_MENU_KEYSTROKE_STYLES}>
              {KEYSTROKES.CTRL_D}
            </span>
          ),
          onClick: deleteSelected,
        },
        {
          key: "delete",
          icon: <IconTrash size={CONTEXT_MENU_ICON_SIZE} />,
          title: "Delete",
          color: "red",
          hidden: isSelectionNonEmpty && isClickedItemSelected,
          onClick: () => deleteLineItem(record),
        },
      ];
    },
    [
      addAttachment,
      addInlineItem,
      addSubItem,
      deleteLineItem,
      deleteLineItemCrewMix,
      deleteSelected,
      handleCopy,
      handleCut,
      handleMove,
      handlePaste,
      openCreateAssembly,
      openManageAttachments,
      packageId,
      packages,
      setLineItemCrewMix,
    ],
  );
};
