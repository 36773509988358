import { Button, Group, Modal } from "@mantine/core";
import React from "react";
import { useForm } from "react-hook-form";
import { NewCustomerFormContent } from "src/components/Forms/NewCustomerFormContent";
import {
  DEFAULT_NEW_CUSTOMER_VALUE,
  NewCustomer,
} from "src/components/Forms/types/customer";
import { FormFooter } from "src/components/Frames/FormFooter";
import { api } from "src/data/api/api";
import {
  ACCOUNT_MANAGER_ROLE,
  Association,
  AssociationType,
  ResourceType,
} from "src/data/api/types/bulkTransaction";
import { selectCurrentCompanyId } from "src/data/selectors/auth";
import { useAppSelector } from "src/data/store";
import { showBulkTransactionNotifications } from "src/utils/notifications/showBulkTransactionNotifications";

type FormValues = NewCustomer["data"];

interface Props {
  readonly onClose: () => void;
}

export const NewCustomerDialog = React.memo<Props>(function _NewCustomerDialog({
  onClose,
}) {
  const currentCompanyId = useAppSelector(selectCurrentCompanyId);
  const [trigger, mutationState] = api.endpoints.bulkTransaction.useMutation();

  const { control, handleSubmit } = useForm<FormValues>({
    defaultValues: DEFAULT_NEW_CUSTOMER_VALUE,
  });

  const handleNewCustomerCreate = React.useCallback(
    async (submittedValue: FormValues) => {
      try {
        const customerId = crypto.randomUUID();
        const associations: Array<Association> = [];
        if (submittedValue.accountManager != null) {
          associations.push({
            type: AssociationType.Role,
            user_id: submittedValue.accountManager,
            resource_type: ResourceType.Customer,
            resource_id: customerId,
            metadata: {
              name: ACCOUNT_MANAGER_ROLE,
            },
          });
        }

        await trigger({
          bulk_transaction: {
            associations,
            customers_attributes: [
              {
                uuid: customerId,
                company_id: currentCompanyId,
                name: submittedValue.name,
                industry_id: submittedValue.industry,
                vista_customer_id: submittedValue.vistaCustomerId,
              },
            ],
          },
        }).unwrap();
        onClose();
      } catch (error) {
        showBulkTransactionNotifications(error);
      }
    },
    [currentCompanyId, onClose, trigger],
  );

  return (
    <Modal
      centered={true}
      onClose={onClose}
      opened={true}
      size="lg"
      title="Create new customer"
    >
      <NewCustomerFormContent control={control} />

      <FormFooter
        rightSection={
          <Group gap={10}>
            <Button onClick={onClose} variant="outline">
              Cancel
            </Button>
            <Button
              loading={mutationState.isLoading}
              onClick={handleSubmit(handleNewCustomerCreate)}
            >
              Create customer
            </Button>
          </Group>
        }
      />
    </Modal>
  );
});
