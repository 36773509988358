export function truncateFilename(filename: string, maxLength: number): string {
  if (filename.length <= maxLength) return filename;
  const extIndex = filename.lastIndexOf(".") + 1;
  if (extIndex === -1 || extIndex < filename.length - 5) {
    // Truncate normally if no extension, or extension too long
    return filename.slice(0, maxLength - 3) + "...";
  }

  const namePart = filename.slice(
    0,
    maxLength - (filename.length - extIndex) - 3,
  );

  return `${namePart}...${filename.slice(extIndex)}`;
}
