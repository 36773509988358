import { GetBidsArgs } from "src/data/api/types/getBids";
import {
  Contact,
  Customer,
  Estimation,
  LoadedBid,
} from "src/data/api/types/shared/bid";
import { FilterEntry } from "src/data/api/types/shared/filterSort";
import { Sort } from "src/data/api/types/shared/sort";
import { ById } from "src/types/byId";
import { Mutable } from "src/types/mutable";
import { BidColumnId, FilterByColumn } from "../constants";

export function getBidFilterKeyFromColumnId(key: BidColumnId) {
  switch (key) {
    case BidColumnId.BID_STAGE:
      return "bid_stage";
    case BidColumnId.BRANCH_ID:
      return "branch_id";
    case BidColumnId.CONSTRUCTION_TYPE:
      return "construction_type";
    case BidColumnId.CONTACTS:
      return "contacts";
    case BidColumnId.CUSTOMERS:
      return "customers";
    case BidColumnId.JOB_TYPES:
      return "estimations";
    case BidColumnId.JOB_SITE_ADDRESS:
      return "job_site";
    case BidColumnId.PRIORITY:
      return "priority";
    case BidColumnId.RELATIONSHIP:
      return "relationship";
    case BidColumnId.SALES_LEAD:
      return "estimators";
    case BidColumnId.STATUS:
      return "estimations";
    case BidColumnId.CREATED_BY:
      return "created_by";
    case BidColumnId.UPDATED_BY:
      return "updated_by";
  }
}

export function getBidSortFromColumnId(
  key: BidColumnId | undefined,
  sort: Sort | undefined,
) {
  if (key == null || sort == null) {
    return;
  }

  switch (key) {
    case BidColumnId.BID_NUMBER:
      return { id: sort };
    case BidColumnId.BID_STAGE:
      return { bid_stage: sort };
    case BidColumnId.BRANCH_ID:
      return { branch_id: sort };
    case BidColumnId.CONSTRUCTION_TYPE:
      return { construction_type: sort };
    case BidColumnId.CONTACTS:
      return { contacts: { first_name: sort } };
    case BidColumnId.CREATED_AT:
      return { created_at: sort };
    case BidColumnId.CREATED_BY:
      return { created_by: { first_name: sort } };
    case BidColumnId.CUSTOMERS:
      return { customers: { name: sort } };
    case BidColumnId.DUE_DATE:
      return { customer_bids: { due_at: sort } };
    case BidColumnId.JOB_SITE_ADDRESS:
      return { address: { full_address: sort } };
    case BidColumnId.JOB_TYPES:
      return { estimations: { job_types: sort } };
    case BidColumnId.JOB_WALK_DATE:
      return { customer_bids: { job_walk_at: sort } };
    case BidColumnId.NAME:
      return { name: sort };
    case BidColumnId.PRIORITY:
      return { priority: sort };
    case BidColumnId.RELATIONSHIP:
      return { relationship: sort };
    case BidColumnId.SALES_LEAD:
      return { estimators: { first_name: sort } };
    case BidColumnId.STATUS:
      return { estimations: { status: sort } };
    case BidColumnId.TOTAL_SELL_AMOUNT:
      return { estimations: { total: sort } };
    case BidColumnId.UPDATED_AT:
      return { updated_at: sort };
    case BidColumnId.UPDATED_BY:
      return { updated_by: { first_name: sort } };
  }
}

export const BID_LABEL_BY_ID = {
  [BidColumnId.BID_NUMBER]: "Bid number",
  [BidColumnId.BID_STAGE]: "Bid stage",
  [BidColumnId.BRANCH_ID]: "Branch",
  [BidColumnId.CONSTRUCTION_TYPE]: "Construction type",
  [BidColumnId.CONTACTS]: "Contact",
  [BidColumnId.CREATED_AT]: "Created at",
  [BidColumnId.CREATED_BY]: "Created by",
  [BidColumnId.CUSTOMERS]: "Customer",
  [BidColumnId.DUE_DATE]: "Due date",
  [BidColumnId.JOB_SITE_ADDRESS]: "Job site address",
  [BidColumnId.JOB_SITE_ADDRESS_DETAILS]: "Suite/floor",
  [BidColumnId.JOB_TYPES]: "Job type",
  [BidColumnId.JOB_WALK_DATE]: "Walk date",
  [BidColumnId.NAME]: "Project name",
  [BidColumnId.PRIORITY]: "Priority",
  [BidColumnId.RELATIONSHIP]: "Relationship",
  [BidColumnId.SALES_LEAD]: "Salesperson",
  [BidColumnId.STATUS]: "Status",
  [BidColumnId.TOTAL_SELL_AMOUNT]: "Total sell amount",
  [BidColumnId.UPDATED_AT]: "Updated at",
  [BidColumnId.UPDATED_BY]: "Updated by",
};

export function getFilterEntriesFromFilterSetById(
  filterSetById: Partial<Record<BidColumnId, ReadonlySet<string>>>,
) {
  const and: Mutable<NonNullable<GetBidsArgs["search"]>["and"]> = [];
  for (const [key, filterSet] of Object.entries(filterSetById)) {
    if (filterSet != null && filterSet.size > 0) {
      const or: Array<FilterEntry> = [];

      for (const filterValue of filterSet) {
        const filterKey = getBidFilterKeyFromColumnId(key as BidColumnId);

        if (filterKey != null) {
          or.push({
            [filterKey]: filterValue,
          });
        }
      }

      and.push({ or });
    }
  }

  return and;
}

export function getFilterSetByIdFromFiltersById(
  filtersById?: ById<ReadonlyArray<string>>,
) {
  if (filtersById == null) {
    return;
  }

  const filterSetById: ById<Set<string>> = {};

  Object.entries(filtersById).forEach(([columnId, filters]) => {
    if (filters == null) {
      return;
    }

    // eslint-disable-next-line immutable/no-mutation
    filterSetById[columnId] = new Set(filters);
  });

  return filterSetById;
}

//general filtering
function filtering<T>(
  filters: string[],
  onFilter: (data: T, filters: string[]) => boolean,
) {
  return function (data: T) {
    if (filters.length === 0) return true;
    return onFilter(data, filters);
  };
}

function filterUsers(estimation: Estimation, userUuids: string[]) {
  return estimation.users.every((user) => {
    return userUuids.includes(user.uuid);
  });
}

function filterJobTypes(estimation: Estimation, jobTypes: string[]) {
  return (
    estimation.attributes.job_types?.some((jobType) => {
      return jobTypes.includes(jobType);
    }) ?? false
  );
}

function filterStatuses(estimation: Estimation, statusFilters: string[]) {
  return statusFilters.includes(estimation.attributes.status);
}

function filterCustomers(customer: Customer, customerUuids: string[]) {
  return customerUuids.includes(customer.uuid);
}

function filterContacts(contact: Contact, contactUuids: string[]) {
  return contactUuids.includes(contact.uuid);
}

function transformEstimations(
  estimations: ReadonlyArray<Estimation>,
  filters: FilterByColumn,
): ReadonlyArray<Estimation> {
  let filteredEstimations = estimations.slice();

  if (filters.SALES_LEAD != null) {
    const userUuids = Array.from(filters.SALES_LEAD);

    if (userUuids.length > 0) {
      filteredEstimations = filteredEstimations.filter(
        filtering<Estimation>(userUuids, filterUsers),
      );
    }
  }

  if (filters.STATUS != null) {
    const statusFilters = Array.from(filters.STATUS);

    if (statusFilters.length > 0) {
      filteredEstimations = filteredEstimations.filter(
        filtering<Estimation>(statusFilters, filterStatuses),
      );
    }
  }

  if (filters.JOB_TYPES != null) {
    const jobTypesFilter = Array.from(filters.JOB_TYPES);

    if (jobTypesFilter.length > 0) {
      filteredEstimations = filteredEstimations
        .filter(filtering<Estimation>(jobTypesFilter, filterJobTypes))
        .map((estimation) => {
          return {
            ...estimation,
            attributes: {
              ...estimation.attributes,
              job_types: estimation.attributes.job_types?.filter((jobType) => {
                return jobTypesFilter.includes(jobType);
              }),
            },
          };
        });
    }
  }

  return filteredEstimations;
}

function transformCustomers(
  customers: ReadonlyArray<Customer>,
  filters: FilterByColumn,
): ReadonlyArray<Customer> {
  if (filters.CUSTOMERS == null) return customers;

  const userUuids = Array.from(filters.CUSTOMERS);

  if (userUuids.length > 0) {
    return customers;
  }

  return customers.filter(filtering<Customer>(userUuids, filterCustomers));
}

function transformContacts(
  contacts: ReadonlyArray<Contact>,
  filters: FilterByColumn,
): ReadonlyArray<Contact> {
  if (filters.CONTACTS == null) return contacts;

  const userUuids = Array.from(filters.CONTACTS);

  if (userUuids.length == 0) {
    return contacts;
  }

  return contacts.filter(
    filtering<Contact>(Array.from(filters.CONTACTS), filterContacts),
  );
}

export function transformBidBasedOnFilters(
  bid: LoadedBid,
  filters: FilterByColumn,
) {
  return {
    ...bid,
    attributes: {
      ...bid.attributes,
      contacts: transformContacts(bid.attributes.contacts, filters),
      estimations: transformEstimations(bid.attributes.estimations, filters),
      customers: transformCustomers(bid.attributes.customers, filters),
    },
  };
}
