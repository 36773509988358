import { Button, Group, Modal } from "@mantine/core";
import React from "react";
import { useForm } from "react-hook-form";
import { FormFooter } from "src/components/Frames/FormFooter";
import { api } from "src/data/api/api";
import { LoadedContact } from "src/data/api/types/shared/contact";
import { selectCurrentCompanyId } from "src/data/selectors/auth";
import { useAppSelector } from "src/data/store";
import { showBulkTransactionNotifications } from "src/utils/notifications/showBulkTransactionNotifications";
import { NewContactFormContent } from "../../../Forms/NewContactFormContent";
import { NewContact } from "../../../Forms/types/contact";

type FormValue = NewContact["data"];

interface Props {
  readonly contact: LoadedContact;
  readonly onClose: () => void;
}

export const EditContactDialog = React.memo<Props>(function _EditContactDialog({
  contact,
  onClose,
}) {
  const { control, handleSubmit } = useForm<FormValue>({
    defaultValues: {
      email: contact.attributes.email,
      firstName: contact.attributes.first_name,
      lastName: contact.attributes.last_name,
      phoneNumber: contact.attributes.phone,
    },
  });
  const currentCompanyId = useAppSelector(selectCurrentCompanyId);
  const [trigger, mutationState] = api.endpoints.bulkTransaction.useMutation();

  const handleContactEdit = React.useCallback(
    async (submittedValue: FormValue) => {
      try {
        await trigger({
          bulk_transaction: {
            contacts_attributes: [
              {
                id: contact.attributes.id,
                company_id: currentCompanyId,
                email: submittedValue.email,
                first_name: submittedValue.firstName,
                last_name: submittedValue.lastName,
                phone: submittedValue.phoneNumber,
              },
            ],
          },
        }).unwrap();
        onClose();
      } catch (error) {
        showBulkTransactionNotifications(error);
      }
    },
    [contact.attributes.id, currentCompanyId, onClose, trigger],
  );

  return (
    <Modal
      centered={true}
      onClose={onClose}
      opened={true}
      size="lg"
      title="Edit existing contact"
    >
      <NewContactFormContent control={control} />

      <FormFooter
        rightSection={
          <Group gap={10}>
            <Button onClick={onClose} variant="outline">
              Cancel
            </Button>
            <Button
              loading={mutationState.isLoading}
              onClick={handleSubmit(handleContactEdit)}
            >
              Edit contact
            </Button>
          </Group>
        }
      />
    </Modal>
  );
});
