import { ActionIcon, Group, Menu } from "@mantine/core";
import { IconHistory, IconPlus } from "@tabler/icons-react";
import React from "react";
import { useCreateEstimationVersion } from "src/hooks/useCreateEstimationVersion";
import { useEstimationVersionHistory } from "src/hooks/useEstimationVersionHistory";

interface Props1 {
  estimationId: string;
}

export const VersionHistoryIcon = ({ estimationId }: Props1) => {
  const openCreateNewVersion = useCreateEstimationVersion(estimationId, true);
  const openVersionHistory = useEstimationVersionHistory(estimationId, true);

  const methods = React.useMemo(() => {
    return [
      {
        key: "create",
        name: "Create a new version",
        Icon: IconPlus,
        onClick: openCreateNewVersion,
      },
      {
        key: "manager",
        name: "Version history",
        Icon: IconHistory,
        onClick: openVersionHistory,
      },
    ].map(({ name, onClick, Icon, key }) => (
      <Menu.Item key={key} onClick={onClick}>
        <Group gap="xs">
          <Icon size={14} strokeWidth={3} />
          {name}
        </Group>
      </Menu.Item>
    ));
  }, [openCreateNewVersion, openVersionHistory]);

  return (
    <Menu>
      <Menu.Target>
        <ActionIcon size="sm" variant="transparent">
          <IconHistory color="white" />
        </ActionIcon>
      </Menu.Target>

      <Menu.Dropdown>{methods}</Menu.Dropdown>
    </Menu>
  );
};
