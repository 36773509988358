import { Attachment } from "src/data/api/types/shared/attachment";

export interface StateAttachment extends Attachment {
  isLoading: boolean;
  isRename: boolean;
}

export const ATTACHMENT_TYPE_OPTIONS = {
  Drawing: "Drawing",
  Specification: "Specification",
  VendorQuote: "Vendor quote",
  SubcontractorQuote: "Subcontractor quote",
  Image: "Image",
  Schedule: "Schedule",
  RFI: "RFI",
  Submittal: "Submittal",
  Proposal: "Proposal",
  Contract: "Contract",
};
