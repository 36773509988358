import { Group, Stack, Text } from "@mantine/core";
import { useMemo } from "react";
import { useEstimationSelector } from "src/context/EstimationProvider";
import { capitalize } from "src/utils/capitalize";
import { inputs } from "../../constants";
import PercentageInput from "../PercentageInput/PercentageInput";

interface Props {
  method: string;
  level: string;
  percentage: Record<string, number>;
  onChange: (key: string, value: number) => void;
}

const PercentageInputGroup = ({
  method,
  level,
  percentage,
  onChange,
}: Props) => {
  const isFrozen = useEstimationSelector(
    (estimation) => estimation.attributes.state_freeze,
  );

  const items = useMemo(() => {
    return inputs
      .filter((input) => input.method === method)
      .filter((input) => input.level === level)
      .map((input) => (
        <PercentageInput
          key={input.label + "_" + method}
          data={input}
          disabled={isFrozen}
          initialValue={percentage[input.value]}
          limit={method === "margin" ? 100 : Infinity}
          onChange={onChange}
        />
      ));
  }, [level, method, onChange, percentage, isFrozen]);

  return (
    <Stack>
      <Text
        fw="bolder"
        fz="sm"
      >{`Enter ${capitalize(method)} % by ${capitalize(level)}`}</Text>
      <Group wrap="wrap">{items}</Group>
    </Stack>
  );
};

export default PercentageInputGroup;
