import { CKEditor, useCKEditorCloud } from "@ckeditor/ckeditor5-react";
import React from "react";
import { WithClassname } from "src/types/withClassName";
import { EMPTY_STRING } from "src/utils/empty";
import "../../../../public/EstimationProposal.css";
import type { EditorConfig } from "@ckeditor/ckeditor5-core";
import { useElementSize } from "@mantine/hooks";
import { DecoupledEditor as DecoupledEditorType } from "ckeditor5";
import { EventInfo } from "ckeditor5/src/utils.js";
import classNames from "classnames";
import { LoadingScreen } from "src/components/Frames/LoadingScreen";
import { LoadingScreenType } from "src/types/loadingScreenType";
import {
  HEADER_AND_FOOTER_CSS,
  MATRIX_FOOTER,
  MATRIX_HEADER,
} from "./constants";

const PROD_URL = import.meta.env.VITE_SERVER_URL;
const LICENSE_KEY = import.meta.env.VITE_CKE_LICENSE;

interface CustomEditorConfig extends EditorConfig {
  pagination?: {
    pageWidth: string;
    pageHeight: string;
    pageMargins: {
      top: string;
      bottom: string;
      right: string;
      left: string;
    };
  };
  exportPdf?: {
    tokenUrl?: string;
    stylesheets: string[];
    fileName: string;
    converterOptions: {
      format: string;
      margin_top: string;
      margin_bottom: string;
      margin_right: string;
      margin_left: string;
      page_orientation: string;
      header_html?: string;
      footer_html?: string;
      header_and_footer_css?: string;
    };
  };
}

interface Props extends WithClassname {
  readonly componentHeight?: number;
  readonly componentWidth?: number;
  readonly content: string | null;
  readonly isReadonly?: boolean;
  readonly onChange?: (arg: string) => void;
  readonly setFocusState?: (arg: boolean) => void;
  readonly title?: string;
  readonly disabled: boolean;
}

export const EstimationProposal = React.memo<Props>(
  function _EstimationProposal({
    componentHeight,
    componentWidth,
    content,
    onChange,
    title,
    disabled,
  }) {
    const editorContainerRef = React.useRef(null);
    const editorMenuBarRef = React.useRef<HTMLDivElement | null>(null);
    const editorToolbarRef = React.useRef<HTMLDivElement | null>(null);
    const editorMinimapRef = React.useRef(null);
    const editorRef = React.useRef<HTMLDivElement | null>(null);
    const { ref: refMenuSize, height: menuHeight } = useElementSize();
    const { ref: refToolbarSize, height: toolbarHeight } = useElementSize();
    const { ref: refEditorSize, width: editorWidth } = useElementSize();
    const { ref: refPageSize, width: pageWidth } = useElementSize();
    const [isLayoutReady, setIsLayoutReady] = React.useState(false);
    const cloud = useCKEditorCloud({ version: "44.0.0", premium: true });

    const contentHeight = React.useMemo(() => {
      if (componentHeight == null) {
        return null;
      }

      return componentHeight - menuHeight - toolbarHeight;
    }, [componentHeight, menuHeight, toolbarHeight]);

    React.useEffect(() => {
      setIsLayoutReady(true);
      return () => setIsLayoutReady(false);
    }, []);

    const editorInstance = React.useMemo<{
      config: CustomEditorConfig;
      editor: typeof DecoupledEditorType;
    } | null>(() => {
      if (
        cloud.status !== "success" ||
        !isLayoutReady ||
        editorMinimapRef.current == null
      ) {
        return null;
      }

      const {
        DecoupledEditor,
        Alignment,
        Autoformat,
        AutoImage,
        AutoLink,
        Autosave,
        BalloonToolbar,
        Base64UploadAdapter,
        Bold,
        CloudServices,
        Code,
        Essentials,
        FindAndReplace,
        FontBackgroundColor,
        FontColor,
        FontFamily,
        FontSize,
        Heading,
        Highlight,
        HorizontalLine,
        ImageBlock,
        ImageCaption,
        ImageInline,
        ImageInsert,
        ImageInsertViaUrl,
        ImageResize,
        ImageStyle,
        ImageTextAlternative,
        ImageToolbar,
        ImageUpload,
        Indent,
        IndentBlock,
        Italic,
        Link,
        LinkImage,
        List,
        ListProperties,
        Minimap,
        PageBreak,
        Paragraph,
        PasteFromOffice,
        RemoveFormat,
        SpecialCharacters,
        SpecialCharactersArrows,
        SpecialCharactersCurrency,
        SpecialCharactersEssentials,
        SpecialCharactersLatin,
        SpecialCharactersMathematical,
        SpecialCharactersText,
        Strikethrough,
        Subscript,
        Superscript,
        Table,
        TableCaption,
        TableCellProperties,
        TableColumnResize,
        TableProperties,
        TableToolbar,
        TextTransformation,
        TodoList,
        Underline,
      } = cloud.CKEditor;

      const { ExportPdf, Pagination, MultiLevelList } =
        cloud.CKEditorPremiumFeatures;

      const config: CustomEditorConfig = {
        toolbar: {
          items: [
            "exportPdf",
            "|",
            "undo",
            "redo",
            "|",
            "previousPage",
            "nextPage",
            "|",
            "heading",
            "|",
            "fontSize",
            "fontFamily",
            "fontColor",
            "fontBackgroundColor",
            "|",
            "bold",
            "italic",
            "underline",
            "|",
            "link",
            "insertTable",
            "highlight",
            "|",
            "alignment",
            "|",
            "bulletedList",
            "numberedList",
            "todoList",
            "outdent",
            "indent",
          ],
          shouldNotGroupWhenFull: false,
        },
        plugins: [
          Alignment,
          Autoformat,
          AutoImage,
          AutoLink,
          Autosave,
          BalloonToolbar,
          Base64UploadAdapter,
          Bold,
          CloudServices,
          Code,
          Essentials,
          ExportPdf,
          FindAndReplace,
          FontBackgroundColor,
          FontColor,
          FontFamily,
          FontSize,
          Heading,
          Highlight,
          HorizontalLine,
          ImageBlock,
          ImageCaption,
          ImageInline,
          ImageInsert,
          ImageInsertViaUrl,
          ImageResize,
          ImageStyle,
          ImageTextAlternative,
          ImageToolbar,
          ImageUpload,
          Indent,
          IndentBlock,
          Italic,
          Link,
          LinkImage,
          List,
          ListProperties,
          Minimap,
          MultiLevelList,
          PageBreak,
          Pagination,
          Paragraph,
          PasteFromOffice,
          RemoveFormat,
          SpecialCharacters,
          SpecialCharactersArrows,
          SpecialCharactersCurrency,
          SpecialCharactersEssentials,
          SpecialCharactersLatin,
          SpecialCharactersMathematical,
          SpecialCharactersText,
          Strikethrough,
          Subscript,
          Superscript,
          Table,
          TableCaption,
          TableCellProperties,
          TableColumnResize,
          TableProperties,
          TableToolbar,
          TextTransformation,
          TodoList,
          Underline,
        ],
        balloonToolbar: [
          "bold",
          "italic",
          "|",
          "link",
          "|",
          "bulletedList",
          "numberedList",
        ],
        exportPdf: {
          tokenUrl: import.meta.env.DEV
            ? "https://fab15wss7g9w.cke-cs.com/token/dev/065ef24db0616443a29eace484d5b8e3c0ddba6ed1d7abe77489b3d41fec?limit=10"
            : `${PROD_URL}/editor/generate_token`,
          stylesheets: [
            "https://cdn.ckeditor.com/ckeditor5/43.3.1/ckeditor5.css",
            "https://cdn.ckeditor.com/ckeditor5-premium-features/43.3.1/ckeditor5-premium-features.css",
            "/EstimationProposalPdf.css",
          ],
          fileName: title != null ? `${title}.pdf` : "proposal.pdf",
          converterOptions: {
            format: "Letter",
            margin_top: "1.4in",
            margin_bottom: "1in",
            margin_right: "1in",
            margin_left: "1in",
            page_orientation: "portrait",
            header_html: MATRIX_HEADER,
            footer_html: MATRIX_FOOTER,
            header_and_footer_css: HEADER_AND_FOOTER_CSS,
          },
        },
        fontFamily: {
          options: [
            { title: "Arial (default)", model: "default" },
            "Courier New, Courier, monospace",
            "Georgia, serif",
            "Lucida Sans Unicode, Lucida Grande, sans-serif",
            "Tahoma, Geneva, sans-serif",
            "Times New Roman, Times, serif",
            "Trebuchet MS, Helvetica, sans-serif",
            "Verdana, Geneva, sans-serif",
          ],
          supportAllValues: true,
        },
        fontSize: {
          options: [10, 12, 14, "default", 18, 20, 22],
          supportAllValues: true,
        },
        heading: {
          options: [
            {
              model: "paragraph",
              title: "Paragraph",
              class: "ck-heading_paragraph",
            },
            {
              model: "heading1",
              view: "h1",
              title: "Heading 1",
              class: "ck-heading_heading1",
            },
            {
              model: "heading2",
              view: "h2",
              title: "Heading 2",
              class: "ck-heading_heading2",
            },
            {
              model: "heading3",
              view: "h3",
              title: "Heading 3",
              class: "ck-heading_heading3",
            },
            {
              model: "heading4",
              view: "h4",
              title: "Heading 4",
              class: "ck-heading_heading4",
            },
            {
              model: "heading5",
              view: "h5",
              title: "Heading 5",
              class: "ck-heading_heading5",
            },
            {
              model: "heading6",
              view: "h6",
              title: "Heading 6",
              class: "ck-heading_heading6",
            },
          ],
        },
        htmlSupport: {
          allow: [
            {
              name: /^.*$/,
              styles: true,
              attributes: true,
              classes: true,
            },
          ],
        },
        image: {
          toolbar: [
            "toggleImageCaption",
            "imageTextAlternative",
            "|",
            "imageStyle:inline",
            "imageStyle:wrapText",
            "imageStyle:breakText",
            "|",
            "resizeImage",
          ],
        },
        initialData: content ?? EMPTY_STRING,
        licenseKey: LICENSE_KEY,
        link: {
          addTargetToExternalLinks: true,
          defaultProtocol: "https://",
          decorators: {
            toggleDownloadable: {
              mode: "manual",
              label: "Downloadable",
              attributes: {
                download: "file",
              },
            },
          },
        },
        list: {
          properties: {
            styles: true,
            startIndex: true,
            reversed: true,
          },
        },
        menuBar: {
          isVisible: true,
        },
        minimap: {
          container: editorMinimapRef.current,
          extraClasses:
            "editor-container_include-minimap ck-minimap__iframe-content",
        },
        pagination: {
          pageWidth: "8.5in",
          pageHeight: "11in",
          pageMargins: {
            top: "1.4in",
            bottom: "1in",
            right: "1in",
            left: "1in",
          },
        },
        placeholder: "Type or paste your content here!",
        table: {
          contentToolbar: [
            "tableColumn",
            "tableRow",
            "mergeTableCells",
            "tableProperties",
            "tableCellProperties",
          ],
        },
      };

      return {
        editor: DecoupledEditor,
        config,
      };
    }, [cloud, content, isLayoutReady, title]);

    const shouldShowMinimap =
      editorInstance != null && editorWidth > pageWidth + 135 + 20; // 20px for scrollbar

    const handleEditorChange = React.useCallback(
      (_event: EventInfo, _editor: DecoupledEditorType): void => {
        if (onChange != null && _editor != null) {
          onChange(_editor.getData() ?? EMPTY_STRING);
        }
      },
      [onChange],
    );

    const handleAfterDestroy = React.useCallback((): void => {
      if (
        editorToolbarRef.current == null ||
        editorMenuBarRef.current == null
      ) {
        return;
      }

      Array.from(editorToolbarRef.current.children).forEach((child) =>
        child.remove(),
      );
      Array.from(editorMenuBarRef.current.children).forEach((child) =>
        child.remove(),
      );
    }, []);

    const handleEditorReady = React.useCallback(
      (_editor: DecoupledEditorType): void => {
        if (
          editorToolbarRef.current == null ||
          editorMenuBarRef.current == null ||
          _editor.ui.view.toolbar.element == null ||
          _editor.ui.view.menuBarView.element == null
        ) {
          return;
        }

        editorToolbarRef.current.appendChild(_editor.ui.view.toolbar.element);
        editorMenuBarRef.current.appendChild(
          _editor.ui.view.menuBarView.element,
        );
      },
      [],
    );

    return (
      <div>
        {!isLayoutReady ||
        editorInstance == null ||
        editorMenuBarRef.current == null ||
        editorMinimapRef.current == null ? (
          <LoadingScreen loadingScreenType={LoadingScreenType.Editor} />
        ) : null}
        <div
          className={classNames(
            "main-container",
            editorInstance == null ? "loading-state" : null,
          )}
          style={
            {
              "--ckeditor5-preview-height": `${contentHeight}px`,
            } as React.CSSProperties
          }
        >
          <div
            ref={editorContainerRef}
            className="editor-container editor-container_document-editor editor-container_include-minimap editor-container_include-pagination"
          >
            <div ref={refMenuSize} className="menu-wrapper">
              <div
                ref={editorMenuBarRef}
                className="editor-container__menu-bar"
              ></div>
            </div>
            <div ref={refToolbarSize} className="toolbar-wrapper">
              <div
                ref={editorToolbarRef}
                className="editor-container__toolbar"
              ></div>
            </div>
            <div
              ref={refEditorSize}
              className="editor-container__minimap-wrapper"
              style={{
                width:
                  componentWidth != null ? `${componentWidth}px` : "inherit",
                overflowX: componentWidth != null ? "auto" : "inherit",
              }}
            >
              <div
                ref={refPageSize}
                className="editor-container__editor-wrapper"
              >
                <div className="editor-container__editor">
                  <div ref={editorRef}>
                    {isLayoutReady &&
                    editorInstance != null &&
                    editorMenuBarRef.current != null &&
                    editorMinimapRef.current != null ? (
                      <CKEditor
                        config={editorInstance.config}
                        disabled={disabled}
                        editor={editorInstance.editor}
                        onAfterDestroy={handleAfterDestroy}
                        onChange={handleEditorChange}
                        onReady={handleEditorReady}
                      />
                    ) : null}
                  </div>
                </div>
              </div>
              <div
                className={classNames(
                  "editor-container__sidebar editor-container__sidebar_minimap",
                  !shouldShowMinimap
                    ? "editor-container__sidebar-minimap-hidden"
                    : null,
                )}
              >
                <div ref={editorMinimapRef}></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  },
);
