import { Button, Group, Modal, Stack, Text } from "@mantine/core";
import React from "react";
import { useDeleteEstimationMutation } from "src/data/api/api";
import { Estimation } from "src/data/api/types/shared/bid";

interface Props {
  opened: boolean;
  closeModal: () => void;
  version: Estimation;
}

export const ConfirmationEstimationDeletionDialog = ({
  opened,
  closeModal,
  version,
}: Props) => {
  const [deleteEstimation, { isLoading }] = useDeleteEstimationMutation();

  const title = React.useMemo(() => {
    if (version.attributes.title) {
      return `${version.attributes.title} version`;
    }

    return `Version ${version.attributes.version_number}`;
  }, [version.attributes.title, version.attributes.version_number]);

  const handleDelete = React.useCallback(() => {
    deleteEstimation(version.id).then(closeModal);
  }, [closeModal, deleteEstimation, version.id]);

  return (
    <Modal
      onClose={closeModal}
      opened={opened}
      title="Confirmation"
      zIndex={230}
    >
      <Stack gap="xs">
        <Text>{`Are you sure you want to delete ${title}?`}</Text>
        <Group justify="flex-end">
          <Button onClick={closeModal} variant="outline">
            Cancel
          </Button>
          <Button loading={isLoading} onClick={handleDelete}>
            Confirm
          </Button>
        </Group>
      </Stack>
    </Modal>
  );
};
