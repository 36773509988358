import { Button, Group, Menu, Stack } from "@mantine/core";
import { useDebouncedCallback, useElementSize } from "@mantine/hooks";
import { skipToken } from "@reduxjs/toolkit/query";
import {
  IconArrowBarLeft,
  IconDotsVertical,
  IconExchange,
  IconSquares,
} from "@tabler/icons-react";
import classNames from "classnames";
import React from "react";
import { useLocation, useParams } from "react-router-dom";
import { LoadingScreen } from "src/components/Frames/LoadingScreen";
import { MatchParam } from "src/constants/matches";
import { useEstimationSelector } from "src/context/EstimationProvider";
import { api } from "src/data/api/api";
import { LoadedBid } from "src/data/api/types/shared/bid";
import { useQueryStringParams } from "src/hooks/useQueryStringParams";
import { LoadingScreenType } from "src/types/loadingScreenType";
import { WithClassname } from "src/types/withClassName";
import { EMPTY_STRING } from "src/utils/empty";
import { ProposalTemplateType, SPLIT_OPTIONS } from "./constants";
import { EstimationProposal } from "./EstimationProposal";
import styles from "./ProposalView.module.scss";
import { TemplateSelectView } from "./TemplateSelectView";
import { useProposalValues } from "./useProposalValues";
import { loadTemplates, replacePlaceholders } from "./utils";

type TemplatesState = Readonly<Record<ProposalTemplateType, string>>;

interface Props extends WithClassname {
  readonly bid: LoadedBid | undefined;
  readonly componentHeight: number;
  readonly componentWidth: number;
}

export const ProposalView = React.memo<Props>(function _ProposalView({
  bid,
  componentHeight,
  componentWidth,
}) {
  const isFrozen = useEstimationSelector(
    (estimation) => estimation.attributes.state_freeze,
  );
  const [previewedTemplate, setPreviewedTemplate] =
    React.useState<ProposalTemplateType | null>(null);

  const [templates, setTemplates] = React.useState<TemplatesState | null>(null);
  const [proposal, setProposal] = React.useState<string | null>(null);
  const [isLoading, setIsLoading] = React.useState<boolean>(true);
  const [isSelectMode, setIsSelectMode] = React.useState<boolean>(true);
  const [isSwitchTemplate, setIsSwitchTemplate] =
    React.useState<boolean>(false);

  // TODO: add placeholder replacement logic
  const location = useLocation();
  const [, setSplit] = useQueryStringParams("split");
  const { ref: refHeader, height: headerHeight } = useElementSize();
  const editorHeight = componentHeight - (headerHeight + 13); // 13px not counted in header

  const [addProposal] = api.endpoints.addProposal.useMutation();
  const [updateProposal] = api.endpoints.updateProposal.useMutation();

  const { estimationId } = useParams<MatchParam<"ESTIMATION_ID">>();
  const estimation = React.useMemo(() => {
    if (bid != null && estimationId != null) {
      return bid.attributes.estimations.find(
        (_estimation) => _estimation.id === estimationId,
      );
    }
  }, [bid, estimationId]);

  /*
  Query for existing proposal & values
  */
  const proposalsQuery = api.endpoints.getProposals.useQuery(
    estimation == null
      ? skipToken
      : {
          search: {
            and: [
              {
                estimation: estimation.attributes.uuid,
              },
            ],
          },
        },
  );

  const { isLoaded: isProposalsLoaded, data: loadedProposals } =
    React.useMemo(() => {
      const isLoaded = proposalsQuery.isSuccess || proposalsQuery.isError;
      const data = proposalsQuery.currentData?.collection.data;
      return { isLoaded, data };
    }, [proposalsQuery]);

  const { values: proposalValues } = useProposalValues({
    bid,
    estimation,
    proposal: loadedProposals?.[0]?.attributes.body ?? EMPTY_STRING,
  });

  /*
  Load and store templates
  */
  React.useEffect(() => {
    const fetchTemplates = async () => {
      try {
        const loadedTemplates = await loadTemplates();
        setTemplates({
          [ProposalTemplateType.Blank]: loadedTemplates.blank ?? EMPTY_STRING,
          [ProposalTemplateType.Construction]:
            loadedTemplates.construction ?? EMPTY_STRING,
          [ProposalTemplateType.Retrofit]:
            loadedTemplates.retrofit ?? EMPTY_STRING,
          [ProposalTemplateType.Service]:
            loadedTemplates.service ?? EMPTY_STRING,
        });
      } catch {
        // TODO: add error handling
      }
    };

    fetchTemplates();
  }, [proposalValues?.altCount]);

  /* 
  Loading states
  */
  const isProposalFound = React.useMemo(() => {
    return (
      estimation != null &&
      isProposalsLoaded &&
      loadedProposals != null &&
      loadedProposals.length > 0
    );
  }, [estimation, isProposalsLoaded, loadedProposals]);

  const isProposalNotFound = React.useMemo(() => {
    return (
      estimation != null &&
      isProposalsLoaded &&
      loadedProposals != null &&
      loadedProposals.length === 0
    );
  }, [estimation, isProposalsLoaded, loadedProposals]);

  /*
  Set render states
  */
  React.useEffect(() => {
    if (
      estimation == null ||
      (estimation != null && !isProposalsLoaded) ||
      (estimation != null &&
        isProposalsLoaded &&
        loadedProposals?.length === 0 &&
        templates == null) ||
      (isSwitchTemplate && templates == null) ||
      proposalValues == null
    ) {
      setIsLoading(true);
    } else if (isProposalNotFound || isSwitchTemplate) {
      // valid conditions for TemplateSelectView
      setIsLoading(false);
    } else if (isProposalFound && isSelectMode) {
      // valid conditions for EstimationProposal initial load

      const populatedProposal = replacePlaceholders({
        content: loadedProposals?.[0]?.attributes.body ?? EMPTY_STRING,
        values: proposalValues,
      });

      setProposal(populatedProposal);
      setIsLoading(false);
      setIsSelectMode(false);
      setIsSwitchTemplate(false);
    }
  }, [
    estimation,
    isProposalFound,
    isProposalNotFound,
    isProposalsLoaded,
    isSelectMode,
    isSwitchTemplate,
    loadedProposals,
    proposalValues,
    templates,
  ]);

  /* const handleSelectAddressee = React.useCallback(() => {
    // TODO: add logic for this
  }, []); */

  const handleCollapseView = React.useCallback(() => {
    setSplit(SPLIT_OPTIONS.left);
  }, [setSplit]);

  const handleInitialSave = React.useCallback(
    (selectedProposal: string, selectedTemplateType: ProposalTemplateType) => {
      if (estimationId == null) {
        return;
      }

      const title = `${selectedTemplateType}_Template_Proposal`;
      if (isProposalNotFound) {
        addProposal({
          proposal: {
            body: selectedProposal,
            estimation_id: estimationId,
            title: title,
          },
        });
      } else if (isProposalFound) {
        // When user switches template
        updateProposal({
          body: selectedProposal,
          id: loadedProposals?.[0]?.id ?? EMPTY_STRING,
          title: title,
        });
      }
    },
    [
      addProposal,
      estimationId,
      isProposalFound,
      isProposalNotFound,
      loadedProposals,
      updateProposal,
    ],
  );

  const handleTemplateSelect = React.useCallback(
    (selectedTemplateType: ProposalTemplateType) => {
      if (templates == null || proposalValues == null) {
        return;
      }

      const populatedTemplate = replacePlaceholders({
        content: templates[selectedTemplateType],
        values: proposalValues,
      });

      setProposal(populatedTemplate);
      setIsSelectMode(false);
      setIsSwitchTemplate(false);
      handleInitialSave(populatedTemplate, selectedTemplateType);
    },
    [handleInitialSave, proposalValues, templates],
  );

  const handleSwitchTemplate = React.useCallback(() => {
    setProposal(null);
    setIsSwitchTemplate(true);
    setIsSelectMode(true);
  }, []);

  const handleSaveProposal = useDebouncedCallback((): void => {
    updateProposal({
      body: proposal ?? EMPTY_STRING,
      id: loadedProposals?.[0]?.id ?? EMPTY_STRING,
    });
  }, 500);

  const handleEditorChange = React.useCallback(
    (content: string): void => {
      setProposal(content);
      handleSaveProposal();
    },
    [handleSaveProposal],
  );

  const handleOpenNewTab = React.useCallback(() => {
    const searchParams = new URLSearchParams(location.search);
    searchParams.set("split", SPLIT_OPTIONS.right);
    window.open(`${location.pathname}?${searchParams.toString()}`, "_blank");
    setSplit(SPLIT_OPTIONS.left);
  }, [location, setSplit]);

  return (
    <Stack className={styles.root}>
      <Group ref={refHeader} className={styles.header}>
        <Button
          className={classNames(styles.button, styles.collapseButton)}
          leftSection={<IconArrowBarLeft size={18} stroke={3} />}
          onClick={handleCollapseView}
          variant="subtle"
        >
          Proposal
        </Button>
        {isSelectMode ? null : (
          <Group className={styles.headerRightSection}>
            <Button
              className={classNames(styles.button, styles.generateButton)}
              leftSection={<IconSquares size={18} />}
              onClick={handleOpenNewTab}
            >
              Open in new tab
            </Button>
            <Menu
              arrowPosition="center"
              closeOnItemClick={true}
              position="bottom-end"
              shadow="md"
              width={200}
            >
              <Menu.Target>
                <Button
                  className={styles.optionsButton}
                  disabled={isFrozen}
                  variant="subtle"
                >
                  <IconDotsVertical size={18} />
                </Button>
              </Menu.Target>
              <Menu.Dropdown>
                <Menu.Item
                  className={styles.menuSwitch}
                  leftSection={<IconExchange size={14} />}
                  onClick={handleSwitchTemplate}
                >
                  Switch template
                </Menu.Item>
              </Menu.Dropdown>
            </Menu>
          </Group>
        )}
      </Group>
      {isLoading ? (
        <LoadingScreen loadingScreenType={LoadingScreenType.Editor} />
      ) : isSelectMode ? (
        <TemplateSelectView
          onPreview={setPreviewedTemplate}
          onSelect={handleTemplateSelect}
          previewedTemplate={
            previewedTemplate != null && templates != null
              ? templates[previewedTemplate]
              : EMPTY_STRING
          }
          previewedTemplateType={previewedTemplate}
        />
      ) : (
        <EstimationProposal
          componentHeight={editorHeight}
          componentWidth={componentWidth}
          content={proposal}
          disabled={isFrozen}
          onChange={handleEditorChange}
          title={loadedProposals?.[0]?.attributes.title}
        />
      )}
    </Stack>
  );
});
