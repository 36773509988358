import React from "react";
import { useFeatureFlags } from "src/context/FeatureFlagProvider";
import { ResourceType } from "src/data/api/types/bulkTransaction";
import { LoadedBid } from "src/data/api/types/shared/bid";
import { formatCurrency } from "src/utils/formatCurrency";
import { CellHoverCard } from "./CellHoverCard";

interface Props {
  bid: Readonly<LoadedBid>;
}

export const TotalSellAmountView = React.memo(({ bid }: Props) => {
  const isVersionsEnabled = useFeatureFlags((flags) => flags.versions);

  const [cellContent, pillCount] = React.useMemo(() => {
    let estimations = bid.attributes.estimations.slice();

    if (isVersionsEnabled === true) {
      estimations = estimations.filter(
        (estimation) => estimation.attributes.current,
      );
    }

    const sortedEstimations = estimations.sort((d1, d2) => {
      return (d2.attributes.total ?? 0) - (d1.attributes.total ?? 0);
    });

    return [
      formatCurrency(sortedEstimations[0]?.attributes.total),
      sortedEstimations.length - 1,
    ];
  }, [bid.attributes.estimations, isVersionsEnabled]);

  return (
    <CellHoverCard
      bid={bid}
      cellContent={cellContent}
      pillCount={pillCount}
      resourceType={ResourceType.Estimation}
    />
  );
});
