import { Group, HoverCard, Pill } from "@mantine/core";
import React from "react";
import { CustomersTable } from "src/components/BidProfileView/Overview/Customers/CustomersTable";
import { EstimationsTable } from "src/components/BidProfileView/Overview/Estimations/EstimationsTable";
import { ResourceType } from "src/data/api/types/bulkTransaction";
import { LoadedBid } from "src/data/api/types/shared/bid";

interface Props {
  readonly bid: LoadedBid;
  readonly cellContent: React.ReactNode | undefined;
  readonly pillCount: number;
  readonly resourceType: ResourceType;
}

export const CellHoverCard = React.memo<Props>(function _CellHoverCard({
  bid,
  cellContent,
  resourceType,
  pillCount,
}) {
  const hoverTable = React.useMemo(() => {
    switch (resourceType) {
      case ResourceType.Customer:
        return <CustomersTable bid={bid} shouldHideActions={true} />;
      case ResourceType.Estimation:
        return <EstimationsTable bid={bid} shouldHideActions={true} />;
    }
  }, [bid, resourceType]);

  return (
    <Group gap="xs">
      {cellContent}
      {pillCount > 0 && (
        <HoverCard
          openDelay={400}
          shadow="lg"
          styles={{
            dropdown: {
              maxWidth: "800px",
              width: "min-content",
            },
          }}
        >
          <HoverCard.Target>
            <Pill>+{pillCount}</Pill>
          </HoverCard.Target>
          <HoverCard.Dropdown>{hoverTable}</HoverCard.Dropdown>
        </HoverCard>
      )}
    </Group>
  );
});
