import { ActionIcon, Group, Menu } from "@mantine/core";
import {
  IconDotsVertical,
  IconHistory,
  IconPencil,
  IconPlus,
  IconTrash,
} from "@tabler/icons-react";
import React from "react";
import { useCreateEstimationVersion } from "src/hooks/useCreateEstimationVersion";
import { useEstimationVersionHistory } from "src/hooks/useEstimationVersionHistory";

interface Props {
  removeMethods?: string[];
  estimationId: string;
  estimationUuid: string;
  onDelete: (uuid: string) => void;
  onEdit: (uuid: string) => void;
}

export const EstimationActions = React.memo(function _EstimationActions({
  estimationId,
  estimationUuid,
  onDelete,
  onEdit,
  removeMethods = [],
}: Props) {
  const openCreateNewVersion = useCreateEstimationVersion(estimationId);
  const openVersionHistory = useEstimationVersionHistory(estimationId);

  const handleDelete = React.useCallback(() => {
    onDelete(estimationUuid);
  }, [onDelete, estimationUuid]);

  const handleEdit = React.useCallback(() => {
    onEdit(estimationUuid);
  }, [onEdit, estimationUuid]);

  const methods = React.useMemo(() => {
    return [
      {
        key: "update",
        name: "Update details",
        Icon: IconPencil,
        onClick: handleEdit,
      },
      {
        key: "create",
        name: "Create a new version",
        Icon: IconPlus,
        onClick: openCreateNewVersion,
      },
      {
        key: "manager",
        name: "Version history",
        Icon: IconHistory,
        onClick: openVersionHistory,
      },
    ]
      .filter((method) => !removeMethods.includes(method.key))
      .map(({ name, onClick, Icon, key }) => (
        <Menu.Item key={key} onClick={onClick}>
          <Group gap="xs">
            <Icon size={14} strokeWidth={3} />
            {name}
          </Group>
        </Menu.Item>
      ))
      .concat(
        <Menu.Divider key={"divider"} />,
        <Menu.Item key={"delete"} color="red" onClick={handleDelete}>
          <Group gap="xs">
            <IconTrash size={14} strokeWidth={3} />
            {"Delete Estimation"}
          </Group>
        </Menu.Item>,
      );
  }, [
    handleDelete,
    handleEdit,
    openCreateNewVersion,
    openVersionHistory,
    removeMethods,
  ]);

  return (
    <Menu>
      <Menu.Target>
        <ActionIcon c="black" size="sm" variant="transparent">
          <IconDotsVertical />
        </ActionIcon>
      </Menu.Target>

      <Menu.Dropdown>{methods}</Menu.Dropdown>
    </Menu>
  );
});
