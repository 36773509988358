import { Text } from "@mantine/core";
import { DataTable } from "mantine-datatable";
import React from "react";
import { PackageTotal } from "src/data/api/types/getPackageTotals";
import { formatCurrency } from "src/utils/formatCurrency";
import { CostCell } from "../Table/CostCell";
import { MarginCell } from "../Table/MarginCell";

interface Props {
  packages: PackageTotal[];
}

export const Packages = ({ packages }: Props) => {
  const renderTotalCost = React.useCallback((v: PackageTotal) => {
    return <CostCell record={v} />;
  }, []);

  const renderMargin = React.useCallback((v: PackageTotal) => {
    return <MarginCell priceMethod={null} record={v} />;
  }, []);

  const renderSellPrice = React.useCallback(
    (v: PackageTotal) => (
      <Text fz="xs">{formatCurrency(Number(v.grand_total))}</Text>
    ),
    [],
  );

  return (
    <DataTable
      columns={[
        { title: "Package", accessor: "display_name", width: "25%" },
        {
          title: "Total Cost",
          width: "25%",
          accessor: "subtotal",
          render: renderTotalCost,
        },
        {
          title: "Margin",
          accessor: "margin",
          width: "25%",
          render: renderMargin,
        },
        {
          title: "Sell price",
          width: "25%",
          accessor: "grand_total",
          render: renderSellPrice,
        },
      ]}
      idAccessor={"name"}
      records={packages}
      withRowBorders
    />
  );
};
