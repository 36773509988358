import { Group, NumberInput } from "@mantine/core";
import React from "react";
import { PREVENT_DEFAULT } from "src/constants/preventDefault";
import { EMPTY_STRING } from "src/utils/empty";
import { formatNumber } from "src/utils/formatNumber";
import { useCellKeystrokes } from "../Table/hooks/useCellKeystrokes";
import { DIFFERENTIAL_INPUT_STYLES } from "./constants";
import styles from "./DifferentialField.module.scss";
import {
  CLASSIFICATION_ENTRY_KEYS,
  CREW_MIX_ENTRY_KEYS,
  CrewMixEntry,
  SetClassificationEntryArgs,
  SetCrewMixEntryArgs,
} from "./hooks/useCrewMixData";

interface Props {
  readonly disabled?: boolean;
  readonly getCrewMixEntry: (arg: string) => CrewMixEntry | undefined;
  readonly setClassificationEntry: (arg: SetClassificationEntryArgs) => void;
  readonly setCrewMixEntry: (arg: SetCrewMixEntryArgs) => void;
  readonly trade: string;
}

export const DifferentialField = React.memo<Props>(function _DifferentialField({
  disabled = false,
  getCrewMixEntry,
  setClassificationEntry,
  setCrewMixEntry,
  trade,
}) {
  const inputRef = React.useRef<HTMLInputElement>(null);
  const [value, setValue] = React.useState<string | number>(EMPTY_STRING);
  const [isEdit, setIsEdit] = React.useState<boolean>(false);
  const currentCrewMixEntry = getCrewMixEntry(trade);

  const savedValue = React.useMemo(() => {
    return currentCrewMixEntry?.differential == null
      ? undefined
      : formatNumber(currentCrewMixEntry.differential * 100, 0, 2);
  }, [currentCrewMixEntry]);

  React.useEffect(() => {
    if (!isEdit && savedValue != null) {
      setValue(savedValue === 0 ? EMPTY_STRING : savedValue);
    }
  }, [currentCrewMixEntry, isEdit, savedValue]);

  const classificationIds = React.useMemo(() => {
    return currentCrewMixEntry?.classificationEntries?.map(
      (classification) => classification.classificationId,
    );
  }, [currentCrewMixEntry]);

  const handleFocus = React.useCallback(() => {
    setIsEdit(true);
  }, []);

  const handleSubmit = React.useCallback(() => {
    setIsEdit(false);
    const numberValue = typeof value === "string" ? 0 : value;
    if (numberValue === savedValue) {
      return;
    }

    setCrewMixEntry({
      key: CREW_MIX_ENTRY_KEYS.differential,
      trade,
      value: numberValue / 100,
    });

    classificationIds?.map((id) => {
      const classificationEntry =
        currentCrewMixEntry?.classificationEntries?.find(
          (classification) => classification.classificationId === id,
        );

      if (
        classificationEntry?.straightTimeWageTotal == null ||
        classificationEntry.straightTimeWageBase == null
      ) {
        return;
      }

      setClassificationEntry({
        classificationId: id,
        key: CLASSIFICATION_ENTRY_KEYS.straightTimeWageAdjusted,
        trade,
        value:
          (numberValue / 100) * classificationEntry.straightTimeWageBase +
          classificationEntry.straightTimeWageTotal,
      });
    });
  }, [
    classificationIds,
    currentCrewMixEntry?.classificationEntries,
    savedValue,
    setClassificationEntry,
    setCrewMixEntry,
    trade,
    value,
  ]);

  const { handleEnterBlur: handleKeyPresses } = useCellKeystrokes({
    ref: inputRef,
  });

  return (
    <NumberInput
      ref={inputRef}
      decimalScale={2}
      disabled={disabled}
      hideControls={true}
      onBlur={handleSubmit}
      onChange={setValue}
      onFocus={handleFocus}
      onKeyDown={handleKeyPresses}
      placeholder="-"
      rightSection={
        <Group className={styles.percent} onMouseDown={PREVENT_DEFAULT}>
          %
        </Group>
      }
      rightSectionPointerEvents="none"
      rightSectionWidth={42}
      styles={DIFFERENTIAL_INPUT_STYLES}
      value={value}
    />
  );
});
