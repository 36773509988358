import { SimpleGrid, Tooltip } from "@mantine/core";
import React from "react";
import { useExpandableLineItems } from "src/context/ExpandableLineItemsProvider";
import { BundleLineItem } from "src/data/api/types/getBundles";
import { truncateFilename } from "src/utils/truncateFilename";
import { EstimationResourceType } from "../CrewMix/util/utils";
import styles from "./ActionIcons.module.scss";
import { AttachmentIcon } from "./AttachmentIcon";
import { ExpandIcon } from "./ExpandIcon";
import { useAttachments } from "./hooks/useAttachments";

const FILENAME_MAXIMUM = 4;

interface Props {
  record: BundleLineItem;
}

export const ActionIcons = React.memo<Props>(function _ActionIcons({ record }) {
  const { expandableItems, setExpandedItems } = useExpandableLineItems();
  const openManageAttachments = useAttachments();

  const shouldRenderAttachment = React.useMemo(() => {
    return record.attachments == null ? false : record.attachments.length > 0;
  }, [record.attachments]);

  const shouldRenderChevron = React.useMemo(() => {
    return (
      record.children.filter(
        (child) => child.display_attributes?.display !== "inline",
      ).length > 0
    );
  }, [record.children]);

  const updateExpandable = React.useCallback(() => {
    let newExpandables = expandableItems.slice();
    if (expandableItems.includes(record.id)) {
      newExpandables = newExpandables.filter((item) => item !== record.id);
    } else {
      newExpandables = newExpandables.concat(record.id);
    }

    setExpandedItems(newExpandables);
  }, [expandableItems, record.id, setExpandedItems]);

  const handleClickAttachment = React.useCallback(() => {
    openManageAttachments({
      objectId: record.id,
      objectType: EstimationResourceType.LineItem,
    });
  }, [openManageAttachments, record]);

  const tooltipLabel = React.useMemo(() => {
    if (record.attachments == null) return;

    const list = record.attachments
      .slice(0, FILENAME_MAXIMUM)
      .map((file, index) => (
        <div key={index}>{truncateFilename(file, 35)}</div>
      ));

    if (record.attachments.length > FILENAME_MAXIMUM) {
      return [
        ...list,
        <div key={FILENAME_MAXIMUM}>
          ...and {record.attachments.length - FILENAME_MAXIMUM} more
        </div>,
      ];
    } else {
      return list;
    }
  }, [record.attachments]);

  return (
    <SimpleGrid className={styles.root} cols={3}>
      {shouldRenderAttachment ? (
        <Tooltip
          className={styles.tooltip}
          disabled={record.attachments == null}
          label={tooltipLabel}
          openDelay={500}
          position="top-start"
        >
          <AttachmentIcon
            buttonSize="xs"
            className={styles.attachmentIcon}
            iconSize={14}
            onClick={handleClickAttachment}
          />
        </Tooltip>
      ) : (
        <div className={styles.filler}></div>
      )}

      {/* TODO: add comments icon here */}
      <div className={styles.filler}></div>

      {shouldRenderChevron ? (
        <ExpandIcon
          buttonSize="xs"
          className={styles.expandIcon}
          iconSize={14}
          isExpanded={expandableItems.includes(record.id)}
          onClick={updateExpandable}
        />
      ) : (
        <div className={styles.filler}></div>
      )}
    </SimpleGrid>
  );
});
