import { ValueGetterParams } from "ag-grid-community";
import { StateAttachment } from "src/components/Frames/Attachments/constants";
import { AttachmentColumnId } from "src/components/Frames/Attachments/utils";

interface Args {
  cellData: ValueGetterParams<StateAttachment>;
  columnId: AttachmentColumnId;
  value: unknown;
}

export function setAttachmentTableCell({ cellData, columnId, value }: Args) {
  const { node } = cellData;
  if (node == null) return;
  node.setDataValue(columnId, value);
}
