import { ActionIcon, SimpleGrid } from "@mantine/core";
import { IconDownload, IconPencil, IconTrash } from "@tabler/icons-react";
import { ValueGetterParams } from "ag-grid-community";
import React from "react";
import { useMutateAttachment } from "src/components/EstimationView/Table/hooks/useMutateAttachment";
import { Attachment } from "src/data/api/types/shared/attachment";
import { setAttachmentTableCell } from "src/utils/setAttachmentTableCell";
import styles from "./ActionsCell.module.scss";
import { StateAttachment } from "./constants";
import { AttachmentsColumnId } from "./utils";

const ICON_SIZE = 14;

interface Props {
  attachment: Attachment;
  cellData: ValueGetterParams<StateAttachment>;
  onUpdate: () => void;
}

export const ActionsCell = React.memo<Props>(function _ActionsCell({
  attachment,
  cellData,
  onUpdate,
}) {
  const { onDownload, onDelete } = useMutateAttachment();

  const handleRenameAttachment = React.useCallback(
    (event: React.MouseEvent<HTMLButtonElement>) => {
      event.preventDefault();
      onUpdate();
      setAttachmentTableCell({
        cellData,
        columnId: AttachmentsColumnId.ISRENAME,
        value: true,
      });
    },
    [cellData, onUpdate],
  );

  const handleDownloadAttachment = React.useCallback(
    (event: React.MouseEvent<HTMLButtonElement>) => {
      event.preventDefault();
      onDownload(attachment);
    },
    [attachment, onDownload],
  );

  const handleDeleteAttachment = React.useCallback(
    (event: React.MouseEvent<HTMLButtonElement>) => {
      event.preventDefault();
      onUpdate();
      setAttachmentTableCell({
        cellData,
        columnId: AttachmentsColumnId.ISLOADING,
        value: true,
      });

      onDelete({ attachment });
    },
    [attachment, cellData, onDelete, onUpdate],
  );

  const isLoading = React.useMemo(() => {
    return cellData.node?.data?.isLoading;
  }, [cellData.node?.data?.isLoading]);

  return (
    <SimpleGrid className={styles.actions} cols={3}>
      <ActionIcon
        disabled={isLoading}
        onClick={handleRenameAttachment}
        variant="transparent"
      >
        <IconPencil color="black" size={ICON_SIZE} />
      </ActionIcon>
      <ActionIcon
        disabled={isLoading}
        onClick={handleDownloadAttachment}
        variant="transparent"
      >
        <IconDownload color="black" size={ICON_SIZE} />
      </ActionIcon>
      <ActionIcon
        loading={isLoading}
        onClick={handleDeleteAttachment}
        variant="transparent"
      >
        <IconTrash color="red" size={ICON_SIZE} />
      </ActionIcon>
    </SimpleGrid>
  );
});
