import { modals } from "@mantine/modals";
import { skipToken } from "@reduxjs/toolkit/query";
import React from "react";
import { useSearchParams } from "react-router-dom";
import { LineItemCrewMixFormValues } from "src/components/Forms/types/crewMix";
import { usePackageCrewMix } from "src/context/PackageCrewMixProvider";
import { api } from "src/data/api/api";
import { AddCrewMixArgs } from "src/data/api/types/addCrewMix";
import { BundleLineItem } from "src/data/api/types/getBundles";
import { EMPTY_STRING } from "src/utils/empty";
import { PACKAGE_PARAM } from "../../Table/constants";
import { HOUR_TYPE_LOOKUP } from "../constants";
import { LineItemCrewMixDialog } from "../LineItemCrewMixDialog";
import { addLineItemCrewMix } from "../util/addLineItemCrewMix";
import { LaborTypeTime } from "../util/utils";
import styles from "./LineItemCrewMix.module.scss";

interface TransferArgs {
  _newTrade: string;
  lineItemId: number;
  onTransfer: () => void;
}

export function useLineItemCrewMix(prevCrewMixId: number | null = null) {
  const [params] = useSearchParams();
  const packageId = params.get(PACKAGE_PARAM) ?? EMPTY_STRING;
  const [addCrewMix] = api.endpoints.addCrewMix.useMutation();
  const [deleteCrewMix] = api.endpoints.deleteCrewMix.useMutation();

  const prevCrewMix = api.endpoints.getCrewMix.useQuery(
    prevCrewMixId?.toString() ?? skipToken,
  ).currentData?.crew_mix.data;

  const prevLaborSource = api.endpoints.getLaborSource.useQuery(
    prevCrewMix?.attributes.labor_source.id.toString() ?? skipToken,
  ).currentData?.labor_source.data;

  const { crewMixes: packageCrewMixes, getCrewMix } = usePackageCrewMix();
  const packageLaborSourceCodes = [
    ...new Set(
      packageCrewMixes.map((option) => option.attributes.labor_source.code),
    ),
  ];

  const packageLaborSources = api.endpoints.getLaborSourcesFilter.useQuery(
    packageLaborSourceCodes.length === 0
      ? skipToken
      : {
          search: {
            or: packageLaborSourceCodes.map((code) => {
              return { code };
            }),
          },
        },
  ).currentData?.collection.data;

  const setLineItemCrewMix = React.useCallback(
    (record: BundleLineItem) => {
      modals.open({
        title: "Adjust crew mix for selected item",
        centered: true,
        children: (
          <LineItemCrewMixDialog
            onClose={modals.closeAll}
            packageCrewMix={getCrewMix(record.trade)}
            packageId={packageId}
            record={record}
          />
        ),
        closeOnEscape: false,
        transitionProps: {
          transition: "pop",
        },
      });
    },
    [getCrewMix, packageId],
  );

  const deleteLineItemCrewMix = React.useCallback(
    (record: BundleLineItem) => {
      if (record.crew_mix_id == null) return;
      deleteCrewMix(record.crew_mix_id);
    },
    [deleteCrewMix],
  );

  const transferLineItemCrewMix = React.useCallback(
    async ({ _newTrade, lineItemId, onTransfer }: TransferArgs) => {
      const packageCrewMix = getCrewMix(_newTrade);
      const newLaborSource = packageLaborSources?.find(
        (laborSource) =>
          parseInt(laborSource?.id) ===
          packageCrewMix?.attributes.labor_source.id,
      );

      const percentageObj = prevCrewMix?.attributes.crew_mix_percentages.find(
        (obj) => obj.percentage === 1,
      );

      const laborTypeName = prevLaborSource?.attributes.labor_types.find(
        (laborType) => laborType.id === percentageObj?.labor_type_id,
      )?.name;

      const isLaborTypePresent =
        newLaborSource?.attributes.labor_types.find(
          (laborType) => laborType.name === laborTypeName,
        ) != null;

      /* 
        Need to build the crew mix form selections
      */
      const hourType =
        Object.values(HOUR_TYPE_LOOKUP)[
          Object.keys(HOUR_TYPE_LOOKUP).indexOf(
            percentageObj?.labor_type_time as LaborTypeTime,
          )
        ];

      if (
        prevCrewMix == null ||
        newLaborSource == null ||
        laborTypeName == null ||
        percentageObj == null ||
        hourType == null
      ) {
        return;
      }

      const crewMixSelections: LineItemCrewMixFormValues = {
        classification: laborTypeName,
        hourType,
        differential:
          parseFloat(prevCrewMix?.attributes.differential ?? "0") * 100,
      };

      modals.openConfirmModal({
        centered: true,
        transitionProps: {
          transition: "pop",
        },
        title: <div className={styles.title}>Heads up!</div>,
        children: (
          <div className={styles.body}>
            {isLaborTypePresent
              ? "Your line item has individual classifications and hour allocations saved to the labor activities. Do you want to carry these specifications forward to the newly selected trade or discard?"
              : "Your line item has individual classifications and hour allocations saved to the labor activities. Changing the trade will discard these settings."}
          </div>
        ),
        labels: isLaborTypePresent
          ? { confirm: "Yes, carry over", cancel: "No, discard" }
          : { confirm: "Confirm", cancel: "Cancel" },
        cancelProps: { className: styles.button },
        confirmProps: { className: styles.button },
        onCancel: async () => {
          if (isLaborTypePresent) {
            await deleteCrewMix(parseInt(prevCrewMix?.id));
            onTransfer();
          }
        },
        onConfirm: async () => {
          if (isLaborTypePresent) {
            await addLineItemCrewMix({
              addCrewMix: async (args: AddCrewMixArgs) => {
                addCrewMix(args);
              },
              crewMixSelections,
              laborSource: newLaborSource,
              lineItemId,
              packageId: parseInt(packageId),
              trade: _newTrade,
            });
          }

          await deleteCrewMix(parseInt(prevCrewMix?.id));
          onTransfer();
        },
      });
    },
    [
      addCrewMix,
      deleteCrewMix,
      getCrewMix,
      packageId,
      packageLaborSources,
      prevCrewMix,
      prevLaborSource?.attributes.labor_types,
    ],
  );

  return { setLineItemCrewMix, deleteLineItemCrewMix, transferLineItemCrewMix };
}
