import React, { createContext, useMemo, useState } from "react";
import { BundleLineItem, PackageBundle } from "src/data/api/types/getBundles";

export type ActionTypes = "copy" | "cut";

interface ContextType {
  selectedPackages: number[];
  setSelectedPackages: React.Dispatch<React.SetStateAction<number[]>>;
  selectedBundles: PackageBundle[];
  setSelectedBundles: React.Dispatch<React.SetStateAction<PackageBundle[]>>;
  action: ActionTypes | null;
  setAction: React.Dispatch<React.SetStateAction<ActionTypes | null>>;
  selectedItems: BundleLineItem[];
  setSelectedItems: React.Dispatch<React.SetStateAction<BundleLineItem[]>>;
}

export const SelectedLineItemsContext = createContext<ContextType>({
  selectedPackages: [],
  setSelectedPackages: () => {},
  action: null,
  selectedBundles: [],
  setSelectedBundles: () => {},
  setAction: () => {},
  selectedItems: [],
  setSelectedItems: () => {},
});

interface Props {
  children: React.ReactNode;
}

function SelectedLineItemsProvider({ children }: Props) {
  const [selectedItems, setSelectedItems] = useState<BundleLineItem[]>([]);
  const [selectedBundles, setSelectedBundles] = useState<PackageBundle[]>([]);
  const [selectedPackages, setSelectedPackages] = useState<number[]>([]);
  const [action, setAction] = useState<ActionTypes | null>(null);

  const value = useMemo(() => {
    return {
      selectedItems,
      setSelectedItems,
      action,
      setAction,
      setSelectedBundles,
      selectedPackages,
      setSelectedPackages,
      selectedBundles,
    };
  }, [action, selectedBundles, selectedItems, selectedPackages]);

  return (
    <SelectedLineItemsContext.Provider value={value}>
      {children}
    </SelectedLineItemsContext.Provider>
  );
}

export default SelectedLineItemsProvider;
