import { Group, NumberInput, Select, Stack } from "@mantine/core";
import React from "react";
import { Control, useController } from "react-hook-form";
import { PREVENT_DEFAULT } from "src/constants/preventDefault";
import { EMPTY_STRING } from "src/utils/empty";
import {
  DIFFERENTIAL_INPUT_STYLES,
  HOUR_TYPE_OPTIONS,
} from "../EstimationView/CrewMix/constants";
import { DEFAULT_PLACEHOLDER } from "../EstimationView/Table/constants";
import styles from "./LineItemCrewMixFormContent.module.scss";
import { LineItemCrewMixFormValues } from "./types/crewMix";

interface Props {
  classificationOptions: Array<string>;
  control: Control<LineItemCrewMixFormValues>;
  isLoading: boolean;
  isProjectManagement: boolean;
  isWarehouse: boolean;
}

export const LineItemCrewMixFormContent = React.memo<Props>(
  function _LineItemCrewMixFormContent({
    classificationOptions,
    control,
    isLoading,
    isProjectManagement,
    isWarehouse,
  }) {
    const classification = useController({ control, name: "classification" });
    const hourType = useController({ control, name: "hourType" });
    const differential = useController({ control, name: "differential" });
    return (
      <Stack className={styles.root}>
        <Select
          allowDeselect={false}
          checkIconPosition="right"
          data={classificationOptions}
          disabled={isLoading || isWarehouse}
          label="Classification"
          onChange={classification.field.onChange}
          value={classification.field.value}
          withScrollArea={false}
        />
        <Select
          allowDeselect={false}
          checkIconPosition="right"
          data={HOUR_TYPE_OPTIONS}
          disabled={isLoading || isProjectManagement}
          label="Hour type"
          onChange={hourType.field.onChange}
          value={hourType.field.value}
          withScrollArea={false}
        />
        {hourType.field.value === "Straight time" ? (
          <NumberInput
            decimalScale={2}
            hideControls={true}
            label="Shift differential"
            onChange={differential.field.onChange}
            placeholder={DEFAULT_PLACEHOLDER}
            rightSection={
              <Group className={styles.percent} onMouseDown={PREVENT_DEFAULT}>
                %
              </Group>
            }
            rightSectionPointerEvents="none"
            rightSectionWidth={42}
            styles={DIFFERENTIAL_INPUT_STYLES}
            value={
              differential.field.value === 0
                ? EMPTY_STRING
                : differential.field.value
            }
          />
        ) : null}
      </Stack>
    );
  },
);
