import { Anchor, Group, Stack, Tabs } from "@mantine/core";
import { skipToken } from "@reduxjs/toolkit/query";
import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import { PageHeader } from "src/components/Frames/PageHeader";
import { ShareButton } from "src/components/Frames/ShareButton";
import { MatchParam } from "src/constants/matches";
import { Paths } from "src/constants/paths";
import { api } from "src/data/api/api";
import { LoadingScreenType } from "src/types/loadingScreenType";
import { BidTable } from "../BidView/Table/BidTable";
import { DeleteButton } from "../Frames/DeleteButton";
import { LoadingScreen } from "../Frames/LoadingScreen";
import styles from "./CustomerProfileView.module.scss";
import { Overview } from "./Overview/Overview";

const TABS = ["Overview", "Bids", "Attachments"] as const;

export const CustomerProfileView = React.memo(function _CustomerProfileView() {
  const navigate = useNavigate();
  const { customerId } = useParams<MatchParam<"CUSTOMER_ID">>();

  const customer = api.endpoints.getCustomer.useQuery(customerId ?? skipToken);
  const [deleteCustomer] = api.endpoints.deleteCustomer.useMutation();

  const handleCustomerClick = React.useCallback(() => {
    navigate(Paths.SLASH + Paths.CUSTOMERS);
  }, [navigate]);

  const handleCustomerDelete = React.useCallback(async () => {
    if (customerId == null) {
      return;
    }

    await deleteCustomer(customerId);
    handleCustomerClick();
  }, [customerId, deleteCustomer, handleCustomerClick]);

  if (customer.currentData == null) {
    return <LoadingScreen loadingScreenType={LoadingScreenType.Profile} />;
  }

  return (
    <Stack className={styles.stack}>
      <PageHeader
        rightSection={
          <Group>
            <ShareButton />
            <DeleteButton onDelete={handleCustomerDelete} />
          </Group>
        }
        subtitle={
          <span className={styles.subtitle}>
            <Anchor
              className={styles.path}
              component="button"
              inline={true}
              onClick={handleCustomerClick}
            >
              Customers
            </Anchor>
            <span className={styles.slash}>/</span>
            <span className={styles.path}>
              {customer.currentData.customer.data.attributes.name}
            </span>
          </span>
        }
        title={customer.currentData.customer.data.attributes.name}
      />

      <Group className={styles.group}>
        <Tabs className={styles.tabs} defaultValue={TABS[0]}>
          <Tabs.List p="0 20px">
            {TABS.map((tab) => (
              <Tabs.Tab key={tab} value={tab}>
                {tab}
              </Tabs.Tab>
            ))}
          </Tabs.List>

          <Tabs.Panel className={styles.panel} value={TABS[0]}>
            <Overview customer={customer.currentData.customer.data} />
          </Tabs.Panel>

          <Tabs.Panel className={styles.panel} value={TABS[1]}>
            <BidTable
              customerIdFilter={
                customer.currentData.customer.data.attributes.uuid
              }
            />
          </Tabs.Panel>

          <Tabs.Panel value={TABS[2]}>{TABS[2]}</Tabs.Panel>
        </Tabs>
      </Group>
    </Stack>
  );
});
