import { Group, Menu, Tabs } from "@mantine/core";
import { useDisclosure, useElementSize, useViewportSize } from "@mantine/hooks";
import { IconCirclePlus, IconClipboardCopy } from "@tabler/icons-react";
import classNames from "classnames";
import React from "react";
import PackageCrewMixProvider from "src/context/PackageCrewMixProvider";
import { useSelectedLineItems } from "src/context/SelectedLineItemsProvider";
import { useCloneItemsMutation, useGetPackagesQuery } from "src/data/api/api";
import { useQueryStringParams } from "src/hooks/useQueryStringParams";
import { WithClassname } from "src/types/withClassName";
import { SetCrewMixButton } from "../CrewMix/SetCrewMixButton";
import NewPackageDialog from "../NewPackageDialog";
import PackageTab from "../PackageTab";
import AddNewItemButton from "./components/AddNewItemButton";
import EstimationPackage from "./components/EstimationPackage";
import EstimationSummaryButton from "./components/EstimationSummaryButton";
import TotalSellPriceSection from "./components/TotalSellPriceSection";
import { PACKAGE_PARAM } from "./constants";
import styles from "./EstimationTable.module.scss";
import { useTableKeystrokes } from "./hooks/useTableKeystrokes";

interface Props2 {
  onClick: () => void;
  estimationId: number;
  disabled: boolean;
}

const NewPackageButton = ({ onClick, estimationId, disabled }: Props2) => {
  const { action, selectedPackages, setAction, setSelectedPackages } =
    useSelectedLineItems();
  const [opened, setOpened] = React.useState(false);
  const [cloneItems] = useCloneItemsMutation();

  const handleContextMenu = React.useCallback(
    (event: React.MouseEvent<HTMLButtonElement>) => {
      event.preventDefault();

      setOpened(true);
    },
    [],
  );

  const handleClose = React.useCallback(() => {
    setOpened(false);
  }, []);

  const handlePaste = React.useCallback(() => {
    if (action != null && selectedPackages.length > 0) {
      cloneItems({
        action_type: action,
        package_ids: selectedPackages.map((_package) => ({ id: _package })),
        line_item_ids: [],
        bundle_ids: [],
        location: {
          type: "Estimation",
          id: estimationId,
        },
      });

      setAction(null);
      setSelectedPackages([]);
    }
  }, [
    cloneItems,
    action,
    selectedPackages,
    estimationId,
    setAction,
    setSelectedPackages,
  ]);

  return (
    <Menu
      arrowPosition="side"
      closeOnItemClick
      onClose={handleClose}
      opened={opened}
      position="right-start"
      shadow="md"
      width={200}
    >
      <Menu.Target>
        <Tabs.Tab
          className={styles.addPackageButton}
          color="#5C5F66"
          disabled={disabled}
          onContextMenu={handleContextMenu}
          value={NEW_PACKAGE_VALUE}
        >
          <IconCirclePlus color="white" size={28} />
        </Tabs.Tab>
      </Menu.Target>

      <Menu.Dropdown>
        <Menu.Item onClick={onClick}>
          <Group gap="xs">
            <IconCirclePlus size="16px" />
            New Package
          </Group>
        </Menu.Item>
        <Menu.Divider />
        <Menu.Item
          disabled={action == null || selectedPackages.length < 1}
          onClick={handlePaste}
        >
          <Group gap="xs">
            <IconClipboardCopy size="16px" />
            {`Paste ${selectedPackages.length} item(s)`}
          </Group>
        </Menu.Item>
      </Menu.Dropdown>
    </Menu>
  );
};

const NEW_PACKAGE_VALUE = "new-view";

interface Props extends WithClassname {
  readonly estimationId: string;
  readonly isFrozen: boolean;
}

export const EstimationTable = React.memo<Props>(function _EstimationTable({
  className,
  estimationId,
  isFrozen,
}) {
  const { height: screenHeight } = useViewportSize();
  const headerRef = React.useRef<HTMLDivElement>(null);
  const [bodyHeight, setBodyHeight] = React.useState<number | null>(null);
  const { ref: footerRef, height: footerHeight } =
    useElementSize<HTMLDivElement>();

  const [selectedPackageId, setSelectedPackageId] =
    useQueryStringParams(PACKAGE_PARAM);

  useTableKeystrokes(selectedPackageId, isFrozen);

  const [isNewViewDialogOpen, { open: openNewView, close: closeNewView }] =
    useDisclosure(false);

  const { currentData } = useGetPackagesQuery(estimationId);
  const packages = React.useMemo(() => {
    if (currentData == null) {
      return [];
    }

    return currentData.collection;
  }, [currentData]);

  const selectedPackage = React.useMemo(() => {
    if (selectedPackageId != null) {
      const selected = packages.find(
        (_package) => _package.id.toString() === selectedPackageId,
      );

      if (selected != null) return selected;
    }

    return packages[0];
  }, [packages, selectedPackageId]);

  const notSelectedPackages = React.useMemo(() => {
    return packages.filter(
      (_package) => _package.id.toString() !== selectedPackageId,
    );
  }, [packages, selectedPackageId]);

  const handleTabChange = React.useCallback(
    (value: string | null) => {
      if (value == null) {
        return;
      }

      if (value === NEW_PACKAGE_VALUE) {
        openNewView();
        return;
      }

      setSelectedPackageId(value);
    },
    [openNewView, setSelectedPackageId],
  );

  React.useLayoutEffect(() => {
    const topOffset = headerRef.current?.getBoundingClientRect().top ?? 0;
    const headerHeight = headerRef.current?.getBoundingClientRect().height ?? 0;

    if (screenHeight > 0) {
      setBodyHeight(
        Math.round(screenHeight - (topOffset + headerHeight + footerHeight)),
      );
    }
  }, [footerHeight, screenHeight]);

  React.useEffect(() => {
    if (selectedPackage?.id != null) {
      setSelectedPackageId(selectedPackage.id.toString());
    }
  }, [selectedPackage?.id, setSelectedPackageId]);

  return (
    <div className={classNames(styles.root, className)}>
      {/* header */}
      <Tabs
        activateTabWithKeyboard={false}
        onChange={handleTabChange}
        value={selectedPackageId}
        variant="outline"
      >
        <Group className={styles.tabsWrapper}>
          <Tabs.List className={styles.packageTabs}>
            {packages.map((packageTab) => (
              <PackageTab key={packageTab.id} packageTab={packageTab} />
            ))}
          </Tabs.List>

          <NewPackageButton
            disabled={isFrozen}
            estimationId={Number(estimationId)}
            onClick={openNewView}
          />
        </Group>
      </Tabs>

      {/* body */}
      {selectedPackageId ? (
        <PackageCrewMixProvider packageId={selectedPackageId}>
          <div>
            <Group
              ref={headerRef}
              className={styles.searchAndButtonsHeader}
              justify="end"
            >
              <EstimationSummaryButton />
              <SetCrewMixButton />
              <AddNewItemButton
                disabled={isFrozen}
                packageId={selectedPackageId}
              />
            </Group>
            <div
              className={styles.tableWrapper}
              style={{
                height: `${bodyHeight}px`,
                overflowY: "scroll",
                overflowX: "scroll",
                scrollPaddingLeft: "28px",
                background: "#f9fafb",
              }}
            >
              <EstimationPackage
                packageId={selectedPackageId}
                packages={notSelectedPackages}
              />
            </div>
          </div>
        </PackageCrewMixProvider>
      ) : null}

      {/* footer */}
      {selectedPackage ? (
        <div ref={footerRef}>
          <TotalSellPriceSection selectedPackage={selectedPackage} />
        </div>
      ) : null}

      {isNewViewDialogOpen && (
        <NewPackageDialog
          onClose={closeNewView}
          onNewPackage={setSelectedPackageId}
        />
      )}
    </div>
  );
});
