import { ActionIcon, MantineSize } from "@mantine/core";
import { IconChevronRight } from "@tabler/icons-react";
import classNames from "classnames";
import React from "react";
import { WithClassname } from "src/types/withClassName";
import styles from "./ExpandIcon.module.scss";

interface Props extends WithClassname {
  buttonSize?: MantineSize;
  iconSize?: number;
  isExpanded: boolean;
  onClick?: () => void;
}

export const ExpandIcon = React.memo<Props>(function _ExpandIcon({
  buttonSize,
  className,
  iconSize = 18,
  isExpanded,
  onClick,
}) {
  const handleExpandable = React.useCallback(
    (event: React.MouseEvent<HTMLButtonElement>) => {
      if (onClick != null) {
        event.stopPropagation();
      }

      onClick?.();
    },
    [onClick],
  );

  return (
    <ActionIcon
      onClick={handleExpandable}
      size={buttonSize}
      variant="transparent"
    >
      <IconChevronRight
        className={classNames(className, styles.expandIcon, {
          [styles.expandIconRotated]: isExpanded,
        })}
        size={iconSize}
      />
    </ActionIcon>
  );
});
