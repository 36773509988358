import React from "react";
import { useFeatureFlags } from "src/context/FeatureFlagProvider/useFeatureFlags";
import { ResourceType } from "src/data/api/types/bulkTransaction";
import { LoadedBid } from "src/data/api/types/shared/bid";
import { LabelValue } from "src/types/util/labelValue";
import { CellHoverCard } from "./CellHoverCard";

interface Props {
  bid: Readonly<LoadedBid>;
  statuses: ReadonlyArray<LabelValue>;
}

export const StatusView = React.memo(({ bid, statuses }: Props) => {
  const isVersionsEnabled = useFeatureFlags((flags) => flags.versions);
  const [cellContent, pillCount] = React.useMemo(() => {
    let estimations = bid.attributes.estimations.slice();

    if (isVersionsEnabled === true) {
      estimations = estimations.filter(
        (estimation) => estimation.attributes.current === true,
      );
    }

    const sortedEstimations = estimations.sort((d1, d2) => {
      return (d2.attributes.total ?? 0) - (d1.attributes.total ?? 0);
    });

    return [
      statuses.find(
        (option) => option.value === sortedEstimations[0]?.attributes.status,
      )?.label,
      sortedEstimations.length - 1,
    ];
  }, [bid.attributes.estimations, isVersionsEnabled, statuses]);

  return (
    <CellHoverCard
      bid={bid}
      cellContent={cellContent}
      pillCount={pillCount}
      resourceType={ResourceType.Estimation}
    />
  );
});
