import { notifications } from "@mantine/notifications";
import React from "react";
import { api } from "src/data/api/api";
import { Attachment } from "src/data/api/types/shared/attachment";
import { downloadS3File } from "src/utils/downloadS3File";

interface OnUpdateArgs {
  attachment: Attachment | undefined;
  attachmentType?: string | null;
  filename?: string;
}

interface OnDeleteArgs {
  attachment: Attachment | undefined;
}

export function useMutateAttachment() {
  const [updateAttachment] = api.endpoints.updateAttachment.useMutation();
  const [deleteAttachment] = api.endpoints.deleteAttachment.useMutation();

  const onUpdate = React.useCallback(
    async ({ attachment, attachmentType, filename }: OnUpdateArgs) => {
      if (attachment == null) return;

      let updatedFilename: string | undefined = undefined;
      if (filename != null) {
        /*
          Ensure correct extension
        */
        const currentExtension = attachment.filename.substring(
          attachment.filename.lastIndexOf("."),
        );

        const hasCorrectExtension = filename.endsWith(currentExtension);
        updatedFilename = hasCorrectExtension
          ? filename
          : `${filename}${currentExtension}`;
      }

      await updateAttachment({
        attachment_type: attachmentType,
        title: updatedFilename,
        id: attachment.id,
        object_id: attachment.object_id,
        object_type: attachment.object_type,
      });
    },
    [updateAttachment],
  );

  const onDownload = React.useCallback(
    async (attachment: Attachment | undefined): Promise<void> => {
      if (attachment == null) return;

      try {
        await downloadS3File(attachment.filename, attachment.url);
      } catch (error) {
        notifications.show({
          title: "Error",
          message: `Failed to download file: ${attachment.filename}`,
        });
      }
    },
    [],
  );

  const onDelete = React.useCallback(
    async ({ attachment }: OnDeleteArgs) => {
      if (attachment == null) return;

      try {
        await deleteAttachment({
          attachment_id: attachment.id,
          object_id: attachment.object_id,
          object_type: attachment.object_type,
        });
      } catch (error) {
        notifications.show({
          title: "Error",
          message: `Failed to delete file: ${attachment.filename}`,
        });
      }
    },
    [deleteAttachment],
  );

  return { onDelete, onDownload, onUpdate };
}
