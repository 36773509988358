import {
  BundleLineItem,
  GetPackageBundleReturns,
} from "src/data/api/types/getBundles";

export const buildLineItems = (data: GetPackageBundleReturns) => {
  const bundles = data.collection.data
    .map((bundle) => {
      if (bundle.attributes.bundle_type === "group") {
        return bundle.attributes.children;
      }

      return bundle;
    })
    .flat();

  const lineItems = bundles
    .map((bundle) => bundle.attributes.line_items)
    .flat()
    .map((item) => ({
      ...item,
      children: [],
    }))
    .filter(
      (item, index, self) => index === self.findIndex((i) => i.id === item.id),
    );

  const lineItemsWithParent = lineItems.filter(
    (item) => item.parent_id != null,
  );

  const formattedLineItems = lineItems
    .map((item) => {
      return {
        ...item,
        children: lineItemsWithParent
          .map((lineItem) => ({
            ...lineItem,
            children: lineItemsWithParent.filter(
              (innerItem) => innerItem.parent_id === lineItem.id,
            ),
          }))
          .filter((lineItem) => lineItem.parent_id === item.id),
      };
    })
    .filter(
      (item) =>
        lineItemsWithParent.some((lineItem) => lineItem.id === item.id) != true,
    );

  return bundles.reduce(
    (map, bundle) => {
      return {
        ...map,
        [bundle.id]: formattedLineItems.filter(
          (item) => item.bundle_id === bundle.attributes.id,
        ),
      };
    },
    {} as Record<number, BundleLineItem[]>,
  );
};

export const buildBundles = (data: GetPackageBundleReturns) => {
  return data.collection.data
    .filter(
      (bundle) =>
        bundle.attributes.line_items.length > 0 ||
        bundle.attributes.children?.length > 0,
    )
    .sort((data1, data2) => {
      return (
        (data1.attributes.position ?? 0) - (data2.attributes.position ?? 0)
      );
    });
};

export const transformGetBundlesResponseV3 = (
  data: GetPackageBundleReturns,
) => {
  const bundles = buildBundles(data);
  const lineItemsByBundle = buildLineItems(data);

  return {
    bundles,
    lineItemsByBundle,
  };
};
