import {
  CloseButton,
  Combobox,
  Input,
  InputWrapperProps,
  TextInput,
  useCombobox,
} from "@mantine/core";
import classNames from "classnames";
import React from "react";
import { PREVENT_DEFAULT } from "src/constants/preventDefault";
import { EMPTY_STRING } from "src/utils/empty";
import CostTypeDropdownOption from "./components/CostTypeDropdownOption";
import { DropdownOption, TEXT_INPUT_STYLES } from "./constants";
import styles from "./DropdownCell.module.scss";
import { DropdownCellSelected } from "./DropdownCellSelected";
import { useCellKeystrokes } from "./hooks/useCellKeystrokes";

interface Props extends Omit<InputWrapperProps, "onChange"> {
  readonly disabled?: boolean;
  readonly options: ReadonlyArray<DropdownOption>;
  readonly displayClassName: string | undefined;
  readonly placeholders: {
    readonly active: string;
    readonly inactive: string;
  };
  readonly value?: string;
  category: string | null;
  handleUpdate: (value: string, category?: string | null) => void;
}

export const DropdownCell = React.memo<Props>(function _DropdownCell({
  handleUpdate,
  disabled = false,
  options,
  displayClassName,
  placeholders,
  value,
  category,
  ...inputWrapperProps
}) {
  const inputRef = React.useRef<HTMLInputElement>(null);
  const [search, setSearch] = React.useState(value ?? EMPTY_STRING);
  const [isEdit, setIsEdit] = React.useState(false);
  const [openPopover, setOpenPopover] = React.useState(false);

  const selectedOption = React.useMemo(() => {
    return !isEdit
      ? options.find((option) => option.value === search)
      : undefined;
  }, [isEdit, options, search]);

  const combobox = useCombobox({
    onDropdownClose: () => combobox.resetSelectedOption(),
    onDropdownOpen: (eventSource) => {
      if (eventSource === "keyboard") {
        combobox.selectActiveOption();
      } else {
        combobox.updateSelectedOptionIndex("active");
      }
    },
  });

  const filteredOptions = React.useMemo(() => {
    return options;
  }, [options]);

  const handleDropdownOpen = React.useCallback(() => {
    setIsEdit(true);
    combobox.openDropdown();
  }, [combobox]);

  const handleSearchChange = React.useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setSearch(event.currentTarget.value);
      combobox.updateSelectedOptionIndex("active");
    },
    [combobox],
  );

  const handleClear = React.useCallback(() => {
    setSearch(EMPTY_STRING);
    combobox.openDropdown();
  }, [combobox]);

  const handleOptionSubmit = React.useCallback(
    (submittedValue: string) => {
      setSearch(submittedValue);
      combobox.updateSelectedOptionIndex("active");

      if (
        options.find((option) => option.value === submittedValue)?.categories
          ?.length
      ) {
        setOpenPopover(true);
      } else {
        setOpenPopover(false);
        handleUpdate(submittedValue, null);
        combobox.closeDropdown();
        setIsEdit(false);
      }
    },
    [combobox, handleUpdate, options],
  );

  const handleSearchBlur = React.useCallback(() => {
    if (search === EMPTY_STRING) {
      handleOptionSubmit(search);
    } else if (options.find((option) => option.value === search) == null) {
      handleOptionSubmit(value ?? EMPTY_STRING);
    } else {
      const newCategory = options
        .find((option) => option.value === search)
        ?.categories?.find(
          (categoryOption) => categoryOption.value === category,
        )?.value;

      handleUpdate(search, newCategory ?? null);
      setOpenPopover(false);
      combobox.closeDropdown();
      setIsEdit(false);
    }
  }, [
    category,
    combobox,
    handleOptionSubmit,
    handleUpdate,
    options,
    search,
    value,
  ]);

  const { handleEscapeBlur: handleKeyPresses } = useCellKeystrokes({
    ref: inputRef,
  });

  /* 
    Prevents cell interaction if user clicks w/ any non-primary mouse button
  */
  const handleMouseDown = React.useCallback((event: React.MouseEvent) => {
    if (event.button !== 0) {
      event.preventDefault();
    }
  }, []);

  const handleEditSelection = React.useCallback(() => {
    if (disabled === false) {
      setIsEdit(true);
    }
  }, [disabled]);

  React.useEffect(() => {
    if (isEdit) {
      inputRef.current?.focus();
    }
  }, [isEdit]);

  React.useEffect(() => {
    if (!isEdit) {
      setSearch(value ?? EMPTY_STRING);
    }
  }, [isEdit, value]);

  if (selectedOption != null) {
    return (
      <DropdownCellSelected
        category={category}
        displayClassName={displayClassName}
        item={selectedOption}
        onClick={handleEditSelection}
      />
    );
  }

  return (
    <div
      className={classNames(styles.root, isEdit ? styles.editMode : undefined)}
    >
      <Input.Wrapper {...inputWrapperProps} className={styles.textField}>
        <Combobox
          onOptionSubmit={handleOptionSubmit}
          store={combobox}
          styles={{
            dropdown: {
              minWidth: "190px",
            },
          }}
        >
          <Combobox.Target>
            <TextInput
              ref={inputRef}
              disabled={disabled}
              onBlur={handleSearchBlur}
              onChange={handleSearchChange}
              onFocus={handleDropdownOpen}
              onKeyDown={handleKeyPresses}
              onMouseDown={handleMouseDown}
              placeholder={
                !isEdit ? placeholders.inactive : placeholders.active
              }
              rightSection={
                search !== EMPTY_STRING &&
                !disabled &&
                isEdit && (
                  <CloseButton
                    aria-label="Clear value"
                    onClick={handleClear}
                    // prevent other fields from losing focus if user interacts with CloseButton while on another field
                    onMouseDown={PREVENT_DEFAULT}
                    size="sm"
                  />
                )
              }
              rightSectionPointerEvents={
                search !== EMPTY_STRING ? "all" : "none"
              }
              styles={TEXT_INPUT_STYLES}
              value={search}
              variant={"unstyled"}
            />
          </Combobox.Target>

          <Combobox.Dropdown style={{ overflow: "visible" }}>
            <Combobox.Options>
              {filteredOptions.map((item, index) => (
                <Combobox.Option
                  key={item.value}
                  className={styles.option}
                  onMouseOver={() => combobox.selectOption(index)}
                  selected={item.value === search}
                  value={item.value}
                >
                  <CostTypeDropdownOption
                    currentCategory={category}
                    currentItem={search}
                    handleUpdate={(
                      costType: string,
                      newCategory?: string | null,
                    ) => {
                      combobox.closeDropdown();
                      setIsEdit(false);
                      setOpenPopover(false);
                      handleUpdate(costType, newCategory);
                    }}
                    item={item}
                    openPop={openPopover}
                    setOpenPop={setOpenPopover}
                  />
                </Combobox.Option>
              ))}
            </Combobox.Options>
          </Combobox.Dropdown>
        </Combobox>
      </Input.Wrapper>
    </div>
  );
});
