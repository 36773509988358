import { Button, Group, Modal } from "@mantine/core";
import React from "react";
import { useForm } from "react-hook-form";
import { NewContactFormContent } from "src/components/Forms/NewContactFormContent";
import {
  DEFAULT_NEW_CONTACT_VALUE,
  NewContact,
} from "src/components/Forms/types/contact";
import { PersistenceType } from "src/components/Forms/types/persistenceType";
import { FormFooter } from "src/components/Frames/FormFooter";
import { api } from "src/data/api/api";
import {
  Association,
  AssociationType,
  ResourceType,
} from "src/data/api/types/bulkTransaction";
import { selectCurrentCompanyId } from "src/data/selectors/auth";
import { useAppSelector } from "src/data/store";
import { showBulkTransactionNotifications } from "src/utils/notifications/showBulkTransactionNotifications";

type FormValue = NewContact["data"];

interface Props {
  readonly onClose: () => void;
}

export const NewContactDialog = React.memo<Props>(function _NewContactDialog({
  onClose,
}) {
  const { control, handleSubmit } = useForm<FormValue>({
    defaultValues: DEFAULT_NEW_CONTACT_VALUE,
  });
  const currentCompanyId = useAppSelector(selectCurrentCompanyId);
  const [trigger, mutationState] = api.endpoints.bulkTransaction.useMutation();

  const handleNewContactCreate = React.useCallback(
    async (submittedValue: FormValue) => {
      const contactUuid = crypto.randomUUID();
      if (submittedValue.workplace == null) {
        return;
      }

      const customerId =
        submittedValue.workplace?.type === PersistenceType.EXISTING
          ? submittedValue.workplace.data.uuid
          : submittedValue.workplace.uuid;

      const associations: Array<Association> = [];
      try {
        await trigger({
          bulk_transaction: {
            associations: [
              {
                type: AssociationType.ContactRole,
                contact_id: contactUuid,
                resource_id: customerId,
                resource_type: ResourceType.Customer,
                metadata: {
                  name: submittedValue.role ?? undefined,
                },
              },
              ...associations,
            ],
            contacts_attributes: [
              {
                uuid: contactUuid,
                company_id: currentCompanyId,
                email: submittedValue.email,
                first_name: submittedValue.firstName,
                last_name: submittedValue.lastName,
                phone: submittedValue.phoneNumber,
              },
            ],
          },
        }).unwrap();
        onClose();
      } catch (error) {
        showBulkTransactionNotifications(error);
      }
    },
    [trigger, currentCompanyId, onClose],
  );

  return (
    <Modal
      centered={true}
      onClose={onClose}
      opened={true}
      size="lg"
      title="Create new contact"
    >
      <NewContactFormContent
        control={control}
        shouldRenderRole={true}
        shouldRenderWorkplace={true}
      />

      <FormFooter
        rightSection={
          <Group gap={10}>
            <Button onClick={onClose} variant="outline">
              Cancel
            </Button>
            <Button
              loading={mutationState.isLoading}
              onClick={handleSubmit(handleNewContactCreate)}
            >
              Create contact
            </Button>
          </Group>
        }
      />
    </Modal>
  );
});
