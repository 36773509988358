import { ActionIcon, MantineSize } from "@mantine/core";
import { IconPaperclip } from "@tabler/icons-react";
import React, { forwardRef } from "react";
import { WithClassname } from "src/types/withClassName";

interface Props extends WithClassname {
  buttonSize?: MantineSize;
  iconSize?: number;
  onClick?: () => void;
}

export const AttachmentIcon = React.memo(
  forwardRef<HTMLButtonElement, Props>(function _AttachmentIcon(
    { buttonSize, className, iconSize = 18, onClick },
    ref,
  ) {
    return (
      <ActionIcon
        ref={ref}
        onClick={onClick}
        size={buttonSize}
        variant="transparent"
      >
        <IconPaperclip className={className} size={iconSize} />
      </ActionIcon>
    );
  }),
);
