import { Group, Text } from "@mantine/core";
import { DataTableColumnTextAlign } from "mantine-datatable";
import { useMemo } from "react";
import { getDropdownOptions } from "src/components/EstimationView/getDropdownOptions";
import {
  DropdownType,
  PRICE_METHOD,
} from "src/components/EstimationView/Table/constants";
import { DropdownCellSelected } from "src/components/EstimationView/Table/DropdownCellSelected";
import { ExpandIcon } from "src/components/EstimationView/Table/ExpandIcon";
import { CostCell } from "src/components/Frames/Table/CostCell";
import { LaborHoursCell } from "src/components/Frames/Table/LaborHoursCell";
import { MarginCell } from "src/components/Frames/Table/MarginCell";
import { MarginTotalCell } from "src/components/Frames/Table/MarginTotalCell";
import { PercentOfCostCell } from "src/components/Frames/Table/PercentOfCostCell";
import { TotalCostCell } from "src/components/Frames/Table/TotalCostCell";
import { PackageTotal } from "src/data/api/types/getPackageTotals";
import { useQueryStringParams } from "src/hooks/useQueryStringParams";
import { calculateTotal } from "src/utils/calculateTotal";
import { formatCurrency } from "src/utils/formatCurrency";
import styles from "../TradeColumns.module.scss";

export const useTradeColumns = (
  records: PackageTotal[],
  expandedLineItemNames: string[],
) => {
  const [priceMethod] = useQueryStringParams(PRICE_METHOD);
  const WIDTH = "13%";

  const showFooter = records.length > 0;

  return useMemo(() => {
    return [
      {
        width: "22%",
        accessor: "trade",
        title: "TRADE",
        render: (v: PackageTotal) => {
          const { displayClassName, options } = getDropdownOptions(
            DropdownType.Trade,
          );

          const item = options[0]?.options.find(
            (option) => option.value === v.name,
          );

          if (item != null) {
            return (
              <Group className={styles.tradeWrapper}>
                <ExpandIcon
                  className={styles.expandIcon}
                  isExpanded={expandedLineItemNames.includes(v.name)}
                />
                <DropdownCellSelected
                  displayClassName={displayClassName}
                  item={item}
                />
              </Group>
            );
          }
        },
        footer: showFooter ? (
          <Text className={styles.totals}>Totals</Text>
        ) : null,
        textAlign: "left" as DataTableColumnTextAlign,
      },
      {
        width: WIDTH,
        accessor: "percent_of_cost",
        title: "% OF COST",
        render: (v: PackageTotal) => (
          <PercentOfCostCell record={v} records={records} withTaxes={true} />
        ),
        footer: showFooter ? <Text className={styles.totals}>100%</Text> : null,
      },
      {
        width: WIDTH,
        accessor: "rate",
        title: "RATE",
        render: (v: PackageTotal) => (
          <Text className={styles.content}>{formatCurrency(v.rate ?? 0)}</Text>
        ),
      },
      {
        width: WIDTH,
        accessor: "labor_hrs",
        title: "LABOR HRS",
        render: (v: PackageTotal) => <LaborHoursCell record={v} />,
        footer: showFooter ? (
          <Text
            className={styles.totals}
          >{`${calculateTotal(records, "total_hours")} hrs`}</Text>
        ) : null,
      },
      {
        width: WIDTH,
        accessor: "cost",
        title: "COST",
        render: (v: PackageTotal) => <CostCell record={v} />,
        footer: showFooter ? <TotalCostCell records={records} /> : null,
      },
      {
        width: WIDTH,
        accessor: "margin",
        title: priceMethod === "markup" ? "MARKUP" : "MARGIN",
        render: (v: PackageTotal) => (
          <MarginCell priceMethod={priceMethod} record={v} />
        ),
        footer: showFooter ? <MarginTotalCell records={records} /> : null,
      },
      {
        width: WIDTH,
        accessor: "sell_price",
        title: "SELL PRICE",
        render: (v: PackageTotal) => (
          <div className={styles.content}>
            {formatCurrency(Number(v.grand_total))}
          </div>
        ),
        footer: showFooter ? (
          <Text className={styles.totals}>
            {formatCurrency(calculateTotal(records, "grand_total"))}
          </Text>
        ) : null,
      },
    ];
  }, [expandedLineItemNames, priceMethod, records, showFooter]);
};
