import { notifications } from "@mantine/notifications";
import {
  createApi,
  fetchBaseQuery,
  type BaseQueryApi,
  type FetchArgs,
} from "@reduxjs/toolkit/query/react";
import { Recipe } from "node_modules/@reduxjs/toolkit/dist/query/core/buildThunks";
import { ConsumerSingleton } from "src/cable/consumer";
import { subscriptions } from "src/cable/subscriptions";
import { extractCompanyIdFromToken } from "src/data/selectors/auth";
import { RootState } from "src/data/store";
import { EMPTY_STRING } from "src/utils/empty";
import { showEntityNotification } from "src/utils/notifications/showEntityNotification";
import {
  transformAssembliesResponse,
  transformAssemblyResponse,
} from "src/utils/transformAssembliesResponse";
import {
  buildLineItems,
  transformGetBundlesResponseV3,
} from "src/utils/transfromGetBundlesResponseV3";
import { Method } from "../../constants/method";
import { resourceCacheSlice } from "../slices/resourceCache";
import { AddAttachmentsArgs } from "./types/addAttachments";
import { AddCrewMixArgs } from "./types/addCrewMix";
import { AddNoteArgs } from "./types/addNote";
import { AddPackageBundleArgs } from "./types/addPackageBundle";
import { AddProposalArgs, AddProposalReturns } from "./types/addProposal";
import { AttachAssemblyToPackageArgs } from "./types/attachAssembly";
import {
  ActionType,
  BulkTransactionArgs,
  BulkTransactionReturns,
  EntityResource,
  ResourceType,
} from "./types/bulkTransaction";
import { CalculateEstimationArgs } from "./types/calculateEstimation";
import { CloneItemsArgs } from "./types/cloneItems";
import { CreateAssemblyArgs } from "./types/createAssembly";
import {
  CreateNewVersionArgs,
  CreateNewVersionReturn,
} from "./types/createEstimationVersion";
import { CreateListViewArgs } from "./types/createListView";
import { CreatePackageArgs, CreatePackageReturns } from "./types/createPackage";
import { DeleteAttachmentArgs } from "./types/deleteAttachment";
import { DeleteLineItemsArgs } from "./types/deleteLineItems";
import { GeneratePdfArgs, GeneratePdfReturns } from "./types/generatePdf";
import {
  GeneratePresignedUrlArgs,
  GeneratePresignedUrlReturns,
} from "./types/generatePresignedUrl";
import { GetAssembliesArgs, GetAssembliesReturns } from "./types/getAssemblies";
import { GetAssemblyReturn } from "./types/getAssembly";
import {
  GetAttachmentsArgs,
  GetAttachmentsReturns,
} from "./types/getAttachments";
import { GetBidReturns } from "./types/getBid";
import { GetBidAttachmentsReturns } from "./types/getBidAttachments";
import {
  GetBidOptionsReturns,
  getTransformedBidOptions,
} from "./types/getBidOptions";
import { GetBidsArgs, GetBidsReturns } from "./types/getBids";
import {
  GetBundlesReturns,
  LineItemsUpdatesData,
  PackageBundleReturnData,
} from "./types/getBundles";
import { GetCompanyReturns } from "./types/getCompany";
import { GetContactReturns } from "./types/getContact";
import {
  GetContactRoleOptionsReturns,
  getTransformedContactRoleOptions,
} from "./types/getContactRoleOptions";
import { GetContactsArgs, GetContactsReturns } from "./types/getContacts";
import { GetCostTypesArgs, GetCostTypesReturns } from "./types/getCostTypes";
import {
  GetCostTypesByGroupArgs,
  GetCostTypesTotalsByTradeArgs,
  GetCostTypesTotalsByTradeReturn,
} from "./types/getCostTypeTotalsByTrade";
import { GetCrewMixReturns } from "./types/getCrewMix";
import { GetCrewMixesReturns } from "./types/getCrewMixes";
import { GetCustomerReturns } from "./types/getCustomer";
import {
  GetCustomerOptionsReturns,
  getTransformedCustomerOptions,
} from "./types/getCustomerOptions";
import { GetCustomersArgs, GetCustomersReturns } from "./types/getCustomers";
import { GetDashboardArgs, GetDashboardReturns } from "./types/getDashboard";
import { GetJobSiteReturns } from "./types/getJobSite";
import {
  getTransformedJobSiteOptions,
  TransformedGetJobSiteOptionsReturns,
} from "./types/getJobSiteOptions";
import { GetJobSitesArgs, GetJobSitesReturns } from "./types/getJobSites";
import { GetLaborSourceReturns } from "./types/getLaborSource";
import { GetLaborSourceOptionsReturns } from "./types/getLaborSourceOptions";
import {
  GetLaborSourcesFilterArgs,
  GetLaborSourcesReturns,
} from "./types/getLaborSources";
import { GetListViewsReturns } from "./types/getListViews";
import { GetNormalizedAddressesReturns } from "./types/getNormalizedAddresses";
import { GetPackageReturns } from "./types/getPackage";
import { GetPackagesReturns } from "./types/getPackagesArgs";
import {
  GetPackageTotalsArgs,
  GetPackageTotalsReturns,
} from "./types/getPackageTotals";
import { GetPackageProfitCalculationReturns } from "./types/getProfitCalculation";
import { GetProposalReturns } from "./types/getProposal";
import { GetProposalsReturns } from "./types/getProposals";
import { GetUserReturns } from "./types/getUser";
import { GetUsersArgs, GetUsersReturns } from "./types/getUsers";
import { LoginArgs } from "./types/login";
import { Estimation } from "./types/shared/bid";
import { FilterArgs } from "./types/shared/filterSort";
import { SignUpArgs } from "./types/signUp";
import { SortItemsArgs } from "./types/sortItems";
import {
  UpdateAttachmentArgs,
  UpdateAttachmentReturns,
} from "./types/updateAttachment";
import { UpdateBundleArgs } from "./types/updateBundleArgs";
import { UpdateCrewMixArgs } from "./types/updateCrewMix";
import { UpdateEstimationArgs } from "./types/updateEstimation";
import { UpdateListViewArgs } from "./types/updateListView";
import { UpdateNoteArgs } from "./types/updateNote";
import { UpdatePackageArgs } from "./types/updatePackage";
import { UpdateProfitCalculationArgs } from "./types/updateProfitCalculation";
import { UpdateProposalArgs } from "./types/updateProposal";
import { UpdateUserArgs } from "./types/updateUser";
import {
  getAuthToken,
  invalidatesTagsOnUpdateBundle,
  removeAuthToken,
  setAuthToken,
  TAG,
  updateBundlesCacheData,
  updateCacheOnAssemblyAttach,
  updateCacheOnCreateLineItem,
  updateCacheOnDeleteBundle,
  updateCacheOnUpdateBundle,
} from "./utils";

const PROD_URL = import.meta.env.VITE_SERVER_URL;
const BASE_URL = import.meta.env.DEV ? EMPTY_STRING : PROD_URL; // Dev proxy is configured in vite.config.ts

const dynamicBaseQuery = ({
  baseUrl,
  mode,
}: {
  baseUrl: string;
  mode: RequestMode;
}) => {
  // Create a fetchBaseQuery with the given options
  const baseQuery = fetchBaseQuery({
    baseUrl,
    mode,
    prepareHeaders: (headers, { getState }) => {
      const state = getState() as RootState;
      const token = state.auth.token;

      if (token) {
        headers.set("Authorization", `Bearer ${token}`);
      }

      headers.set("Content-Type", "application/json");
      return headers;
    },
  });

  // Wrap baseQuery to dynamically modify requests
  return async (
    args: string | FetchArgs,
    api: BaseQueryApi,
    extraOptions: { [key: string]: unknown },
  ) => {
    const { getState } = api;
    const state = getState() as RootState;
    const token = state.auth.token;
    let companyId: string | undefined = undefined;

    if (token != null) {
      companyId = extractCompanyIdFromToken(token);
    }

    // Ensure args is an object
    if (typeof args === "string") {
      // eslint-disable-next-line no-param-reassign
      args = { url: args };
    }

    // Inject companyId into the URL
    if (args.url && companyId) {
      if (args.url.startsWith("/api/companies/")) {
        // eslint-disable-next-line immutable/no-mutation, no-param-reassign
        args.url = args.url.replace(":companyId", companyId);
      }
    }

    // Make the API request using the modified args
    try {
      const response = await baseQuery(args, api, extraOptions);

      // Handle 401 Unauthorized error
      if (response.error?.status === 401 && !isShowingSessionError) {
        isShowingSessionError = true;
        notifications.show({
          title: "Error",
          message: "Your session has expired. Please sign in again.",
          onClose: () => {
            isShowingSessionError = false;
          },
        });
      }

      return response;
    } catch (error) {
      return { error };
    }
  };
};

const websocketUrl = (token?: string) => {
  const host = import.meta.env.DEV
    ? "localhost:3000/api"
    : BASE_URL.split("//")[1];
  const protocol = import.meta.env.DEV ? "ws" : "wss";

  return `${protocol}://${host}/cable?token=${token}`;
};

const cacheUpdateFunctionMap: Map<
  string,
  (draft: Recipe<GetBundlesReturns>) => void
> = new Map();

let isShowingSessionError = false;

export const api = createApi({
  reducerPath: "api",
  baseQuery: dynamicBaseQuery({
    baseUrl: BASE_URL,
    mode: "cors",
  }),
  tagTypes: Object.values(TAG),

  endpoints: (builder) => ({
    /*
      Addresses
    */
    getNormalizedAddresses: builder.query<
      GetNormalizedAddressesReturns,
      string
    >({
      query: (address) => ({
        url: `/api/addresses/normalize?full_address=${encodeURIComponent(address)}`,
        method: Method.GET,
      }),
    }),

    /*
      Bids
    */
    getBid: builder.query<GetBidReturns, string>({
      query: (bidId) => ({
        url: `/api/companies/:companyId/bids/${bidId}`,
        method: Method.GET,
      }),
      providesTags: [{ type: TAG.BID }],
    }),

    getBids: builder.query<GetBidsReturns, GetBidsArgs | undefined>({
      query: (body) => ({
        url: `/api/companies/:companyId/bids/filter`,
        method: Method.POST,
        body,
      }),
      providesTags: [{ type: TAG.BID }],
    }),

    getBidOptions: builder.query<GetBidOptionsReturns, void>({
      query: () => ({
        url: "/api/companies/:companyId/bids/options",
        method: Method.GET,
      }),
      transformResponse: getTransformedBidOptions,
    }),

    getBidAttachments: builder.query<GetBidAttachmentsReturns, string>({
      query: (id) => ({
        url: `/api/companies/:companyId/bids/${id}/attachments`,
        method: Method.GET,
      }),
      providesTags: [TAG.ATTACHMENT],
    }),

    deleteBid: builder.mutation<void, string>({
      query: (bidId) => ({
        url: `/api/companies/:companyId/bids/${bidId}`,
        method: Method.DELETE,
      }),
      invalidatesTags: [
        { type: TAG.BID },
        { type: TAG.CONTACT },
        { type: TAG.CUSTOMER },
        { type: TAG.JOB_SITE },
        { type: TAG.USER },
      ],
    }),

    /*
      Bulk Transactions
    */
    bulkTransaction: builder.mutation<
      BulkTransactionReturns,
      BulkTransactionArgs
    >({
      query: (bulk_transaction) => ({
        url: "/api/companies/:companyId/bulk_transaction",
        method: Method.POST,
        body: bulk_transaction,
      }),
      onQueryStarted: async (_, { queryFulfilled }) => {
        try {
          const { data } = await queryFulfilled;

          const bidResource =
            data.bulk_transaction?.data?.attributes?.updated_resources?.find(
              (resource): resource is EntityResource =>
                resource.type === ResourceType.Bid &&
                resource.action === ActionType.Create,
            );
          const entityType = bidResource?.type;
          const entityId = bidResource?.id;

          if (entityType == null || entityId == null) {
            return;
          }

          showEntityNotification({
            entityId: entityId,
            entityType: entityType,
          });
        } catch (error) {
          // error notification
        }
      },
      invalidatesTags: [
        { type: TAG.BID },
        { type: TAG.CONTACT },
        { type: TAG.CUSTOMER },
        { type: TAG.JOB_SITE },
        { type: TAG.USER },
      ],
    }),

    /*
      Copy/Cut
    */
    cloneItems: builder.mutation<
      {
        bundles: { data: PackageBundleReturnData[] };
      },
      CloneItemsArgs
    >({
      query: (body) => ({
        url: "/api/clones",
        method: Method.POST,
        body,
      }),
      onQueryStarted: async (args, { dispatch, queryFulfilled }) => {
        const { data } = await queryFulfilled;

        if (args.action_type === "cut" && args.meta?.packageId != null) {
          dispatch(
            api.util.updateQueryData(
              "getBundles",
              args.meta.packageId,
              (draft) => {
                if (args.bundle_ids.length > 0) {
                  const ids = args.bundle_ids.map(({ id }) => id);

                  args.bundle_ids.forEach((bundle) => {
                    // eslint-disable-next-line no-param-reassign
                    delete draft.lineItemsByBundle[bundle.id];
                  });

                  // eslint-disable-next-line immutable/no-mutation, no-param-reassign
                  draft.bundles = draft.bundles.filter(
                    (bundle) => !ids.includes(bundle.attributes.id),
                  );
                }
              },
            ),
          );
        }

        const packageId =
          data.bundles.data[0]?.attributes.package_id.toString();

        if (packageId == null) return;

        dispatch(
          api.util.updateQueryData("getBundles", packageId, (draft) => {
            if (args.location.type === "Bundle") {
              draft.bundles.forEach((bundle, index, bundles) => {
                if (bundle.attributes.id === args.location.id) {
                  // eslint-disable-next-line immutable/no-mutation, no-param-reassign
                  const { children } = bundle.attributes;

                  let currentIndex =
                    children.findIndex(
                      (child) =>
                        child.attributes.id === args.meta?.placeBelowBundleId,
                    ) + 1;

                  if (currentIndex < 1) {
                    currentIndex = children.length;
                  }

                  children.splice(currentIndex, 0, ...data.bundles.data);

                  // eslint-disable-next-line immutable/no-mutation, no-param-reassign
                  bundles[index] = {
                    ...bundle,
                    attributes: {
                      ...bundle.attributes,
                      children,
                    },
                  };
                }
              });
            } else {
              let currentIndex =
                draft.bundles.findIndex(
                  (bundle) =>
                    bundle.attributes.id === args.meta?.placeBelowBundleId,
                ) + 1;

              if (currentIndex < 1) {
                currentIndex = draft.bundles.length;
              }

              draft.bundles.splice(currentIndex, 0, ...data.bundles.data);
            }

            // eslint-disable-next-line immutable/no-mutation, no-param-reassign
            draft.lineItemsByBundle = {
              ...draft.lineItemsByBundle,
              ...buildLineItems({
                collection: {
                  data: data.bundles.data,
                },
              }),
            };
          }),
        );
      },
      invalidatesTags: [TAG.PACKAGE],
    }),

    /*
      Companies
    */
    getCompany: builder.query<GetCompanyReturns, void>({
      query: () => ({
        url: "/api/companies/:companyId",
        method: Method.GET,
      }),
    }),

    /*
      Contacts
    */
    getContacts: builder.query<GetContactsReturns, GetContactsArgs | undefined>(
      {
        query: (body) => ({
          url: `/api/companies/:companyId/contacts/filter`,
          method: Method.POST,
          body,
        }),
        onQueryStarted: async (_, { dispatch, queryFulfilled }) => {
          const { data } = await queryFulfilled;
          dispatch(
            resourceCacheSlice.actions.addContacts(data.collection.data),
          );
        },
        providesTags: [{ type: TAG.CONTACT }],
      },
    ),
    /*
      Contact Roles
    */
    getContact: builder.query<GetContactReturns, string>({
      query: (customerId) => ({
        url: `/api/companies/:companyId/contacts/${customerId}`,
        method: Method.GET,
      }),
      onQueryStarted: async (_, { dispatch, queryFulfilled }) => {
        const { data } = await queryFulfilled;
        dispatch(resourceCacheSlice.actions.addContacts([data.contact.data]));
      },
      providesTags: [{ type: TAG.CONTACT }],
    }),

    getContactRoleOptions: builder.query<GetContactRoleOptionsReturns, void>({
      query: () => ({
        url: "/api/contact_roles/options",
        method: Method.GET,
      }),
      transformResponse: getTransformedContactRoleOptions,
    }),

    deleteContact: builder.mutation<void, string>({
      query: (contactId) => ({
        url: `/api/companies/:companyId/contacts/${contactId}`,
        method: Method.DELETE,
      }),
      invalidatesTags: [
        { type: TAG.BID },
        { type: TAG.CONTACT },
        { type: TAG.CUSTOMER },
        { type: TAG.JOB_SITE },
        { type: TAG.USER },
      ],
    }),

    /*
      Customers
    */
    getCustomer: builder.query<GetCustomerReturns, string>({
      query: (customerId) => ({
        url: `/api/companies/:companyId/customers/${customerId}`,
        method: Method.GET,
      }),
      onQueryStarted: async (_, { dispatch, queryFulfilled }) => {
        const { data } = await queryFulfilled;
        dispatch(resourceCacheSlice.actions.addCustomers([data.customer.data]));
      },
      providesTags: [{ type: TAG.CUSTOMER }],
    }),

    getCustomers: builder.query<
      GetCustomersReturns,
      GetCustomersArgs | undefined
    >({
      query: (body) => ({
        url: `/api/companies/:companyId/customers/filter`,
        method: Method.POST,
        body,
      }),
      onQueryStarted: async (_, { dispatch, queryFulfilled }) => {
        const { data } = await queryFulfilled;
        dispatch(resourceCacheSlice.actions.addCustomers(data.collection.data));
      },
      providesTags: [{ type: TAG.CUSTOMER }],
    }),

    getCustomerOptions: builder.query<GetCustomerOptionsReturns, void>({
      query: () => ({
        url: "/api/companies/:companyId/customers/options",
        method: Method.GET,
      }),
      transformResponse: getTransformedCustomerOptions,
    }),

    deleteCustomer: builder.mutation<void, string>({
      query: (customerId) => ({
        url: `/api/companies/:companyId/customers/${customerId}`,
        method: Method.DELETE,
      }),
      invalidatesTags: [
        { type: TAG.BID },
        { type: TAG.CONTACT },
        { type: TAG.CUSTOMER },
        { type: TAG.JOB_SITE },
        { type: TAG.USER },
      ],
    }),

    /*
      Estimations
    */
    updateEstimation: builder.mutation<void, UpdateEstimationArgs>({
      query: (body) => ({
        url: `/api/estimations/${body.id}`,
        method: Method.PUT,
        body: body.estimation,
      }),
      invalidatesTags: [TAG.BID],
    }),

    markEstimationCurrent: builder.mutation<void, string>({
      query: (estimationId) => ({
        url: `/api/estimations/${estimationId}/mark_current`,
        method: Method.POST,
      }),
      invalidatesTags: [TAG.BID],
    }),

    deleteEstimation: builder.mutation<void, string>({
      query: (estimationId) => ({
        url: `/api/estimations/${estimationId}`,
        method: Method.DELETE,
      }),
      invalidatesTags: [TAG.ESTIMATION_VERSIONS],
    }),

    calculateEstimation: builder.mutation<void, CalculateEstimationArgs>({
      query: (body) => ({
        url: `/api/estimations/${body.id}/calculate`,
        method: Method.POST,
        body: body.options,
      }),
      invalidatesTags: [TAG.PACKAGE],
    }),

    allVersions: builder.query<{ collection: { data: Estimation[] } }, string>({
      query: (estimationId) => ({
        url: `/api/estimations/${estimationId}/all_versions`,
        method: Method.GET,
      }),
      providesTags: [TAG.ESTIMATION_VERSIONS],
    }),

    createNewVersion: builder.mutation<
      CreateNewVersionReturn,
      CreateNewVersionArgs
    >({
      query: (body) => ({
        url: `/api/estimations/${body.estimationId}/create_version`,
        method: Method.POST,
        body: body.version,
      }),
      invalidatesTags: [{ type: TAG.BID }],
    }),

    createPackage: builder.mutation<CreatePackageReturns, CreatePackageArgs>({
      query: (body) => ({
        url: `/api/packages`,
        method: Method.POST,
        body,
      }),
      onQueryStarted: async (args, { dispatch, queryFulfilled }) => {
        const { data } = await queryFulfilled;

        dispatch(
          api.util.updateQueryData(
            "getPackages",
            args.package.estimation_id,
            (draft) => {
              draft.collection.push(data.package.data.attributes);
            },
          ),
        );
      },
    }),

    getPackages: builder.query<GetPackagesReturns, string>({
      query: (estimationId) => ({
        url: `/api/estimations/${estimationId}/packages`,
        method: Method.GET,
      }),
      providesTags: (_returns, _error, estimationId) => [
        { type: TAG.PACKAGE, id: estimationId },
      ],
    }),

    getPackage: builder.query<GetPackageReturns, string>({
      query: (id) => ({
        url: `/api/packages/${id}`,
        method: Method.GET,
      }),
      providesTags: [{ type: TAG.PACKAGE }],
    }),

    updatePackage: builder.mutation<void, UpdatePackageArgs>({
      query: (arg) => ({
        url: `/api/packages/${arg.id}`,
        method: Method.PUT,
        body: arg.package,
      }),
      invalidatesTags: [TAG.PACKAGE],
    }),

    deletePackage: builder.mutation<void, string>({
      query: (id) => ({
        url: `/api/packages/${id}`,
        method: Method.DELETE,
      }),
      invalidatesTags: [TAG.PACKAGE],
    }),

    getBundles: builder.query<GetBundlesReturns, string>({
      query: (packageId) => ({
        url: `/api/packages/${packageId}/bundles`,
        method: Method.GET,
      }),
      transformResponse: transformGetBundlesResponseV3,
      providesTags: (_returns, _error, packageId) => [
        { type: TAG.BUNDLE, id: packageId },
      ],
      async onCacheEntryAdded(
        packageId,
        { cacheEntryRemoved, getState, cacheDataLoaded, updateCachedData },
      ) {
        const state = getState() as RootState;
        const token = state.auth.token;

        if (token == null) return;

        const consumer = ConsumerSingleton.getConsumer(websocketUrl(token));

        cacheUpdateFunctionMap.set(
          `cacheUpdateFunction_${packageId}`,
          updateCachedData,
        );

        try {
          // Wait for the cache to load
          const { data: cacheData } = await cacheDataLoaded;

          const companyUuid = cacheData.bundles[0]?.attributes.company_uuid;

          if (companyUuid == null) return;

          if (!subscriptions.has(companyUuid)) {
            // Create a subscription to the LineItemsUpdates channel
            const subscription = consumer.subscriptions.create(
              {
                channel: "LineItemsUpdates",
                company_uuid: companyUuid,
              },
              {
                received(data: LineItemsUpdatesData) {
                  const dataPackageId =
                    data.record?.package_id || data.old_record?.package_id;

                  if (dataPackageId == null) return;

                  const updateCached = cacheUpdateFunctionMap.get(
                    `cacheUpdateFunction_${dataPackageId}`,
                  );
                  if (updateCached != null) {
                    updateCached((draft) =>
                      updateBundlesCacheData(draft, data),
                    );
                  }
                },
              },
            );

            subscriptions.set(companyUuid, subscription);
          }
        } catch (error) {
          console.error("Error setting up subscription:", error);
        }

        // Cleanup the subscription when the cache entry is removed
        await cacheEntryRemoved;
      },
    }),

    getProfitCalculation: builder.query<
      GetPackageProfitCalculationReturns,
      string
    >({
      query: (packageId) => ({
        url: `/api/packages/${packageId}/profit_calculation`,
        method: Method.GET,
      }),
      providesTags: [{ type: TAG.PROFIT }],
    }),

    updateProfitCalculation: builder.mutation<
      void,
      UpdateProfitCalculationArgs
    >({
      query: (args) => ({
        url: `/api/profit_calculations/${args.id}`,
        method: Method.PUT,
        body: args.body,
      }),
      invalidatesTags: [
        TAG.TOTALS,
        TAG.PROFIT,
        TAG.PACKAGE,
        TAG.COST_TYPE_BY_TRADE,
      ],
    }),

    getPackageTotals: builder.query<
      GetPackageTotalsReturns,
      GetPackageTotalsArgs
    >({
      query: (args) => {
        return {
          url: `/api/packages/${args.packageId}/totals`,
          method: Method.GET,
          params: {
            distribution: args.distribution,
          },
        };
      },
      providesTags: [{ type: TAG.TOTALS }],
    }),

    getCostTypeTotalsByTrade: builder.query<
      GetCostTypesTotalsByTradeReturn,
      GetCostTypesTotalsByTradeArgs
    >({
      query: (args) => ({
        url: `/api/packages/${args.packageId}/cost_types_by_trade`,
        method: Method.GET,
        params: {
          trade: args.trade,
        },
      }),
      providesTags: [{ type: TAG.COST_TYPE_BY_TRADE }],
    }),

    deleteLineItems: builder.mutation<void, DeleteLineItemsArgs>({
      query: (args) => ({
        url: `/api/packages/${args.packageId}/delete_items`,
        method: Method.DELETE,
        body: args.lineItems,
      }),
      invalidatesTags: [TAG.TOTALS, TAG.COST_TYPES],
    }),

    getCostTypeTotalsByGroup: builder.query<
      GetCostTypesTotalsByTradeReturn,
      GetCostTypesByGroupArgs
    >({
      query: (args) => ({
        url: `/api/bundles/cost_types_by_group`,
        method: Method.GET,
        params: args,
      }),
      providesTags: [{ type: TAG.COST_TYPE_BY_TRADE }],
    }),

    createLineItem: builder.mutation<
      { bundle: { data: PackageBundleReturnData } },
      AddPackageBundleArgs
    >({
      query: (arg) => ({
        url: `/api/bundles`,
        method: Method.POST,
        body: arg,
      }),
      onQueryStarted: async (_, { dispatch, queryFulfilled }) => {
        const { data } = await queryFulfilled;

        dispatch(
          api.util.updateQueryData(
            "getBundles",
            data.bundle.data.attributes.package_id.toString(),
            (draft) => {
              updateCacheOnCreateLineItem(draft, data.bundle.data);
            },
          ),
        );
      },
    }),

    updateItems: builder.mutation<
      { bundle: { data: PackageBundleReturnData } },
      UpdateBundleArgs
    >({
      query: (arg) => ({
        url: `/api/bundles/${arg.bundle_id}`,
        method: Method.PUT,
        body: arg.line_item,
      }),
      onQueryStarted: async (arg, { dispatch, queryFulfilled }) => {
        const { data } = await queryFulfilled;

        if (
          arg.line_item.line_items_attributes?.some((lineItem) =>
            ["_destroy"].some((key) => key in lineItem),
          )
        ) {
          return;
        }

        dispatch(
          api.util.updateQueryData(
            "getBundles",
            data.bundle.data.attributes.package_id.toString(),
            (draft) => {
              updateCacheOnUpdateBundle(draft, data.bundle.data);
            },
          ),
        );
      },
      invalidatesTags: (_returns, _error, payload) =>
        invalidatesTagsOnUpdateBundle(payload),
    }),

    moveItems: builder.mutation<void, CloneItemsArgs>({
      query: (args) => ({
        url: "/api/drag_and_drops",
        method: Method.POST,
        body: args,
      }),
    }),

    sortItems: builder.mutation<void, SortItemsArgs>({
      query: (args) => ({
        url: `/api/bundles/sort`,
        method: Method.POST,
        body: args,
      }),
      onQueryStarted({ bundle_ids, ...patch }, { dispatch, queryFulfilled }) {
        const patchResult = dispatch(
          api.util.updateQueryData(
            "getBundles",
            patch.meta.packageId,
            (draft) => {
              const { bundles } = draft;
              let newBundles = bundles.slice();

              if (bundle_ids != null && bundle_ids[0] != null) {
                const theBundle = bundles.find(
                  (bundle) => bundle.attributes.id === bundle_ids[0]?.id,
                );

                newBundles = bundles.filter(
                  (bundle) => bundle.attributes.id !== bundle_ids[0]?.id,
                );

                if (theBundle != null) {
                  const newBundle: PackageBundleReturnData = {
                    ...theBundle,
                    attributes: {
                      ...theBundle.attributes,
                      position: bundle_ids[0].position ?? 1,
                    },
                  };
                  newBundles.splice(bundle_ids[0].index ?? 0, 0, newBundle);
                }
              }

              Object.assign(draft, {
                ...draft,
                bundles: newBundles,
              });
            },
          ),
        );

        queryFulfilled.catch(patchResult.undo);
      },
    }),

    deleteBundle: builder.mutation<
      { bundle: { data: PackageBundleReturnData } },
      number
    >({
      query: (bundleId) => ({
        url: `/api/bundles/${bundleId}`,
        method: Method.DELETE,
      }),
      invalidatesTags: [TAG.COST_TYPES],
      onQueryStarted: async (_, { dispatch, queryFulfilled }) => {
        const { data } = await queryFulfilled;

        dispatch(
          api.util.updateQueryData(
            "getBundles",
            data.bundle.data.attributes.package_id.toString(),
            (draft) => {
              updateCacheOnDeleteBundle(draft, data.bundle.data);
            },
          ),
        );
      },
    }),

    /*
      Assembly 
    */
    getAssemblies: builder.query<GetAssembliesReturns, GetAssembliesArgs>({
      query: (body) => ({
        url: "/api/assemblies/filter",
        method: Method.POST,
        body,
      }),
      providesTags: [{ type: TAG.ASSEMBLY }],
      transformResponse: transformAssembliesResponse,
    }),

    getAssembly: builder.query<GetAssemblyReturn, number>({
      query: (assemblyId) => ({
        url: `/api/assemblies/${assemblyId}`,
        method: Method.GET,
      }),
      transformResponse: transformAssemblyResponse,
      providesTags: (_returns, _error, assemblyId) => [
        { type: TAG.ASSEMBLY, id: assemblyId },
      ],
    }),

    createAssembly: builder.mutation<void, CreateAssemblyArgs>({
      query: (args) => ({
        url: "/api/assemblies",
        method: Method.POST,
        body: args,
      }),
      invalidatesTags: [TAG.ASSEMBLY],
    }),

    updateAssembly: builder.mutation<void, UpdateBundleArgs>({
      query: (arg) => ({
        url: `/api/assemblies/${arg.bundle_id}`,
        method: Method.PUT,
        body: arg.line_item,
      }),
      invalidatesTags: (_returns, _error, payload) => [
        { type: TAG.ASSEMBLY, id: payload.bundle_id },
        {
          type: TAG.ASSEMBLY,
        },
        {
          type: TAG.COST_TYPES,
        },
      ],
    }),

    deleteAssembly: builder.mutation<void, number>({
      query: (assemblyId) => ({
        url: `/api/assemblies/${assemblyId}`,
        method: Method.DELETE,
      }),
      invalidatesTags: [TAG.ASSEMBLY, TAG.COST_TYPES],
    }),

    attachAssembly: builder.mutation<
      { bundles: { data: PackageBundleReturnData[] } },
      AttachAssemblyToPackageArgs
    >({
      query: (args) => ({
        url: `/api/assemblies/${args.assemblyId}/attach`,
        method: Method.POST,
        body: args.body,
      }),
      invalidatesTags: [TAG.PACKAGE],
      onQueryStarted: async (_, { dispatch, queryFulfilled }) => {
        const { data } = await queryFulfilled;

        const packageId =
          data.bundles.data[0]?.attributes.package_id.toString();
        if (packageId == null) return;

        dispatch(
          api.util.updateQueryData("getBundles", packageId, (draft) => {
            updateCacheOnAssemblyAttach(draft, data.bundles.data);
          }),
        );
      },
    }),

    favoriteAssembly: builder.mutation<void, number>({
      query: (assemblyId) => ({
        url: `/api/favorites`,
        method: Method.POST,
        body: {
          favoritable_type: "Bundle",
          favoritable_id: assemblyId,
        },
      }),
    }),

    unfavoriteAssembly: builder.mutation<void, number>({
      query: (assemblyId) => ({
        url: `/api/favorites/${assemblyId}`,
        method: Method.DELETE,
        body: {
          favoritable_type: "Bundle",
          favoritable_id: assemblyId,
        },
      }),
    }),
    /* 
      Proposals
    */
    getProposals: builder.query<GetProposalsReturns, FilterArgs>({
      query: (body) => ({
        url: `/api/proposals/filter`,
        method: Method.POST,
        body: body,
      }),
      providesTags: [{ type: TAG.PROPOSAL }],
    }),

    getProposal: builder.query<GetProposalReturns, string>({
      query: (proposalId) => ({
        url: `/api/proposals/${proposalId}`,
        method: Method.GET,
      }),
      providesTags: [{ type: TAG.PROPOSAL }],
    }),

    addProposal: builder.mutation<AddProposalReturns, AddProposalArgs>({
      query: (proposal) => ({
        url: `/api/proposals`,
        method: Method.POST,
        body: proposal,
      }),
      invalidatesTags: [{ type: TAG.PACKAGE }, { type: TAG.PROPOSAL }],
    }),

    updateProposal: builder.mutation<void, UpdateProposalArgs>({
      query: (proposal) => ({
        url: `/api/proposals/${proposal.id}`,
        method: Method.PUT,
        body: proposal,
      }),
      invalidatesTags: [{ type: TAG.PACKAGE }, { type: TAG.PROPOSAL }],
    }),

    deleteProposal: builder.mutation<void, string>({
      query: (id) => ({
        url: `/api/proposals/${id}`,
        method: Method.DELETE,
      }),
      invalidatesTags: [{ type: TAG.PACKAGE }, { type: TAG.PROPOSAL }],
    }),

    generatePDF: builder.mutation<GeneratePdfReturns, GeneratePdfArgs>({
      query: (body) => ({
        url: `/api/proposals/${body.id}/generate_pdf`,
        method: Method.POST,
        body: { options: body.options },
      }),
    }),

    /* 
      Labor Sources
    */
    getLaborSources: builder.query<GetLaborSourcesReturns, void>({
      query: () => ({
        url: `/api/labor_sources/`,
        method: Method.GET,
      }),
    }),

    getLaborSourcesFilter: builder.query<
      GetLaborSourcesReturns,
      GetLaborSourcesFilterArgs
    >({
      query: (body) => ({
        url: `/api/labor_sources/filter`,
        method: Method.POST,
        body: body,
      }),
    }),

    getLaborSource: builder.query<GetLaborSourceReturns, string>({
      query: (laborSourceId) => ({
        url: `/api/labor_sources/${laborSourceId}`,
        method: Method.GET,
      }),
    }),

    /* 
      Trade Crew Mix Defaults
    */
    getLaborSourceOptions: builder.query<GetLaborSourceOptionsReturns, void>({
      query: () => ({
        url: "/api/labor_sources/options",
        method: Method.GET,
      }),
    }),

    /* 
      Crew Mixes
    */
    getCrewMixes: builder.query<GetCrewMixesReturns, void>({
      query: () => ({
        url: `/api/crew_mixes/`,
        method: Method.GET,
      }),
      providesTags: [{ type: TAG.CREW_MIX }],
    }),

    getPackageCrewMixes: builder.query<GetCrewMixesReturns, string>({
      query: (packageId) => ({
        url: `/api/packages/${packageId}/crew_mixes`,
        method: Method.GET,
      }),
      providesTags: [{ type: TAG.CREW_MIX }],
    }),

    getCrewMix: builder.query<GetCrewMixReturns, number | string>({
      query: (crewMixId) => ({
        url: `/api/crew_mixes/${crewMixId}`,
        method: Method.GET,
      }),
      providesTags: [{ type: TAG.CREW_MIX }],
    }),

    addCrewMix: builder.mutation<string | undefined, AddCrewMixArgs>({
      query: (crewMix) => ({
        url: `/api/crew_mixes`,
        method: Method.POST,
        body: crewMix,
      }),
      invalidatesTags: [
        { type: TAG.CREW_MIX },
        { type: TAG.PACKAGE },
        { type: TAG.TOTALS },
        { type: TAG.BUNDLE },
      ],
    }),

    updateCrewMix: builder.mutation<string | undefined, UpdateCrewMixArgs>({
      query: (crewMix) => ({
        url: `/api/crew_mixes/${crewMix.crew_mix_id}`,
        method: Method.PUT,
        body: crewMix,
      }),
      invalidatesTags: [
        { type: TAG.CREW_MIX },
        { type: TAG.PACKAGE },
        { type: TAG.TOTALS },
        { type: TAG.BUNDLE },
      ],
    }),

    deleteCrewMix: builder.mutation<void, number>({
      query: (id) => ({
        url: `/api/crew_mixes/${id}`,
        method: Method.DELETE,
      }),
      invalidatesTags: [
        { type: TAG.CREW_MIX },
        { type: TAG.PACKAGE },
        { type: TAG.TOTALS },
        { type: TAG.BUNDLE },
      ],
    }),

    /*
      Job Sites
    */
    getJobSite: builder.query<GetJobSiteReturns, string>({
      query: (jobSiteId) => ({
        url: `/api/companies/:companyId/job_sites/${jobSiteId}`,
        method: Method.GET,
      }),
      providesTags: [{ type: TAG.JOB_SITE }],
    }),

    getJobSites: builder.query<GetJobSitesReturns, GetJobSitesArgs | undefined>(
      {
        query: (body) => ({
          url: `/api/companies/:companyId/job_sites/filter`,
          method: Method.POST,
          body,
        }),
        providesTags: [{ type: TAG.JOB_SITE }],
      },
    ),

    getJobSiteOptions: builder.query<TransformedGetJobSiteOptionsReturns, void>(
      {
        query: () => ({
          url: "/api/companies/:companyId/job_sites/options",
          method: Method.GET,
        }),
        transformResponse: getTransformedJobSiteOptions,
      },
    ),

    deleteJobSite: builder.mutation<void, string>({
      query: (jobSiteId) => ({
        url: `/api/companies/:companyId/job_sites/${jobSiteId}`,
        method: Method.DELETE,
      }),
      invalidatesTags: [
        { type: TAG.BID },
        { type: TAG.CONTACT },
        { type: TAG.CUSTOMER },
        { type: TAG.JOB_SITE },
        { type: TAG.USER },
      ],
    }),

    /* 
      Attachments
    */
    getAttachments: builder.query<GetAttachmentsReturns, GetAttachmentsArgs>({
      query: (query) => ({
        url: `/api/attachments?object_type=${query.objectType}&object_id=${query.objectId}`,
        method: Method.GET,
      }),
      providesTags: [TAG.ATTACHMENT],
    }),

    generatePresignedUrl: builder.mutation<
      GeneratePresignedUrlReturns,
      GeneratePresignedUrlArgs
    >({
      query: (body) => ({
        url: `/api/attachments/presigned_url`,
        method: Method.POST,
        body,
      }),
    }),

    addAttachments: builder.mutation<void, AddAttachmentsArgs>({
      query: (body) => ({
        url: `/api/attachments/attach_files`,
        method: Method.POST,
        body,
      }),
      invalidatesTags: [
        { type: TAG.BID },
        { type: TAG.CONTACT },
        { type: TAG.CUSTOMER },
        { type: TAG.JOB_SITE },
        { type: TAG.USER },
        { type: TAG.PACKAGE },
        { type: TAG.BUNDLE },
        { type: TAG.ATTACHMENT },
      ],
    }),

    updateAttachment: builder.mutation<
      UpdateAttachmentReturns,
      UpdateAttachmentArgs
    >({
      query: (body) => ({
        url: `/api/attachments/${body.id}`,
        method: Method.PUT,
        body: body,
      }),
      invalidatesTags: [
        { type: TAG.BID },
        { type: TAG.CONTACT },
        { type: TAG.CUSTOMER },
        { type: TAG.JOB_SITE },
        { type: TAG.USER },
        { type: TAG.PACKAGE },
        { type: TAG.BUNDLE },
        { type: TAG.ATTACHMENT },
      ],
    }),

    deleteAttachment: builder.mutation<void, DeleteAttachmentArgs>({
      query: (body) => ({
        url: `/api/attachments/delete_file`,
        method: Method.PUT,
        body: body,
      }),
      invalidatesTags: [
        { type: TAG.BID },
        { type: TAG.CONTACT },
        { type: TAG.CUSTOMER },
        { type: TAG.JOB_SITE },
        { type: TAG.USER },
        { type: TAG.PACKAGE },
        { type: TAG.BUNDLE },
        { type: TAG.ATTACHMENT },
      ],
    }),

    /* 
      Notes
    */
    addNote: builder.mutation<string | undefined, AddNoteArgs>({
      query: ({ note }) => ({
        url: `/api/notes`,
        method: Method.POST,
        body: note,
      }),
      invalidatesTags: [
        { type: TAG.BID },
        { type: TAG.CONTACT },
        { type: TAG.CUSTOMER },
        { type: TAG.JOB_SITE },
        { type: TAG.USER },
      ],
    }),

    updateNote: builder.mutation<string | undefined, UpdateNoteArgs>({
      query: (note) => ({
        url: `/api/notes/${note.id}`,
        method: Method.PUT,
        body: note,
      }),
      invalidatesTags: [
        { type: TAG.BID },
        { type: TAG.CONTACT },
        { type: TAG.CUSTOMER },
        { type: TAG.JOB_SITE },
        { type: TAG.USER },
      ],
    }),

    deleteNote: builder.mutation<string | undefined, number>({
      query: (id) => ({
        url: `/api/notes/${id}`,
        method: Method.DELETE,
      }),
      invalidatesTags: [
        { type: TAG.BID },
        { type: TAG.CONTACT },
        { type: TAG.CUSTOMER },
        { type: TAG.JOB_SITE },
        { type: TAG.USER },
      ],
    }),

    /* 
      Dashboard
    */
    getDashboardSales: builder.query<GetDashboardReturns, GetDashboardArgs>({
      query: (dates) => ({
        url: `/api/reports/sales?${new URLSearchParams({
          start_date: dates.start_date,
          end_date: dates.end_date,
        })}`,
        method: Method.GET,
      }),
    }),

    /*
      List Views
    */
    getListViews: builder.query<GetListViewsReturns, void>({
      query: () => ({
        url: "/api/list_views",
        method: Method.GET,
      }),
      providesTags: [TAG.LIST_VIEW],
    }),

    createListView: builder.mutation<void, CreateListViewArgs>({
      query: (args) => ({
        url: "/api/list_views",
        method: Method.POST,
        body: args,
      }),
      invalidatesTags: [TAG.LIST_VIEW],
    }),

    updateListView: builder.mutation<void, UpdateListViewArgs>({
      query: (args) => ({
        url: `/api/list_views/${args.listViewId}`,
        method: Method.PUT,
        body: args.body,
      }),
      invalidatesTags: [TAG.LIST_VIEW],
    }),

    deleteListView: builder.mutation<void, string>({
      query: (listViewId) => ({
        url: `/api/list_views/${listViewId}`,
        method: Method.DELETE,
      }),
      invalidatesTags: [TAG.LIST_VIEW],
    }),

    /* 
    Cost types
    */
    getLineItemOptions: builder.query<
      GetCostTypesReturns,
      GetCostTypesArgs | undefined
    >({
      query: (args) => ({
        url: `/api/line_items/options`,
        method: Method.GET,
        params: {
          target_id: args?.id,
          target_type: args?.type,
        },
      }),
      providesTags: [TAG.COST_TYPES],
    }),

    /*
      Sessions
    */
    login: builder.mutation<string | undefined, LoginArgs>({
      query: (args) => ({
        url: "/login",
        method: Method.POST,
        body: { user: args },
      }),
      transformResponse: getAuthToken,
      onQueryStarted: (_args, { queryFulfilled }) => {
        setAuthToken(queryFulfilled);
      },
    }),

    logout: builder.mutation<void, void>({
      query: () => ({
        url: "/logout",
        method: Method.DELETE,
      }),
      onQueryStarted: () => {
        removeAuthToken();
      },
    }),

    signUp: builder.mutation<string | undefined, SignUpArgs>({
      query: (args) => ({
        url: "/signup",
        method: Method.POST,
        body: { user: { ...args } },
      }),
      transformResponse: getAuthToken,
      onQueryStarted: (_args, { queryFulfilled }) => {
        setAuthToken(queryFulfilled);
      },
    }),

    /*
      Users
    */
    getUsers: builder.query<GetUsersReturns, GetUsersArgs | undefined>({
      query: (body) => ({
        url: "/api/users/filter",
        method: Method.POST,
        body,
      }),
      onQueryStarted: async (_, { dispatch, queryFulfilled }) => {
        const { data } = await queryFulfilled;
        dispatch(resourceCacheSlice.actions.addUsers(data.collection.data));
      },
      providesTags: [{ type: TAG.USER }],
    }),

    getUser: builder.query<GetUserReturns, string>({
      query: (userId: string) => ({
        url: `/api/users/${userId}`,
        method: Method.GET,
      }),
      onQueryStarted: async (_, { dispatch, queryFulfilled }) => {
        const { data } = await queryFulfilled;
        dispatch(resourceCacheSlice.actions.addUsers([data.user.data]));
      },
      providesTags: (_returns, _error, userId) => [
        { type: TAG.USER, id: userId },
      ],
    }),

    updateUser: builder.mutation<void, UpdateUserArgs>({
      query: (payload) => ({
        url: `/api/users/${payload.id}`,
        method: Method.PUT,
        body: {
          user: payload.user,
        },
      }),
      invalidatesTags: (_returns, _error, payload) => [
        { type: TAG.USER, id: payload.id },
      ],
    }),
  }),
});

export const {
  useAttachAssemblyMutation,
  useCalculateEstimationMutation,
  useCloneItemsMutation,
  useCreateAssemblyMutation,
  useCreateLineItemMutation,
  useCreatePackageMutation,
  useDeleteAssemblyMutation,
  useDeleteBundleMutation,
  useDeleteLineItemsMutation,
  useDeletePackageMutation,
  useFavoriteAssemblyMutation,
  useGeneratePDFMutation,
  useGetAssemblyQuery,
  useGetBidAttachmentsQuery,
  useGetBundlesQuery,
  useGetCostTypeTotalsByGroupQuery,
  useGetCostTypeTotalsByTradeQuery,
  useGetLineItemOptionsQuery,
  useGetPackageCrewMixesQuery,
  useGetPackageTotalsQuery,
  useGetPackagesQuery,
  useGetProfitCalculationQuery,
  useGetUserQuery,
  useLazyGetAssembliesQuery,
  useLazyGetPackagesQuery,
  useMoveItemsMutation,
  useSortItemsMutation,
  useUnfavoriteAssemblyMutation,
  useUpdateAssemblyMutation,
  useUpdateEstimationMutation,
  useUpdateItemsMutation,
  useUpdatePackageMutation,
  useUpdateProfitCalculationMutation,
  useAllVersionsQuery,
  useLazyAllVersionsQuery,
  useCreateNewVersionMutation,
  useDeleteEstimationMutation,
  useMarkEstimationCurrentMutation,
} = api;
