import { EstimationResourceType } from "src/components/EstimationView/CrewMix/util/utils";
import { ResourceType } from "src/data/api/types/bulkTransaction";
import { ValuesUnion } from "src/types/valuesUnion";

export interface AttachmentParent {
  objectId: number | null;
  objectType: typeof EstimationResourceType.LineItem | ResourceType;
}

export const AttachmentsColumnId = {
  NAME: "NAME",
  TYPE: "TYPE",
  CREATED_AT: "CREATED_AT",
  CREATED_BY: "CREATED_BY",
  UPDATED_AT: "UPDATED_AT",
  UPDATED_BY: "UPDATED_BY",
  ACTIONS: "ACTIONS",
  ISRENAME: "ISRENAME",
  ISLOADING: "ISLOADING",
} as const;
export type AttachmentColumnId = ValuesUnion<typeof AttachmentsColumnId>;

export const ATTACHMENTS_LABEL_BY_ID = {
  [AttachmentsColumnId.NAME]: "Name",
  [AttachmentsColumnId.TYPE]: "Type",
  [AttachmentsColumnId.CREATED_AT]: "Uploaded at",
  [AttachmentsColumnId.CREATED_BY]: "Uploaded by",
  [AttachmentsColumnId.UPDATED_AT]: "Updated at",
  [AttachmentsColumnId.UPDATED_BY]: "Updated by",
  [AttachmentsColumnId.ACTIONS]: "Actions",
  [AttachmentsColumnId.ISRENAME]: "isRename",
  [AttachmentsColumnId.ISLOADING]: "isLoading",
};
