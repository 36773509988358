import { notifications } from "@mantine/notifications";
import { IconX } from "@tabler/icons-react";

type UUID = ReturnType<typeof crypto.randomUUID>;

export function showFailNotification(id?: UUID): void {
  const config = {
    color: "red",
    icon: <IconX />,
    loading: false,
    message: "Uh-oh! Something went wrong, please try again.",
  };

  if (id != null) {
    notifications.show(config);
  } else {
    notifications.update({
      ...config,
      id: id,
    });
  }
}
