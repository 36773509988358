import heic2any from "heic2any";

export async function convertHeicToJpg(file: File): Promise<File> {
  const blob = await heic2any({
    blob: file,
    toType: "image/jpeg",
  });

  return new File([blob as Blob], file.name.replace(/\.heic|\.heif/i, ".jpg"), {
    type: "image/jpeg",
  });
}
