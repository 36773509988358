import { useContext } from "react";
import { LoadedBid } from "src/data/api/types/shared/bid";
import { BidContext } from "./BidProvider";

export const useBidSelector = <T>(selector: (bid: LoadedBid) => T) => {
  const bid = useContext(BidContext);
  if (bid == null) {
    throw new Error("useBidSelector must be used within a BidProvider");
  }

  return selector(bid);
};
