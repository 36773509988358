import { Group, Pill, Stack, TextInput } from "@mantine/core";
import { useInputState } from "@mantine/hooks";
import classNames from "classnames";
import React from "react";
import { EMPTY_STRING } from "src/utils/empty";
import { useBuildCrewMixLabels } from "../CrewMix/hooks/useBuildCrewMixLabels";
import { DEFAULT_PLACEHOLDER, TEXT_INPUT_STYLES } from "./constants";
import { useCellKeystrokes } from "./hooks/useCellKeystrokes";
import styles from "./TextCell.module.scss";

interface Props {
  crewMixId: number | null;
  disabled?: boolean;
  handleUpdate: (value: string) => void;
  value?: string;
}

export const TextCell = React.memo<Props>(function _TextCell({
  crewMixId,
  disabled = false,
  handleUpdate,
  value,
}) {
  const inputRef = React.useRef<HTMLInputElement>(null);
  const [cellValue, setCellValue] = useInputState(value ?? EMPTY_STRING);
  const [isEdit, setIsEdit] = React.useState(false);
  const subDisplay = useBuildCrewMixLabels(crewMixId);

  const handleFocus = React.useCallback(() => {
    if (disabled === false) setIsEdit(true);
  }, [disabled]);

  const handleSubmit = React.useCallback(() => {
    setIsEdit(false);

    if (cellValue !== value) {
      handleUpdate(cellValue);
    }
  }, [cellValue, handleUpdate, value]);

  const handleEscape = React.useCallback(() => {
    setIsEdit(false);
    setCellValue(value);
  }, [setCellValue, value]);

  const { handleTextCellKeystrokes: handleKeyPresses } = useCellKeystrokes({
    ref: inputRef,
    onEscape: handleEscape,
    onSubmit: handleSubmit,
  });

  const handleMouseDown = React.useCallback((event: React.MouseEvent) => {
    if (event.button !== 0) {
      event.preventDefault();
    }
  }, []);

  React.useEffect(() => {
    if (isEdit) {
      inputRef.current?.focus();
    } else {
      inputRef.current?.blur();
    }
  }, [isEdit]);

  return (
    <Stack className={styles.root}>
      {!isEdit && cellValue !== EMPTY_STRING ? (
        <div className={styles.fieldRoot} onClick={handleFocus}>
          {cellValue}
        </div>
      ) : (
        <div
          className={classNames(
            styles.fieldRoot,
            isEdit ? styles.editMode : undefined,
          )}
        >
          <TextInput
            ref={inputRef}
            className={styles.textField}
            disabled={disabled}
            onBlur={handleSubmit}
            onChange={setCellValue}
            onFocus={handleFocus}
            onKeyDown={handleKeyPresses}
            onMouseDown={handleMouseDown}
            placeholder={isEdit ? EMPTY_STRING : DEFAULT_PLACEHOLDER}
            styles={TEXT_INPUT_STYLES}
            value={cellValue}
            variant={"unstyled"}
          />
        </div>
      )}
      {crewMixId != null && isEdit === false ? (
        <Group className={styles.labelWrapper}>
          {subDisplay.map((display) => (
            <Pill
              key={display}
              className={styles.label}
              styles={{
                root: {
                  "--pill-height": "1rem",
                },
              }}
            >
              {display}
            </Pill>
          ))}
        </Group>
      ) : null}
    </Stack>
  );
});
