import { TextInput } from "@mantine/core";
import { useDebouncedState, useDidUpdate } from "@mantine/hooks";
import { IconPercentage } from "@tabler/icons-react";
import { ChangeEvent, useCallback, useMemo, useState } from "react";
import { formatNumberHundredths } from "src/utils/formatNumberHundredths";
import { MarginInputType } from "../../constants";

interface Props {
  disabled: boolean;
  data: MarginInputType;
  initialValue?: number;
  limit: number;
  onChange: (key: string, value: number) => void;
}

const PercentageInput = ({
  data,
  initialValue,
  onChange,
  limit,
  disabled,
}: Props) => {
  const [value, setValue] = useDebouncedState("", 200);
  const [error, setError] = useState<string | undefined>(undefined);

  const formattedValue = useMemo(() => {
    if (initialValue) {
      return formatNumberHundredths(initialValue * 100);
    }
  }, [initialValue]);

  const handleChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      setValue(event.currentTarget.value);

      const currentValue =
        event.currentTarget.value != null
          ? Number(event.currentTarget.value)
          : 0;

      if (currentValue > limit) {
        setError(`Value can’t exceed ${limit}%`);
      } else {
        setError(undefined);
      }
    },
    [limit, setValue],
  );

  useDidUpdate(() => {
    if (error == null) {
      onChange(data.value, Number(value) / 100);
    }
  }, [value]);

  return (
    <TextInput
      defaultValue={formattedValue}
      disabled={disabled}
      error={error}
      label={data.label}
      onChange={handleChange}
      placeholder="0"
      rightSection={<IconPercentage size="18px" />}
      styles={{
        input: {
          textAlign: "right",
          width: "180px",
          paddingRight: "40px",
        },
        section: {
          background: "#F8F9FA",
          borderLeft: "1px solid #DEE2E6",
        },
      }}
    />
  );
};

export default PercentageInput;
