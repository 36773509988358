import {
  IconBuilding,
  IconFileDescription,
  IconLayoutGrid,
  IconMapPin,
  IconPhone,
  IconVideo,
} from "@tabler/icons-react";
import React from "react";
import { Paths } from "./paths";

interface NavBarConfig {
  readonly icon: React.ReactElement;
  readonly iconWhite: React.ReactElement;
  readonly name: string;
  readonly path?: string;
  readonly link?: string;
}

export const BIDS_CONFIG: NavBarConfig = {
  icon: <IconFileDescription />,
  iconWhite: <IconFileDescription color="white" />,
  name: "Bids",
  path: Paths.BIDS,
};

export const CUSTOMERS_CONFIG: NavBarConfig = {
  icon: <IconBuilding />,
  iconWhite: <IconBuilding color="white" />,
  name: "Customers",
  path: Paths.CUSTOMERS,
};

export const CONTACTS_CONFIG: NavBarConfig = {
  icon: <IconPhone />,
  iconWhite: <IconPhone color="white" />,
  name: "Contacts",
  path: Paths.CONTACTS,
};

export const JOB_SITES_CONFIG: NavBarConfig = {
  icon: <IconMapPin />,
  iconWhite: <IconMapPin color="white" />,
  name: "Job sites",
  path: Paths.JOB_SITES,
};

export const DASHBOARD_CONFIG: NavBarConfig = {
  icon: <IconLayoutGrid />,
  iconWhite: <IconLayoutGrid color="white" />,
  name: "Dashboard",
  path: Paths.DASHBOARD,
};

export const TUTORIAL_CONFIG: NavBarConfig = {
  icon: <IconVideo />,
  iconWhite: <IconVideo color="white" />,
  name: "Tutorials",
  link: "https://spiral-acoustic-e6b.notion.site/Grace-Tutorial-Videos-18fd89e5cefc8069877cd082b959a8c1",
};

export const NAV_BAR_CONFIGS = [
  BIDS_CONFIG,
  CUSTOMERS_CONFIG,
  CONTACTS_CONFIG,
  JOB_SITES_CONFIG,
  DASHBOARD_CONFIG,
  TUTORIAL_CONFIG,
] as const;
