import { Text } from "@mantine/core";
import classNames from "classnames";
import { DataTableColumnTextAlign } from "mantine-datatable";
import { useMemo } from "react";
import { PRICE_METHOD } from "src/components/EstimationView/Table/constants";
import { CostCell } from "src/components/Frames/Table/CostCell";
import { MarginCell } from "src/components/Frames/Table/MarginCell";
import { PercentOfCostCell } from "src/components/Frames/Table/PercentOfCostCell";
import { PackageTotal } from "src/data/api/types/getPackageTotals";
import { useQueryStringParams } from "src/hooks/useQueryStringParams";
import { capitalize } from "src/utils/capitalize";
import { formatCurrency } from "src/utils/formatCurrency";
import styles from "../CostTypeInnerColumns.module.scss";

export const useCostTypeInnerTableColumns = (records: PackageTotal[]) => {
  const [priceMethod] = useQueryStringParams(PRICE_METHOD);
  const WIDTH = "20%";
  return useMemo(() => {
    return [
      {
        width: WIDTH,
        accessor: "cost_type",
        title: "COST TYPE",
        render: (v: PackageTotal) => (
          <Text className={classNames(styles.content, styles.indent)}>
            {capitalize(v.name)}
          </Text>
        ),
        textAlign: "left" as DataTableColumnTextAlign,
      },
      {
        width: WIDTH,
        accessor: "percent_of_cost",
        title: "% OF COST",
        render: (v: PackageTotal) => (
          <PercentOfCostCell record={v} records={records} withTaxes={false} />
        ),
      },
      {
        width: WIDTH,
        accessor: "cost",
        title: "COST",
        render: (v: PackageTotal) => <CostCell record={v} withTaxes={false} />,
      },
      {
        width: WIDTH,
        accessor: "margin",
        title: "MARGIN",
        render: (v: PackageTotal) => (
          <MarginCell priceMethod={priceMethod} record={v} />
        ),
      },
      {
        width: WIDTH,
        accessor: "sell_price",
        title: "SELL PRICE",
        render: (v: PackageTotal) => (
          <Text className={styles.content}>
            {formatCurrency(Number(v.grand_total))}
          </Text>
        ),
      },
    ];
  }, [priceMethod, records]);
};
