import {
  ColDef,
  GridOptions,
  RowSelectedEvent,
  ValueGetterParams,
} from "ag-grid-community";
import { AgGridReact } from "ag-grid-react";
import classNames from "classnames";
import React from "react";
import { NO_OP_COMPARATOR } from "src/constants/noOpComparator";
import { Attachment } from "src/data/api/types/shared/attachment";
import { LoadingScreenType } from "src/types/loadingScreenType";
import { WithClassname } from "src/types/withClassName";
import { EMPTY_ARRAY } from "src/utils/empty";
import { formatDateTime } from "src/utils/formatDateTime";
import { LoadingScreen } from "../LoadingScreen";
import { ActionsCell } from "./ActionsCell";
import styles from "./AttachmentsTable.module.scss";
import { AttachmentType } from "./AttachmentType";
import { StateAttachment } from "./constants";
import { FilenameCell } from "./FilenameCell";
import { ATTACHMENTS_LABEL_BY_ID, AttachmentsColumnId } from "./utils";

const GRID_OPTIONS: GridOptions = {
  rowSelection: "single",
  suppressCellFocus: true,
  autoSizeStrategy: {
    type: "fitGridWidth",
  },
};

interface Props extends WithClassname {
  attachments: Array<Attachment> | undefined;
  onRowSelect: (arg: Attachment) => void;
}

export const AttachmentsTable = React.memo<Props>(function _AttachmentsTable({
  attachments,
  className,
  onRowSelect,
}) {
  const agGridRef = React.useRef<AgGridReact<StateAttachment>>(null);
  const scrollPositionRef = React.useRef<number>(0);

  /*
    Restore scroll position after row data updates
  */
  React.useEffect(() => {
    if (
      agGridRef.current?.api != null &&
      scrollPositionRef.current != null &&
      agGridRef.current.api.getDisplayedRowCount() > 0 // Only scroll if rows present
    ) {
      agGridRef.current.api.ensureIndexVisible(
        scrollPositionRef.current,
        "top",
      );
    }
  }, [attachments]);

  const saveScrollPosition = React.useCallback(() => {
    const gridElement = document.querySelector(".ag-body-viewport");
    if (gridElement == null || agGridRef.current == null) return 0;
    // Estimate the first fully visible row: distance scrolled / rowHeight
    // eslint-disable-next-line immutable/no-mutation
    scrollPositionRef.current = Math.floor(
      gridElement.scrollTop /
        agGridRef.current.api.getSizesForCurrentTheme().rowHeight,
    );
  }, []);

  const columnDefs = React.useMemo(
    (): Array<ColDef<StateAttachment>> => [
      {
        colId: AttachmentsColumnId.NAME,
        comparator: NO_OP_COMPARATOR,
        headerName: ATTACHMENTS_LABEL_BY_ID[AttachmentsColumnId.NAME],
        cellRenderer: (v: ValueGetterParams<StateAttachment>) => {
          if (v.data == null) return;
          return <FilenameCell attachment={v.data} cellData={v} />;
        },
        minWidth: 100,
      },
      {
        colId: AttachmentsColumnId.TYPE,
        comparator: NO_OP_COMPARATOR,
        headerName: ATTACHMENTS_LABEL_BY_ID[AttachmentsColumnId.TYPE],
        cellRenderer: (v: ValueGetterParams<StateAttachment>) => {
          return <AttachmentType cellData={v} onUpdate={saveScrollPosition} />;
        },
        minWidth: 100,
      },
      {
        colId: AttachmentsColumnId.CREATED_AT,
        comparator: NO_OP_COMPARATOR,
        headerName: ATTACHMENTS_LABEL_BY_ID[AttachmentsColumnId.CREATED_AT],
        valueGetter: (v) => formatDateTime(v.data?.created_at),
        minWidth: 100,
      },
      {
        colId: AttachmentsColumnId.CREATED_BY,
        comparator: NO_OP_COMPARATOR,
        headerName: ATTACHMENTS_LABEL_BY_ID[AttachmentsColumnId.CREATED_BY],
        /* cellRenderer: (v: ValueGetterParams<Attachment>) => {
          return <UserCell userId={v.data?.meta.created_by?.id.toString()} />;
          }, */
        minWidth: 100,
        hide: true,
      },
      {
        colId: AttachmentsColumnId.UPDATED_AT,
        comparator: NO_OP_COMPARATOR,
        headerName: ATTACHMENTS_LABEL_BY_ID[AttachmentsColumnId.UPDATED_AT],
        valueGetter: (v) => formatDateTime(v.data?.updated_at),
        minWidth: 100,
      },
      {
        colId: AttachmentsColumnId.UPDATED_BY,
        comparator: NO_OP_COMPARATOR,
        headerName: ATTACHMENTS_LABEL_BY_ID[AttachmentsColumnId.UPDATED_BY],
        /* cellRenderer: (v: ValueGetterParams<Attachment>) => {
            return <UserCell userId={v.data?.meta.created_by?.id.toString()} />;
            }, */
        minWidth: 100,
        hide: true,
      },
      {
        colId: AttachmentsColumnId.ACTIONS,
        comparator: NO_OP_COMPARATOR,
        headerName: ATTACHMENTS_LABEL_BY_ID[AttachmentsColumnId.ACTIONS],
        cellRenderer: (v: ValueGetterParams<StateAttachment>) => {
          if (v.data == null) return;
          return (
            <ActionsCell
              attachment={v.data}
              cellData={v}
              onUpdate={saveScrollPosition}
            />
          );
        },
        cellClass: styles.actions,
        minWidth: 100,
      },
      {
        colId: AttachmentsColumnId.ISRENAME,
        comparator: NO_OP_COMPARATOR,
        field: "isRename",
        headerName: ATTACHMENTS_LABEL_BY_ID[AttachmentsColumnId.ISRENAME],
        valueGetter: (v) => v.data?.isRename,
        hide: true,
      },
      {
        colId: AttachmentsColumnId.ISLOADING,
        comparator: NO_OP_COMPARATOR,
        field: "isLoading",
        headerName: ATTACHMENTS_LABEL_BY_ID[AttachmentsColumnId.ISLOADING],
        valueGetter: (v) => v.data?.isLoading,
        hide: true,
      },
    ],
    [saveScrollPosition],
  );

  const handleRowClick = React.useCallback(
    (row: RowSelectedEvent<StateAttachment>) => {
      if (row.data == null) return;
      if (row.event?.defaultPrevented) return; // Stops preview if any actions clicked
      if (row.data.isLoading) return; // Disallow preview if deleting row
      onRowSelect(row.data);
    },
    [onRowSelect],
  );

  const stateAttachments = React.useMemo(() => {
    return [...(attachments ?? EMPTY_ARRAY)]
      .sort((a, b) => a.filename.localeCompare(b.filename))
      .map((attachment) => ({
        ...attachment,
        isRename: false,
        isLoading: false,
      }));
  }, [attachments]);

  if (attachments == null) {
    return (
      <div className={className}>
        <LoadingScreen loadingScreenType={LoadingScreenType.Table} />
      </div>
    );
  }

  return (
    <div className={classNames("ag-theme-quartz", styles.table, className)}>
      <AgGridReact<StateAttachment>
        ref={agGridRef}
        columnDefs={columnDefs}
        defaultColDef={{ sortable: false }}
        gridOptions={GRID_OPTIONS}
        onRowClicked={handleRowClick}
        rowData={stateAttachments}
        suppressMovableColumns={true}
      />
    </div>
  );
});
