import { NumberInput, NumberInputProps } from "@mantine/core";
import { useInputState } from "@mantine/hooks";
import classNames from "classnames";
import React from "react";
import { formatNumber } from "src/utils/formatNumber";
import { formatNumericValue } from "src/utils/formatNumericValue";
import { DEFAULT_PLACEHOLDER } from "../Table/constants";
import { useCellKeystrokes } from "../Table/hooks/useCellKeystrokes";
import { UNITS } from "./constants";
import styles from "./CrewMixCell.module.scss";
import { ClassificationEntry } from "./hooks/useCrewMixData";

const CREW_MIX_CELL_INPUT_STYLES: NumberInputProps["styles"] = {
  wrapper: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-end",
    gap: "4px",
  },
  input: {
    height: "min-content",
    minHeight: "min-content",
    padding: "0",
    lineHeight: "1.5",
    textAlign: "right",
    fontSize: "var(--mantine-font-size-xs)",
  },
  section: {
    position: "relative",
    top: "0",
    width: "max-content",
    fontSize: "11px",
  },
};

interface Props {
  readonly data?: ClassificationEntry;
  readonly disabled?: boolean;
  readonly isByPercentMethod: boolean;
  readonly onSubmit: (value: number) => void;
}

export const CrewMixCell = React.memo<Props>(function _CrewMixCell({
  data,
  disabled = false,
  isByPercentMethod,
  onSubmit,
}) {
  const inputRef = React.useRef<HTMLInputElement>(null);
  const [isEdit, setIsEdit] = React.useState(false);
  const [cellValue, setCellValue] = useInputState<number | string>(
    data != null
      ? isByPercentMethod
        ? formatNumber(data.crewPercentage * 100, 0, 2) ?? 0
        : data.crewHeads
      : 0,
  );

  React.useEffect(() => {
    if (data != null && !isEdit) {
      setCellValue(
        isByPercentMethod
          ? formatNumber(data.crewPercentage * 100, 0, 2) ?? 0
          : data.crewHeads,
      );
    }
  }, [data, isByPercentMethod, isEdit, setCellValue]);

  const handleFocus = React.useCallback(() => {
    setIsEdit(true);
  }, []);

  const handleSubmit = React.useCallback(() => {
    setIsEdit(false);
    const numberValue = typeof cellValue === "string" ? 0 : cellValue;
    if (
      (isByPercentMethod &&
        formatNumber(numberValue / 100, 0, 2) !== data?.crewPercentage) ||
      (!isByPercentMethod && numberValue !== data?.crewHeads)
    ) {
      onSubmit(numberValue);
    }
  }, [cellValue, data, isByPercentMethod, onSubmit]);

  const { handleEnterBlur: handleKeyPresses } = useCellKeystrokes({
    ref: inputRef,
  });

  const unit = React.useMemo(() => {
    if (isByPercentMethod) {
      return Math.abs(data?.allottedHours ?? 0) === 1
        ? UNITS.hour
        : UNITS.hours;
    } else {
      return cellValue === 1 ? ` ${UNITS.person}` : ` ${UNITS.people}`;
    }
  }, [cellValue, data, isByPercentMethod]);

  const rightSection = React.useMemo(() => {
    return isByPercentMethod && data?.allottedHours != null
      ? `Total ${formatNumericValue(data.allottedHours, 0, 0)} ${unit}`
      : undefined;
  }, [data, isByPercentMethod, unit]);

  return (
    <div className={classNames(styles.root, isEdit ? styles.focus : undefined)}>
      {/* TODO: add focus styles to outer div */}
      <NumberInput
        ref={inputRef}
        allowDecimal={isByPercentMethod}
        allowLeadingZeros={false}
        allowNegative={false}
        className={styles.inputWrapper}
        decimalScale={isByPercentMethod ? 2 : undefined}
        disabled={disabled}
        hideControls={true}
        onBlur={handleSubmit}
        onChange={setCellValue}
        onFocus={handleFocus}
        onKeyDown={handleKeyPresses}
        placeholder={DEFAULT_PLACEHOLDER}
        rightSection={rightSection}
        styles={CREW_MIX_CELL_INPUT_STYLES}
        suffix={isByPercentMethod ? " %" : unit}
        thousandSeparator=","
        value={cellValue}
        variant="unstyled"
        withKeyboardEvents={false}
      />
    </div>
  );
});
