import { Box, Button, Group, Text } from "@mantine/core";
import React from "react";
import { useMarkEstimationCurrentMutation } from "src/data/api/api";
import { Estimation } from "src/data/api/types/shared/bid";

interface Props {
  estimation: Estimation;
}

const NotCurrentEstimationBanner = ({ estimation }: Props) => {
  const [markEstimationCurrent, { isLoading: isLoadingMarkCurrent }] =
    useMarkEstimationCurrentMutation();

  const title = React.useMemo(() => {
    if (estimation.attributes.title != null) {
      return `Viewing version ${estimation.attributes.title}:`;
    }

    return `Viewing version ${estimation?.attributes.version_number}:`;
  }, [estimation.attributes.title, estimation.attributes.version_number]);

  const handleCurrent = React.useCallback(() => {
    markEstimationCurrent(estimation.id);
  }, [estimation.id, markEstimationCurrent]);

  return (
    <Box bg={"#00A69C"} c="white" mb="8px" p={"6px 16px 6px 4px"}>
      <Group gap="0">
        <Group gap="xs" ml="auto">
          <Text fw="bolder">{title}</Text>
          <Text>To edit the version, set it to current</Text>
        </Group>
        <Group gap="xs" ml="auto">
          <Button
            loading={isLoadingMarkCurrent}
            onClick={handleCurrent}
            variant="white"
          >
            Mark current
          </Button>
          {/* <ActionIcon c="white" variant="transparent">
            <IconX />
          </ActionIcon> */}
        </Group>
      </Group>
    </Box>
  );
};

export default NotCurrentEstimationBanner;
