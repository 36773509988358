import { useDisclosure } from "@mantine/hooks";
import { skipToken } from "@reduxjs/toolkit/query";
import React from "react";
import { AttachmentsTable } from "src/components/Frames/Attachments/AttachmentsTable";
import { AttachmentParent } from "src/components/Frames/Attachments/utils";
import { api } from "src/data/api/api";
import { Attachment } from "src/data/api/types/shared/attachment";
import { WithClassname } from "src/types/withClassName";
import { AttachmentViewer } from "../AttachmentViewer";

interface Props extends AttachmentParent, WithClassname {
  subAttachments?: Array<Attachment> | undefined;
}

export const ManageAttachmentsContent = React.memo<Props>(
  function _ManageAttachmentsContent({
    className,
    objectId,
    objectType,
    subAttachments,
  }) {
    const [selectedAttachment, setSelectedAttachment] =
      React.useState<Attachment | null>(null);

    const [isPreviewOpen, { open: openPreview, close: closePreview }] =
      useDisclosure(false);

    const { currentData: ownAttachments } =
      api.endpoints.getAttachments.useQuery(
        objectId != null
          ? {
              objectId,
              objectType,
            }
          : skipToken,
      );

    const attachments = React.useMemo(() => {
      if (subAttachments == null) {
        return ownAttachments; // nullish renders loading screen in child component
      } else {
        return ownAttachments == null
          ? [...subAttachments]
          : [...subAttachments, ...ownAttachments];
      }
    }, [ownAttachments, subAttachments]);

    const handlePreviewAttachment = React.useCallback(
      (attachment: Attachment) => {
        setSelectedAttachment(attachment);
        openPreview();
      },
      [openPreview],
    );

    return (
      <>
        <AttachmentsTable
          attachments={attachments}
          className={className}
          onRowSelect={handlePreviewAttachment}
        />
        {isPreviewOpen && selectedAttachment != null ? (
          <AttachmentViewer
            attachment={selectedAttachment}
            onClose={closePreview}
          />
        ) : null}
      </>
    );
  },
);
