import { Anchor, Group, Stack, Tabs } from "@mantine/core";
import React from "react";
import { useNavigate } from "react-router-dom";
import { PageHeader } from "src/components/Frames/PageHeader";
import { ShareButton } from "src/components/Frames/ShareButton";
import { Paths } from "src/constants/paths";
import { useBidSelector } from "src/context/BidProvider";
import { api } from "src/data/api/api";
import { Attachments } from "../Frames/Attachments/Attachments";
import { DeleteButton } from "../Frames/DeleteButton";
import styles from "./BidProfileView.module.scss";
import { Overview } from "./Overview/Overview";

const TABS = ["Overview", "Attachments"] as const;

export const BidProfileView = React.memo(function _BidProfileView() {
  const bid = useBidSelector((theBid) => theBid);
  const navigate = useNavigate();
  const [activeTab, setActiveTab] = React.useState<string | null>(TABS[0]);
  const [deleteBid] = api.endpoints.deleteBid.useMutation();

  const bidId = bid.id;

  const handleBidsClick = React.useCallback(() => {
    navigate(Paths.SLASH + Paths.BIDS);
  }, [navigate]);

  const handleBidDelete = React.useCallback(async () => {
    if (bidId == null) {
      return;
    }

    await deleteBid(bidId);
    handleBidsClick();
  }, [bidId, deleteBid, handleBidsClick]);

  return (
    <Stack className={styles.stack}>
      <PageHeader
        rightSection={
          <Group>
            <ShareButton />
            <DeleteButton onDelete={handleBidDelete} />
          </Group>
        }
        subtitle={
          <span className={styles.subtitle}>
            <Anchor
              className={styles.path}
              component="button"
              inline={true}
              onClick={handleBidsClick}
            >
              Bids
            </Anchor>
            <span className={styles.slash}>/</span>
            <span className={styles.path}>{bid.attributes.name}</span>
          </span>
        }
        title={bid.attributes.name}
      />

      <Group className={styles.group}>
        <Tabs
          className={styles.tabs}
          defaultValue={TABS[0]}
          onChange={(value) => setActiveTab(value)}
        >
          <Tabs.List p="0 20px">
            {TABS.map((tab) => (
              <Tabs.Tab key={tab} value={tab}>
                {tab}
              </Tabs.Tab>
            ))}
          </Tabs.List>

          <Tabs.Panel className={styles.panel} value={TABS[0]}>
            <Overview bid={bid} />
          </Tabs.Panel>

          <Tabs.Panel className={styles.panel} value={TABS[1]}>
            {activeTab === TABS[1] ? <Attachments bidId={bidId} /> : null}
          </Tabs.Panel>
        </Tabs>
      </Group>
    </Stack>
  );
});
