import { ValuesUnion } from "src/types/valuesUnion";

export const SALES_LEAD_ROLE = "sales_lead";
export const ACCOUNT_MANAGER_ROLE = "account_manager";

export const ResourceType = {
  Bid: "Bid",
  Customer: "Customer",
  Contact: "Contact",
  Estimation: "Estimation",
  JobSite: "JobSite",
} as const;
export type ResourceType = ValuesUnion<typeof ResourceType>;

export const AssociationType = {
  ContactRole: "ContactRole",
  CustomerBid: "CustomerBid",
  CustomerJobSite: "CustomerJobSite",
  Role: "Role",
} as const;
export type AssociationType = ValuesUnion<typeof AssociationType>;

export const ActionType = {
  Create: "create",
  Update: "update",
} as const;
export type ActionType = ValuesUnion<typeof ActionType>;

interface ContactRoleAssociation {
  readonly contact_id: string;
  readonly id?: number;
  readonly metadata?: {
    readonly customer_id?: string | null;
    readonly departed_at?: string | null;
    readonly name?: string | null; // Role name
  };
  readonly resource_type: ResourceType;
  readonly resource_id: string;
  readonly type: typeof AssociationType.ContactRole;
}

interface RoleAssociation {
  readonly type: typeof AssociationType.Role;
  readonly id?: number;
  readonly user_id: string;
  readonly resource_type: ResourceType;
  readonly resource_id: string;
  readonly metadata?: {
    readonly name?: string | null; // Role name
  };
}

interface CustomerBidAssociation {
  readonly type: typeof AssociationType.CustomerBid;
  readonly id?: number;
  readonly customer_id: string;
  readonly bid_id: string;
  readonly metadata?: {
    readonly due_at?: string | null;
    readonly job_walk_at?: string | null;
  };
}

interface CustomerJobSiteAssociation {
  readonly type: typeof AssociationType.CustomerJobSite;
  readonly id?: number;
  readonly customer_id: string;
  readonly job_site_id: string;
}

export type Association =
  | ContactRoleAssociation
  | RoleAssociation
  | CustomerBidAssociation
  | CustomerJobSiteAssociation;

export interface Deletion {
  readonly resource_id: number;
  readonly resource_type: ResourceType | AssociationType;
}

export interface ContactAttributes {
  readonly id?: number;
  readonly uuid?: string;
  readonly company_id: string;
  readonly email: string;
  readonly first_name: string;
  readonly last_name: string;
  readonly phone: string;
}

export interface CustomerAttributes {
  readonly id?: number;
  readonly uuid?: string;
  readonly company_id: string;
  readonly industry_id: string;
  readonly name: string;
  readonly vista_customer_id: string;
}

export interface EstimationAttributes {
  readonly id?: number;
  readonly uuid?: string;
  readonly job_types: ReadonlyArray<string>;
  readonly status: string;
  readonly total?: number;
  readonly calculated_by?: string;
}

export interface BidAttributes {
  readonly address_details: string;
  readonly id?: number;
  readonly uuid?: string;
  readonly company_id: string;
  readonly bid_stage: string;
  readonly branch_id: string;
  readonly construction_type: string;
  readonly difficulty: string;
  readonly name: string;
  readonly priority: string;
  readonly relationship: string;
  readonly estimations_attributes?: ReadonlyArray<EstimationAttributes>;
}

export interface JobSiteAttributes {
  readonly id?: number;
  readonly uuid?: string;
  readonly build_class: string;
  readonly company_id: string;
  readonly address_attributes: Partial<{
    readonly id?: number;
    readonly full_address: string;
    readonly google_id: string;
  }>;
  readonly bids_attributes: ReadonlyArray<Partial<BidAttributes>>;
}

export interface BulkTransactionArgs {
  readonly bulk_transaction?: {
    readonly associations?: ReadonlyArray<Association>;
    readonly contacts_attributes?: ReadonlyArray<Partial<ContactAttributes>>;
    readonly customers_attributes?: ReadonlyArray<Partial<CustomerAttributes>>;
    readonly deletions?: ReadonlyArray<Deletion>;
    readonly job_sites_attributes?: ReadonlyArray<Partial<JobSiteAttributes>>;
  };
}

export interface BulkTransactionReturns {
  readonly bulk_transaction?: {
    readonly data?: {
      readonly attributes?: {
        readonly updated_resources: ReadonlyArray<Resource>;
      };
    };
  };
}

export interface BulkTransactionError {
  readonly data?: {
    readonly messages?: ReadonlyArray<ReadonlyArray<string>>;
  };
  readonly error?: {
    readonly error?: string;
    readonly status?: string;
  };
}

interface ResourceAttributes {
  readonly action: ActionType;
  readonly errors: ReadonlyArray<string>;
  readonly id: number;
  readonly success: boolean;
  readonly uuid: string | null;
}

export interface EntityResource extends ResourceAttributes {
  readonly type: Exclude<ResourceType, typeof ResourceType.Estimation>;
}

interface AssociationResource extends ResourceAttributes {
  readonly type: AssociationType;
}
export type Resource = EntityResource | AssociationResource;
