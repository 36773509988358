import { ColDef, ValueGetterParams } from "ag-grid-community";
import React from "react";
import { CustomerChip } from "src/components/Frames/EntityChip/CustomerChip";
import { JobSiteChip } from "src/components/Frames/EntityChip/JobSiteChip";
import { CellHoverCard } from "src/components/Frames/Table/CellHoverCard";
import { ContactsView } from "src/components/Frames/Table/ContactsView";
import { JobSiteView } from "src/components/Frames/Table/JobSiteView";
import { SalesLeadView } from "src/components/Frames/Table/SalesLeadView";
import { StatusView } from "src/components/Frames/Table/StatusView";
import { TotalSellAmountView } from "src/components/Frames/Table/TotalSellAmountView";
import { UserCell } from "src/components/Frames/Table/UserCell";
import { NO_OP_COMPARATOR } from "src/constants/noOpComparator";
import { api } from "src/data/api/api";
import { ResourceType } from "src/data/api/types/bulkTransaction";
import { LoadedBid } from "src/data/api/types/shared/bid";
import { ById } from "src/types/byId";
import { EMPTY_ARRAY } from "src/utils/empty";
import { formatDateTime } from "src/utils/formatDateTime";
import { BidColumnId } from "../../constants";
import styles from "../BidTable.module.scss";
import { BID_LABEL_BY_ID } from "../utils";

export function useBidColumnDefById(): ById<ColDef<LoadedBid>> {
  const bidOptions = api.endpoints.getBidOptions.useQuery();

  return React.useMemo((): ById<ColDef<LoadedBid>> => {
    return {
      [BidColumnId.BID_NUMBER]: {
        colId: BidColumnId.BID_NUMBER,
        comparator: NO_OP_COMPARATOR,
        headerName: BID_LABEL_BY_ID[BidColumnId.BID_NUMBER],
        valueGetter: (v) => v.data?.id,
      },
      [BidColumnId.NAME]: {
        colId: BidColumnId.NAME,
        comparator: NO_OP_COMPARATOR,
        headerName: BID_LABEL_BY_ID[BidColumnId.NAME],
        valueGetter: (v) => v.data?.attributes.name,
      },
      [BidColumnId.JOB_SITE_ADDRESS]: {
        colId: BidColumnId.JOB_SITE_ADDRESS,
        comparator: NO_OP_COMPARATOR,
        headerName: BID_LABEL_BY_ID[BidColumnId.JOB_SITE_ADDRESS],
        cellRenderer: (v: ValueGetterParams<LoadedBid>) =>
          v.data?.attributes.job_site == null ? undefined : (
            <JobSiteChip id={v.data.attributes.job_site.id.toString()} />
          ),
      },
      [BidColumnId.JOB_SITE_ADDRESS_DETAILS]: {
        colId: BidColumnId.JOB_SITE_ADDRESS_DETAILS,
        comparator: NO_OP_COMPARATOR,
        headerName: BID_LABEL_BY_ID[BidColumnId.JOB_SITE_ADDRESS_DETAILS],
        valueGetter: (v) => v.data?.attributes.address_details,
      },
      [BidColumnId.DUE_DATE]: {
        colId: BidColumnId.DUE_DATE,
        comparator: NO_OP_COMPARATOR,
        headerName: BID_LABEL_BY_ID[BidColumnId.DUE_DATE],
        cellRenderer: (v: ValueGetterParams<LoadedBid>) => {
          const dueDateCount = v.data?.attributes.customers.filter(
            (customer) => customer.due_at != null,
          ).length;
          return (
            v.data != null && (
              <CellHoverCard
                bid={v.data}
                cellContent={formatDateTime(
                  v.data?.attributes.customers[0]?.due_at,
                )}
                pillCount={
                  (dueDateCount ?? 0) -
                  (v.data?.attributes.customers[0]?.due_at == null ? 0 : 1)
                }
                resourceType={ResourceType.Customer}
              />
            )
          );
        },
        cellClass: styles.pillCell,
      },
      [BidColumnId.JOB_WALK_DATE]: {
        colId: BidColumnId.JOB_WALK_DATE,
        comparator: NO_OP_COMPARATOR,
        headerName: BID_LABEL_BY_ID[BidColumnId.JOB_WALK_DATE],
        cellRenderer: (v: ValueGetterParams<LoadedBid>) => {
          const walkDateCount = v.data?.attributes.customers.filter(
            (customer) => customer.job_walk_at != null,
          ).length;
          return (
            v.data != null && (
              <CellHoverCard
                bid={v.data}
                cellContent={formatDateTime(
                  v.data.attributes.customers[0]?.job_walk_at,
                )}
                pillCount={
                  (walkDateCount ?? 0) -
                  (v.data?.attributes.customers[0]?.job_walk_at == null ? 0 : 1)
                }
                resourceType={ResourceType.Customer}
              />
            )
          );
        },
        cellClass: styles.pillCell,
      },
      [BidColumnId.BID_STAGE]: {
        colId: BidColumnId.BID_STAGE,
        comparator: NO_OP_COMPARATOR,
        headerName: BID_LABEL_BY_ID[BidColumnId.BID_STAGE],
        valueGetter: (v) =>
          bidOptions.currentData?.bidStage.find(
            (option) => option.value === v.data?.attributes.bid_stage,
          )?.label,
      },
      [BidColumnId.PRIORITY]: {
        colId: BidColumnId.PRIORITY,
        comparator: NO_OP_COMPARATOR,
        headerName: BID_LABEL_BY_ID[BidColumnId.PRIORITY],
        valueGetter: (v) =>
          bidOptions.currentData?.priority.find(
            (option) => option.value === v.data?.attributes.priority,
          )?.label,
      },
      [BidColumnId.STATUS]: {
        colId: BidColumnId.STATUS,
        comparator: NO_OP_COMPARATOR,
        headerName: BID_LABEL_BY_ID[BidColumnId.STATUS],
        cellRenderer: (v: ValueGetterParams<LoadedBid>) => {
          if (v.data == null) {
            return null;
          }

          return (
            <StatusView
              bid={v.data}
              statuses={bidOptions.currentData?.status ?? EMPTY_ARRAY}
            />
          );
        },
        cellClass: styles.pillCell,
      },
      [BidColumnId.TOTAL_SELL_AMOUNT]: {
        colId: BidColumnId.TOTAL_SELL_AMOUNT,
        comparator: NO_OP_COMPARATOR,
        headerName: BID_LABEL_BY_ID[BidColumnId.TOTAL_SELL_AMOUNT],
        cellRenderer: (v: ValueGetterParams<LoadedBid>) => {
          if (v.data == null) {
            return null;
          }

          return <TotalSellAmountView bid={v.data} />;
        },
        cellClass: styles.pillCell,
      },
      [BidColumnId.CUSTOMERS]: {
        colId: BidColumnId.CUSTOMERS,
        comparator: NO_OP_COMPARATOR,
        headerName: BID_LABEL_BY_ID[BidColumnId.CUSTOMERS],
        cellRenderer: (v: ValueGetterParams<LoadedBid>) => {
          return (
            v.data != null && (
              <CellHoverCard
                bid={v.data}
                cellContent={
                  v.data.attributes.customers[0]?.id != null ? (
                    <CustomerChip
                      id={v.data.attributes.customers[0].id.toString()}
                    />
                  ) : undefined
                }
                pillCount={v.data?.attributes.customers.length - 1}
                resourceType={ResourceType.Customer}
              />
            )
          );
        },
        cellClass: styles.pillCell,
      },
      [BidColumnId.CONTACTS]: {
        colId: BidColumnId.CONTACTS,
        comparator: NO_OP_COMPARATOR,
        headerName: BID_LABEL_BY_ID[BidColumnId.CONTACTS],
        cellRenderer: (v: ValueGetterParams<LoadedBid>) => {
          if (v.data == null) {
            return null;
          }

          return <ContactsView bid={v.data} />;
        },
        cellClass: styles.pillCell,
      },
      [BidColumnId.SALES_LEAD]: {
        colId: BidColumnId.SALES_LEAD,
        comparator: NO_OP_COMPARATOR,
        headerName: BID_LABEL_BY_ID[BidColumnId.SALES_LEAD],
        cellRenderer: (v: ValueGetterParams<LoadedBid>) => {
          if (v.data == null) {
            return null;
          }

          return <SalesLeadView bid={v.data} />;
        },
        cellClass: styles.pillCell,
      },
      [BidColumnId.JOB_TYPES]: {
        colId: BidColumnId.JOB_TYPES,
        comparator: NO_OP_COMPARATOR,
        headerName: BID_LABEL_BY_ID[BidColumnId.JOB_TYPES],
        cellRenderer: (v: ValueGetterParams<LoadedBid>) => {
          if (v.data == null) {
            return null;
          }

          return (
            <JobSiteView
              bid={v.data}
              jobTypes={bidOptions.currentData?.jobTypes ?? EMPTY_ARRAY}
            />
          );
        },
        cellClass: styles.pillCell,
      },
      [BidColumnId.CREATED_AT]: {
        colId: BidColumnId.CREATED_AT,
        comparator: NO_OP_COMPARATOR,
        headerName: BID_LABEL_BY_ID[BidColumnId.CREATED_AT],
        valueGetter: (v) => formatDateTime(v.data?.meta.created_at),
      },
      [BidColumnId.CREATED_BY]: {
        colId: BidColumnId.CREATED_BY,
        comparator: NO_OP_COMPARATOR,
        headerName: BID_LABEL_BY_ID[BidColumnId.CREATED_BY],
        cellRenderer: (v: ValueGetterParams<LoadedBid>) => {
          return <UserCell userId={v.data?.meta.created_by?.id.toString()} />;
        },
      },
      [BidColumnId.UPDATED_AT]: {
        colId: BidColumnId.UPDATED_AT,
        comparator: NO_OP_COMPARATOR,
        headerName: BID_LABEL_BY_ID[BidColumnId.UPDATED_AT],
        valueGetter: (v) => formatDateTime(v.data?.meta.updated_at),
      },
      [BidColumnId.UPDATED_BY]: {
        colId: BidColumnId.UPDATED_BY,
        comparator: NO_OP_COMPARATOR,
        headerName: BID_LABEL_BY_ID[BidColumnId.UPDATED_BY],
        cellRenderer: (v: ValueGetterParams<LoadedBid>) => {
          return <UserCell userId={v.data?.meta.updated_by?.id.toString()} />;
        },
      },
    };
  }, [
    bidOptions.currentData?.bidStage,
    bidOptions.currentData?.jobTypes,
    bidOptions.currentData?.priority,
    bidOptions.currentData?.status,
  ]);
}
