import { Flex } from "@mantine/core";
import { modals } from "@mantine/modals";
import classNames from "classnames";
import React from "react";
import { BundleLineItem } from "src/data/api/types/getBundles";
import { LineItem } from "src/data/api/types/updateBundleArgs";
import { EMPTY_STRING } from "src/utils/empty";
import { isTruthy } from "src/utils/isTruthy";
import { useLineItemCrewMix } from "../CrewMix/hooks/useLineItemCrewMix";
import { DROPDOWN_PLACEHOLDER, DropdownType } from "./constants";
import styles from "./CostTypeCell.module.scss";
import { DropdownCell } from "./DropdownCell";
import { useCostTypesOptions } from "./hooks/useCostTypesOptions";

interface Props {
  isEditable?: boolean;
  record: BundleLineItem;
  handleUpdate: (bundleId: number, lineItem: LineItem) => void;
}

export const CostTypeCell = React.memo<Props>(function _CostTypeCell({
  record,
  handleUpdate,
  isEditable = true,
}) {
  const { displayClassName, getOptions, getCostType } = useCostTypesOptions();
  const { deleteLineItemCrewMix } = useLineItemCrewMix();
  const updateCostType = React.useCallback(
    (value: string, category?: string | null) => {
      const costType = getCostType(value);
      const defaultUnit = costType?.units.find((unit) => unit.default)?.value;
      const defaultTrade = costType?.trades.find(
        (trade) => trade.default,
      )?.value;

      let options: LineItem = {
        id: record.id,
        cost_type: value,
        category,
        calculate_cost: ["% of subtotal", "calculated"].includes(
          defaultUnit ?? EMPTY_STRING,
        ),
        quantity_value: ["fixed", "calculated"].includes(
          defaultUnit ?? EMPTY_STRING,
        )
          ? "1"
          : undefined,
        quantity_unit: defaultUnit,
        trade: defaultTrade,
      };

      if (isTruthy(costType?.other_expense) && isTruthy(costType.default)) {
        options = {
          ...options,
          calculate_cost: true,
          calculation_percentage: costType.default,
        };
      }

      if (record.crew_mix_id != null && value !== record.cost_type) {
        modals.openConfirmModal({
          centered: true,
          transitionProps: {
            transition: "pop",
          },
          title: <div className={styles.title}>Heads up!</div>,
          children: (
            <div className={styles.body}>
              Your line item has individual classifications and hour allocations
              saved to the labor activities. Changing the cost type will discard
              these settings.
            </div>
          ),
          labels: { confirm: "Confirm", cancel: "Cancel" },
          cancelProps: { className: styles.button },
          confirmProps: { className: styles.button },
          onConfirm: () => {
            deleteLineItemCrewMix(record);
            handleUpdate(record.bundle_id, options);
          },
        });
      } else {
        handleUpdate(record.bundle_id, options);
      }
    },
    [deleteLineItemCrewMix, getCostType, handleUpdate, record],
  );

  const options = React.useMemo(() => {
    const currentOptions = getOptions(record.cost_type);

    if (record.parent_id != null) {
      return currentOptions.filter((option) =>
        ["material", "equipment", record.cost_type].includes(option.value),
      );
    }

    return currentOptions;
  }, [getOptions, record.cost_type, record.parent_id]);

  return (
    <Flex
      className={classNames(
        styles.root,
        record.parent_id != null ? styles.childItem : null,
      )}
    >
      <DropdownCell
        category={record.category}
        disabled={!isEditable}
        displayClassName={displayClassName}
        handleUpdate={updateCostType}
        options={options}
        placeholders={DROPDOWN_PLACEHOLDER[DropdownType.CostType]}
        value={record.cost_type}
      />
    </Flex>
  );
});
