import { Select } from "@mantine/core";
import { IconChevronDown } from "@tabler/icons-react";
import { ValueGetterParams } from "ag-grid-community";
import React from "react";
import { useMutateAttachment } from "src/components/EstimationView/Table/hooks/useMutateAttachment";
import { DEFAULT_SELECT_PLACEHOLDER } from "src/components/Fields/constants";
import { ATTACHMENT_TYPE_OPTIONS, StateAttachment } from "./constants";

interface Props {
  cellData: ValueGetterParams<StateAttachment>;
  onUpdate: () => void;
}

export const AttachmentType = React.memo<Props>(function _AttachmentType({
  cellData,
  onUpdate,
}) {
  const [selection, setSelection] = React.useState<string | null>(
    cellData.data?.attachment_type ?? null,
  );

  /* 
    Prevent table's rowClick event
  */
  const handleClick = React.useCallback(
    (event: React.MouseEvent<HTMLInputElement>) => {
      event.preventDefault();
    },
    [],
  );

  const { onUpdate: onAttachmentUpdate } = useMutateAttachment();

  const handleSelectType = React.useCallback(
    (value: string | null) => {
      setSelection(value);
      onUpdate();
      onAttachmentUpdate({
        attachment: cellData.data,
        attachmentType: value,
      });
    },
    [cellData.data, onAttachmentUpdate, onUpdate],
  );

  return (
    <Select
      allowDeselect={true}
      checkIconPosition="right"
      clearable={true}
      data={Object.values(ATTACHMENT_TYPE_OPTIONS)}
      onChange={handleSelectType}
      onClick={handleClick}
      placeholder={DEFAULT_SELECT_PLACEHOLDER}
      rightSection={<IconChevronDown size={12} style={{ strokeWidth: 2 }} />}
      rightSectionPointerEvents="none"
      styles={{
        dropdown: {
          minWidth: "200px",
          color: "var(--mantine-color-gray-7)",
          fontSize: "var(--mantine-font-size-xs)",
        },
        input: {
          borderRadius: "5px",
          height: "min-content",
          minHeight: "min-content",
          fontSize: "var(--mantine-font-size-xs)",
          color: "var(--mantine-color-gray-7)",
        },
      }}
      value={selection}
      variant="unstyled"
      withScrollArea={false}
    />
  );
});
