import { Button } from "@mantine/core";
import { skipToken } from "@reduxjs/toolkit/query";
import { IconPlus } from "@tabler/icons-react";
import React from "react";
import { useAddAttachment } from "src/components/EstimationView/Table/hooks/useAddAttachment";
import { ManageAttachmentsContent } from "src/components/EstimationView/Table/ManageAttachmentsContent";
import { useGetBidAttachmentsQuery } from "src/data/api/api";
import { ResourceType } from "src/data/api/types/bulkTransaction";
import { EMPTY_STRING } from "src/utils/empty";
import { ContentSection } from "../ContentSection";
import styles from "./Attachments.module.scss";

interface Props {
  readonly bidId: string | undefined;
}

export const Attachments = React.memo<Props>(function _Attachments({ bidId }) {
  const addAttachment = useAddAttachment();
  const { currentData: bidAttachmentsQuery } = useGetBidAttachmentsQuery(
    bidId ?? skipToken,
  );

  return (
    <div className={styles.container}>
      <ContentSection
        className={styles.card}
        rightSection={
          <Button
            className={styles.button}
            leftSection={<IconPlus size={16} />}
            onClick={() =>
              addAttachment({
                objectId: bidId != null ? parseInt(bidId) : null,
                objectType: ResourceType.Bid,
              })
            }
            styles={{
              inner: {
                justifyContent: "flex-start",
              },
              section: {
                marginRight: "4px",
              },
            }}
            variant="filled"
          >
            Upload file(s)
          </Button>
        }
        title="Attachments"
      >
        <ManageAttachmentsContent
          className={styles.tableWrapper}
          objectId={parseInt(bidId ?? EMPTY_STRING) || null}
          objectType={ResourceType.Bid}
          subAttachments={bidAttachmentsQuery?.attachments}
        />
      </ContentSection>
    </div>
  );
});
