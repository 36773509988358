import { LineItemCrewMixFormValues } from "src/components/Forms/types/crewMix";
import {
  AddCrewMixArgs,
  ClassificationLaborAttributes,
} from "src/data/api/types/addCrewMix";
import { LaborSource } from "src/data/api/types/shared/laborSource";
import { EMPTY_ARRAY } from "src/utils/empty";
import { HOUR_TYPE_LOOKUP } from "../constants";
import { EstimationResourceType, LaborTypeTime } from "./utils";

interface Args {
  addCrewMix: (arg: AddCrewMixArgs) => Promise<void>;
  crewMixSelections: LineItemCrewMixFormValues;
  laborSource: LaborSource;
  lineItemId: number;
  packageId: number;
  trade: string;
}

export async function addLineItemCrewMix({
  addCrewMix,
  crewMixSelections,
  laborSource,
  lineItemId,
  packageId,
  trade,
}: Args): Promise<void> {
  const percentagesAttributes = laborSource.attributes.labor_types
    .map((laborType): Array<ClassificationLaborAttributes> => {
      const isSelectedLaborType =
        laborType.name === crewMixSelections.classification;

      return [
        {
          labor_type_id: laborType.id,
          labor_type_time: LaborTypeTime.StraightTime,
          percentage:
            isSelectedLaborType &&
            crewMixSelections.hourType ===
              HOUR_TYPE_LOOKUP[LaborTypeTime.StraightTime]
              ? 1
              : 0,
          wage: laborType.straight_time,
          wage_total: parseFloat(laborType.straight_total),
        },
        {
          labor_type_id: laborType.id,
          labor_type_time: LaborTypeTime.OverTime,
          percentage:
            isSelectedLaborType &&
            crewMixSelections.hourType ===
              HOUR_TYPE_LOOKUP[LaborTypeTime.OverTime]
              ? 1
              : 0,
          wage: laborType.over_time,
          wage_total: parseFloat(laborType.over_total),
        },
        {
          labor_type_id: laborType.id,
          labor_type_time: LaborTypeTime.DoubleTime,
          percentage:
            isSelectedLaborType &&
            crewMixSelections.hourType ===
              HOUR_TYPE_LOOKUP[LaborTypeTime.DoubleTime]
              ? 1
              : 0,
          wage: laborType.double_time,
          wage_total: parseFloat(laborType.double_total),
        },
      ];
    })
    .flat();

  await addCrewMix({
    crew_heads: 0,
    labor_source_id: parseInt(laborSource.id),
    package_id: packageId,
    resource_id: lineItemId,
    resource_type: EstimationResourceType.LineItem,
    trade: trade,
    differential:
      typeof crewMixSelections.differential == "string"
        ? 0
        : crewMixSelections.differential / 100,
    escalation: 0,
    escalation_type: "fixed",
    crew_mix_percentages_attributes: percentagesAttributes ?? EMPTY_ARRAY,
  });
}
