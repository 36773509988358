import { Group, Pill, Table } from "@mantine/core";
import React, { useCallback } from "react";
import styles from "src/components/BidProfileView/Overview/Estimations/EstimationsTable.module.scss";
import { EstimationVersionNumber } from "src/components/EstimationView/EstimationVersionNumber";
import { UserChip } from "src/components/Frames/EntityChip/UserChip";
import { RowHoverOptions } from "src/components/Frames/RowHoverOptions";
import rowHoverOptionsStyles from "src/components/Frames/RowHoverOptions.module.scss";
import { useFeatureFlags } from "src/context/FeatureFlagProvider/useFeatureFlags";
import { api } from "src/data/api/api";
import { ResourceType } from "src/data/api/types/bulkTransaction";
import {
  CalculatedByType,
  Estimation,
  LoadedBid,
} from "src/data/api/types/shared/bid";
import { CostCodeButton } from "./CostCodeButton";
import { EditEstimationDialog } from "./EditEstimationDialog";
import { EstimationActions } from "./EstimationActions";
import { EstimationButton } from "./EstimationButton";

interface Props {
  readonly bid: LoadedBid;
  readonly shouldHideActions?: boolean;
}

export const EstimationsTable = React.memo<Props>(function _EstimationsTable({
  bid,
  shouldHideActions = false,
}) {
  const isVersionsEnabled = useFeatureFlags((flags) => flags.versions);
  const [trigger] = api.endpoints.bulkTransaction.useMutation();
  const bidOptions = api.endpoints.getBidOptions.useQuery();
  const [editId, setEditId] = React.useState<string>();

  const handleDialogClose = React.useCallback(() => {
    setEditId(undefined);
  }, []);

  const handleEstimationDelete = React.useCallback(
    (id: string) => {
      const estimation = bid.attributes.estimations.find(
        (entry) => entry.attributes.uuid === id,
      );

      if (estimation?.attributes.id == null) {
        throw new Error();
      }

      trigger({
        bulk_transaction: {
          deletions: [
            {
              resource_type: ResourceType.Estimation,
              resource_id: estimation.attributes.id,
            },
          ],
        },
      });
    },
    [bid.attributes.estimations, trigger],
  );

  const showTotalSellAmount = useCallback(
    (estimation: Estimation["attributes"]) => {
      if (estimation.total == null) {
        return false;
      }

      if (
        estimation.calculated_by == null ||
        estimation.calculated_by === CalculatedByType.Manual
      ) {
        return true;
      }

      return ["submitted", "won", "lost"].includes(estimation.status);
    },
    [],
  );

  const estimations = React.useMemo(() => {
    if (isVersionsEnabled) {
      return bid.attributes.estimations.filter(
        (estimation) => estimation.attributes.current,
      );
    }

    return bid.attributes.estimations;
  }, [bid.attributes.estimations, isVersionsEnabled]);

  return (
    <Table>
      <Table.Thead>
        <Table.Tr>
          <Table.Th>Salesperson</Table.Th>
          <Table.Th className={styles.noWrap}>Job type</Table.Th>
          {isVersionsEnabled ? <Table.Th>Latest</Table.Th> : null}
          <Table.Th>Status</Table.Th>
          <Table.Th className={styles.noWrap}>Total sell amount</Table.Th>
          <Table.Th>Estimation</Table.Th>
          <Table.Th>Cost codes</Table.Th>
          {!shouldHideActions && <Table.Th />}
        </Table.Tr>
      </Table.Thead>
      <Table.Tbody>
        {estimations.map((estimation) => {
          const user = estimation.users[0];
          return (
            <Table.Tr
              key={estimation.attributes.uuid}
              className={rowHoverOptionsStyles.row}
            >
              <Table.Td>
                {user != null && <UserChip id={user.id.toString()} />}
              </Table.Td>
              <Table.Td>
                <Group gap={5} wrap="wrap">
                  {estimation.attributes.job_types?.map((jobType) => {
                    const displayName = bidOptions.currentData?.jobTypes.find(
                      (entry) => entry.value === jobType,
                    )?.label;

                    return displayName != null ? (
                      <Pill key={jobType}>{displayName}</Pill>
                    ) : undefined;
                  })}
                </Group>
              </Table.Td>
              {isVersionsEnabled ? (
                <Table.Td>
                  <div style={{ width: "25px" }}>
                    <EstimationVersionNumber
                      borderRadius="4px"
                      color="black"
                      padding="3px"
                      version={estimation.attributes.version_number ?? 1}
                    />
                  </div>
                </Table.Td>
              ) : null}
              <Table.Td className={styles.noWrap}>
                {
                  bidOptions.currentData?.status.find(
                    ({ value }) => value === estimation.attributes.status,
                  )?.label
                }
              </Table.Td>
              <Table.Td>
                {showTotalSellAmount(estimation.attributes)
                  ? `$${estimation.attributes.total?.toLocaleString()}`
                  : undefined}
              </Table.Td>

              <Table.Td>
                <EstimationButton estimation={estimation} />
              </Table.Td>

              <Table.Td>
                {estimation.attributes.calculated_by ===
                CalculatedByType.System ? (
                  <CostCodeButton estimation={estimation} />
                ) : null}
              </Table.Td>
              {!shouldHideActions && (
                <Table.Td>
                  {isVersionsEnabled ? (
                    <EstimationActions
                      estimationId={estimation.id}
                      estimationUuid={estimation.attributes.uuid}
                      onDelete={handleEstimationDelete}
                      onEdit={setEditId}
                    />
                  ) : (
                    <RowHoverOptions
                      id={estimation.attributes.uuid}
                      onDelete={handleEstimationDelete}
                      onEdit={setEditId}
                    />
                  )}
                </Table.Td>
              )}
            </Table.Tr>
          );
        })}
      </Table.Tbody>
      {editId != null && (
        <EditEstimationDialog
          bid={bid}
          estimationId={editId}
          onClose={handleDialogClose}
        />
      )}
    </Table>
  );
});
