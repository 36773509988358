import { Stack } from "@mantine/core";
import React, { useEffect } from "react";
import { useLazyAllVersionsQuery } from "src/data/api/api";
import { EstimationVersion } from "./EstimationVersion";

interface Props {
  estimationId: string;
  openEstimation: (estimationId: string) => void;
}

const EstimationVersionHistory = ({ estimationId, openEstimation }: Props) => {
  const [fetchAllVersions, { data }] = useLazyAllVersionsQuery();

  const versions = React.useMemo(() => {
    if (data == null) {
      return [];
    }

    return data.collection.data;
  }, [data]);

  const handleVersions = React.useCallback(
    (newEstimationId: string) => {
      fetchAllVersions(newEstimationId);
    },
    [fetchAllVersions],
  );

  useEffect(() => {
    handleVersions(estimationId);
  }, [estimationId, handleVersions]);

  return (
    <Stack>
      {versions.map((version) => (
        <EstimationVersion
          key={version.id}
          onMarkCurrent={handleVersions}
          onOpen={openEstimation}
          version={version}
        />
      ))}
    </Stack>
  );
};

export default EstimationVersionHistory;
