import { Select } from "@mantine/core";
import React from "react";
import { Control, useController } from "react-hook-form";
import { useAllVersionsQuery } from "src/data/api/api";

type FormValue = {
  name: string;
  notes: string;
  version: string | null;
};

interface Props {
  estimationId: string;
  control: Control<FormValue>;
}

export const SelectEstimationVersion = ({ control, estimationId }: Props) => {
  const { field } = useController({ control, name: "version" });
  const { currentData } = useAllVersionsQuery(estimationId);

  const options = React.useMemo(() => {
    if (currentData == null) {
      return [];
    }

    return currentData.collection.data.map((estimation) => ({
      label: `V${estimation.attributes.version_number} - ${
        estimation.attributes.title ??
        `V${estimation.attributes.version_number}`
      }`,
      value: estimation.id,
    }));
  }, [currentData]);

  return (
    <Select
      data={options}
      label="Select base version"
      onChange={field.onChange}
      placeholder="Version"
      required
      value={field.value}
    />
  );
};
