import {
  ActionIcon,
  Badge,
  Button,
  Group,
  NumberFormatter,
  Stack,
} from "@mantine/core";
import { useDisclosure } from "@mantine/hooks";
import { modals } from "@mantine/modals";
import { IconChevronDown, IconChevronUp, IconTrash } from "@tabler/icons-react";
import React from "react";
import { EstimationVersionNumber } from "src/components/EstimationView/EstimationVersionNumber";
import {
  useGetPackagesQuery,
  useMarkEstimationCurrentMutation,
} from "src/data/api/api";
import { Estimation } from "src/data/api/types/shared/bid";
import { buildPackageTotal } from "src/utils/buildPackageTotal";
import { calculateTotal } from "src/utils/calculateTotal";
import { formatDate } from "src/utils/formatDate";
import { formatNumberHundredths } from "src/utils/formatNumberHundredths";
import { ConfirmationEstimationDeletionDialog } from "./ConfirmationEstimationDeletionDialog";
import { Packages } from "./Packages";

interface Props {
  version: Estimation;
  onOpen: (estimationId: string) => void;
  onMarkCurrent: (estimationId: string) => void;
}

export const EstimationVersion = ({
  version,
  onOpen,
  onMarkCurrent,
}: Props) => {
  const [opened, { open: openModal, close: closeModal }] = useDisclosure(false);
  const { currentData } = useGetPackagesQuery(version.id);
  const [markEstimationCurrent, { isLoading: isLoadingMarkCurrent }] =
    useMarkEstimationCurrentMutation();
  const [open, setOpen] = React.useState(false);

  const title = React.useMemo(() => {
    if (version.attributes.title != null) {
      return version.attributes.title;
    }

    return `Version ${version.attributes.version_number}`;
  }, [version.attributes.title, version.attributes.version_number]);

  const packages = React.useMemo(() => {
    if (currentData == null) {
      return [];
    }

    return currentData.collection.map(buildPackageTotal);
  }, [currentData]);

  const basePrice = React.useMemo(() => {
    return formatNumberHundredths(
      calculateTotal(
        packages.filter((p) => /^base/i.test(p.display_name ?? "")),
        "grand_total",
      ),
    );
  }, [packages]);

  const openEstimation = React.useCallback(() => {
    onOpen(version.id);
    modals.closeAll();
  }, [onOpen, version.id]);

  const handleMarkEstimationCurrent = React.useCallback(() => {
    markEstimationCurrent(version.id).then(() => onMarkCurrent(version.id));
  }, [onMarkCurrent, markEstimationCurrent, version.id]);

  return (
    <>
      <Stack
        key={version.id}
        gap="xs"
        p="md"
        style={{ border: "1px solid black", borderRadius: "5px" }}
      >
        <Group align="flex-start" gap="xs">
          <EstimationVersionNumber
            borderRadius="5px"
            color="black"
            padding="10px"
            version={version.attributes.version_number}
          />
          <Stack gap="0">
            <Group>
              <span style={{ fontWeight: "bolder" }}>{title}</span>
              {version.attributes.current ? (
                <Badge color="blue" radius="xs" size="lg" variant="outline">
                  CURRENT
                </Badge>
              ) : null}
            </Group>
            <span
              style={{ fontSize: "12px" }}
            >{`Last edited by ${version.meta.updated_by?.first_name} ${version.meta.updated_by?.last_name}, ${formatDate(new Date(version.meta.updated_at!))}`}</span>
            {version.attributes.quick_notes ? (
              <span style={{ fontSize: "12px" }}>
                {version.attributes.quick_notes}
              </span>
            ) : null}
          </Stack>
          <Group gap="xs" ml="auto">
            <Button onClick={openEstimation} size="xs" variant="outline">
              Open
            </Button>
            {version.attributes.current === false ? (
              <Button
                loading={isLoadingMarkCurrent}
                onClick={handleMarkEstimationCurrent}
                size="xs"
                variant="outline"
              >
                Mark current
              </Button>
            ) : null}
            <Group gap={0}>
              {version.attributes.current === true ? null : (
                <ActionIcon c="black" onClick={openModal} variant="transparent">
                  <IconTrash size={18} />
                </ActionIcon>
              )}
              <ActionIcon
                c="black"
                onClick={() => setOpen((o) => !o)}
                pl={0}
                variant="transparent"
              >
                {open ? (
                  <IconChevronUp size={18} />
                ) : (
                  <IconChevronDown size={18} />
                )}
              </ActionIcon>
            </Group>
          </Group>
        </Group>
        {open ? (
          <Packages packages={packages} />
        ) : (
          <span style={{ fontWeight: "bolder", fontSize: "12px" }}>
            {`Total Base Package Sell Price: `}
            <NumberFormatter prefix="$" thousandSeparator value={basePrice} />
          </span>
        )}
      </Stack>
      <ConfirmationEstimationDeletionDialog
        closeModal={closeModal}
        opened={opened}
        version={version}
      />
    </>
  );
};
