import { Chip, ChipProps } from "@mantine/core";
import { IconMapPin } from "@tabler/icons-react";
import React from "react";
import { useNavigate } from "react-router-dom";
import { Paths } from "src/constants/paths";
import { api } from "src/data/api/api";
import { CHIP_STYLES } from "./constants";

interface Props extends Omit<ChipProps, "children"> {
  readonly id: string;
}

export const JobSiteChip = React.memo(function _JobSiteChip({
  id,
  ...chipProps
}: Props) {
  const navigate = useNavigate();

  const { data } = api.endpoints.getJobSite.useQuery(id);

  const handleClick = React.useCallback(() => {
    navigate(Paths.SLASH + Paths.JOB_SITES + Paths.SLASH + id);
  }, [id, navigate]);

  return (
    <Chip
      checked={true}
      color="gray"
      icon={<IconMapPin size={14} />}
      onClick={handleClick}
      radius="sm"
      styles={CHIP_STYLES}
      variant="outline"
      {...chipProps}
    >
      {data?.job_site.data.attributes.address?.street1}
      {", "}
      {data?.job_site.data.attributes.address?.city}
    </Chip>
  );
});
