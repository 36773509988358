/* eslint-disable immutable/no-mutation */
import { notifications } from "@mantine/notifications";
import { IconCheck, IconX } from "@tabler/icons-react";
import React from "react";
import { AttachmentNotificationMessage } from "src/components/Frames/Attachments/AttachmentNotificationMessage";
import { AttachmentParent } from "src/components/Frames/Attachments/utils";
import { api } from "src/data/api/api";
import { convertHeicToJpg } from "src/utils/convertHeicToJpg";
import { EMPTY_STRING } from "src/utils/empty";
import { getMd5Checksum } from "src/utils/getMd5Checksum";
import { isTruthy } from "src/utils/isTruthy";
import { showFailNotification } from "src/utils/notifications/showFailNotification";
import { uploadS3File } from "src/utils/uploadS3File";

const AUTO_CLOSE_TIMEOUT_MS = 10_000;
const NOTIFICATION_ID = crypto.randomUUID();

interface UploadArgs extends AttachmentParent {
  files: Array<File>;
}

export function useAddAttachment() {
  const [attachFiles] = api.endpoints.addAttachments.useMutation();
  const [generatePresignedUrl] =
    api.endpoints.generatePresignedUrl.useMutation();

  const handleUploadAttachment = React.useCallback(
    async ({ files, objectId, objectType }: UploadArgs) => {
      if (objectId == null) {
        return showFailNotification();
      }

      notifications.show({
        autoClose: false,
        id: NOTIFICATION_ID,
        loading: true,
        message: (
          <AttachmentNotificationMessage
            fileAmount={files.length}
            notificationId={NOTIFICATION_ID}
          />
        ),
      });

      const signedIds = await Promise.all(
        files.map(async (file) => {
          try {
            let processedFile = file;
            if (["image/heic", "image/heif"].includes(file.type)) {
              processedFile = await convertHeicToJpg(file);
            }

            const checksum = await getMd5Checksum(processedFile);
            const urlData = await generatePresignedUrl({
              byte_size: processedFile.size,
              checksum: checksum,
              content_type: processedFile.type,
              filename: processedFile.name,
              object_id: objectId,
              object_type: objectType,
            }).unwrap();

            /* 
              Upload file to S3
            */
            const response = await uploadS3File(
              processedFile,
              urlData.direct_upload,
              urlData.headers,
            );

            if (!response.ok) {
              throw new Error(`Failed to upload file: ${response.statusText}`);
            }

            return urlData.signed_id;
          } catch (error) {
            notifications.show({
              color: "red",
              icon: <IconX />,
              loading: false,
              message: `Failed to upload file: ${file.name}`,
              title: "Error",
            });

            return EMPTY_STRING;
          }
        }),
      );

      try {
        const uploadedIds = signedIds.filter(isTruthy);
        if (uploadedIds.length === 0) {
          notifications.hide(NOTIFICATION_ID);
          throw new Error();
        }

        await attachFiles({
          object_id: objectId,
          object_type: objectType,
          signed_ids: uploadedIds,
        });

        notifications.update({
          autoClose: AUTO_CLOSE_TIMEOUT_MS,
          color: "teal",
          icon: <IconCheck />,
          id: NOTIFICATION_ID,
          loading: false,
          message: (
            <AttachmentNotificationMessage
              fileAmount={files.length}
              notificationId={NOTIFICATION_ID}
              objectId={objectId}
              objectType={objectType}
              successAmount={uploadedIds.length}
            />
          ),
        });
      } catch (error) {
        showFailNotification(NOTIFICATION_ID);
      }
    },
    [attachFiles, generatePresignedUrl],
  );

  return React.useCallback(
    ({ objectId, objectType }: AttachmentParent) => {
      const input = document.createElement("input");
      input.type = "file";
      input.multiple = true;
      input.accept =
        "image/png,image/jpeg,image/bmp,image/tiff,image/webp,image/heic,image/heif,application/pdf,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document,application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,application/vnd.ms-excel.sheet.macroEnabled.12,text/csv";

      input.onchange = (event) => {
        const files = (event.target as HTMLInputElement).files;
        if (files != null && files.length > 0) {
          handleUploadAttachment({
            files: Array.from(files),
            objectId,
            objectType,
          });
        }
      };

      input.click();
    },
    [handleUploadAttachment],
  );
}
