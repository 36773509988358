import { Button, Group, Tooltip } from "@mantine/core";
import { notifications } from "@mantine/notifications";
import { skipToken } from "@reduxjs/toolkit/query";
import React from "react";
import { useForm, useWatch } from "react-hook-form";
import { LineItemCrewMixFormContent } from "src/components/Forms/LineItemCrewMixFormContent";
import { LineItemCrewMixFormValues } from "src/components/Forms/types/crewMix";
import { FormFooter } from "src/components/Frames/FormFooter";
import { api } from "src/data/api/api";
import {
  AddCrewMixArgs,
  ClassificationLaborAttributes,
} from "src/data/api/types/addCrewMix";
import { BundleLineItem } from "src/data/api/types/getBundles";
import { LoadedCrewMix } from "src/data/api/types/shared/crewMix";
import { LaborSource } from "src/data/api/types/shared/laborSource";
import { UpdateCrewMixArgs } from "src/data/api/types/updateCrewMix";
import { EMPTY_ARRAY, EMPTY_STRING } from "src/utils/empty";
import {
  DEFAULT_CLASSIFICATION,
  HOUR_TYPE_LOOKUP,
  HOUR_TYPE_OPTIONS,
  TRADE_OPTIONS_FOR_LABOR,
} from "./constants";
import styles from "./LineItemCrewMixDialog.module.scss";
import { addLineItemCrewMix } from "./util/addLineItemCrewMix";
import { LaborTypeTime } from "./util/utils";

interface Props {
  onClose: () => void;
  packageCrewMix: LoadedCrewMix | undefined;
  packageId: string;
  record: BundleLineItem;
}

export const LineItemCrewMixDialog = React.memo<Props>(
  function _LineItemCrewMixDialog({
    onClose,
    packageCrewMix,
    packageId,
    record,
  }) {
    const [formKey, setFormKey] = React.useState<number>(0);
    const [addCrewMix, addState] = api.endpoints.addCrewMix.useMutation();
    const [deleteCrewMix, deleteState] =
      api.endpoints.deleteCrewMix.useMutation();
    const [updateCrewMix, updateState] =
      api.endpoints.updateCrewMix.useMutation();

    /* 
      Ensure form content remounts with current control
    */
    React.useEffect(() => {
      setFormKey((prevKey) => prevKey + 1);
    }, []);

    /* 
      Load crew mix on this line item
    */
    const lineItemCrewMix = api.endpoints.getCrewMix.useQuery(
      record.crew_mix_id ?? skipToken,
    ).currentData?.crew_mix.data;

    /* 
      Load labor source for line item's crew mix OR this trade's package crew mix
    */
    const laborSourceQuery = api.endpoints.getLaborSource.useQuery(
      lineItemCrewMix?.attributes.labor_source.id?.toString() ??
        packageCrewMix?.attributes.labor_source.id.toString() ??
        skipToken,
    );

    const { isLaborSourceLoaded, laborSource } = React.useMemo(() => {
      return {
        isLaborSourceLoaded:
          laborSourceQuery.isSuccess || laborSourceQuery.isError,
        laborSource: laborSourceQuery.currentData?.labor_source.data,
      };
    }, [laborSourceQuery]);

    const classificationOptions = React.useMemo(() => {
      return [
        DEFAULT_CLASSIFICATION,
        ...(laborSource?.attributes.labor_types
          .map((laborType) => laborType.name)
          .filter((option) => option != null) ?? EMPTY_ARRAY),
      ];
    }, [laborSource]);

    const isLoading =
      !isLaborSourceLoaded ||
      (record.crew_mix_id != null && lineItemCrewMix == null);

    const isWarehouse = React.useMemo(() => {
      return record.trade === TRADE_OPTIONS_FOR_LABOR.warehouse;
    }, [record]);

    const isProjectManagement = React.useMemo(() => {
      return record.trade === TRADE_OPTIONS_FOR_LABOR.projectManagement;
    }, [record]);

    const selectedCrewMixPercentage = React.useMemo(() => {
      return lineItemCrewMix?.attributes.crew_mix_percentages.find(
        (obj) => obj.percentage === 1,
      );
    }, [lineItemCrewMix]);

    const initialClassification = React.useMemo(() => {
      return (
        laborSource?.attributes.labor_types.find(
          (laborType) =>
            laborType.id === selectedCrewMixPercentage?.labor_type_id,
        )?.name ?? classificationOptions[0]
      );
    }, [classificationOptions, laborSource, selectedCrewMixPercentage]);

    const initialHourType = React.useMemo(() => {
      return (
        HOUR_TYPE_LOOKUP[
          selectedCrewMixPercentage?.labor_type_time as LaborTypeTime
        ] ?? HOUR_TYPE_OPTIONS[0]
      );
    }, [selectedCrewMixPercentage]);

    const initialDifferential = React.useMemo(() => {
      return (
        parseFloat(lineItemCrewMix?.attributes.differential ?? EMPTY_STRING) *
        100
      );
    }, [lineItemCrewMix]);

    const {
      control,
      formState: { isDirty },
      handleSubmit,
      reset,
    } = useForm<LineItemCrewMixFormValues>({
      defaultValues: {
        classification: isWarehouse
          ? classificationOptions[1]
          : initialClassification,
        hourType: isProjectManagement ? HOUR_TYPE_OPTIONS[1] : initialHourType,
        differential: initialDifferential,
      },
    });

    /* 
      Side effect to update form with loaded-in values
    */
    React.useEffect(() => {
      if (!isLoading && (record.crew_mix_id != null || isWarehouse)) {
        reset({
          classification: isWarehouse
            ? classificationOptions[1]
            : initialClassification,
          hourType: initialHourType,
          differential: initialDifferential,
        });
      }
    }, [
      classificationOptions,
      initialClassification,
      initialDifferential,
      initialHourType,
      isLoading,
      isWarehouse,
      record,
      reset,
    ]);

    const classificationValue = useWatch({ control, name: "classification" });
    const hourTypeValue = useWatch({ control, name: "hourType" });
    const isFormInvalid = React.useMemo(() => {
      return (
        (classificationValue === DEFAULT_CLASSIFICATION &&
          hourTypeValue !== HOUR_TYPE_OPTIONS[0] &&
          !isProjectManagement) ||
        (classificationValue !== DEFAULT_CLASSIFICATION &&
          hourTypeValue === HOUR_TYPE_OPTIONS[0] &&
          !isWarehouse)
      );
    }, [classificationValue, hourTypeValue, isProjectManagement, isWarehouse]);

    const handleDialogSubmit = React.useCallback(
      async (formValues: LineItemCrewMixFormValues) => {
        if (laborSource == null) return;
        if (!isDirty) onClose(); // user made no changes

        try {
          await saveLineItemCrewMix({
            addCrewMix: async (args: AddCrewMixArgs) => {
              addCrewMix(args);
            },
            crewMixSelections: formValues,
            crewMixOnLineItem: lineItemCrewMix,
            deleteCrewMix: async (arg: number) => {
              deleteCrewMix(arg);
            },
            laborSource,
            record,
            packageId: parseInt(packageId),
            updateCrewMix: async (args: UpdateCrewMixArgs) => {
              updateCrewMix(args);
            },
          });
        } catch (error) {
          notifications.show({
            message: "Crew mix couldn't be updated. Please try again.",
          });
        }
      },
      [
        addCrewMix,
        deleteCrewMix,
        isDirty,
        laborSource,
        lineItemCrewMix,
        onClose,
        packageId,
        record,
        updateCrewMix,
      ],
    );

    React.useEffect(() => {
      if (
        addState.isSuccess ||
        updateState.isSuccess ||
        deleteState.isSuccess ||
        deleteState.isError
      ) {
        onClose();
      }
    }, [addState, deleteState, onClose, updateState]);

    return (
      <>
        <LineItemCrewMixFormContent
          key={formKey}
          classificationOptions={classificationOptions}
          control={control}
          isLoading={isLoading}
          isProjectManagement={isProjectManagement}
          isWarehouse={isWarehouse}
        />
        <FormFooter
          rightSection={
            <Group>
              <Button
                className={styles.button}
                onClick={onClose}
                variant="default"
              >
                Cancel
              </Button>
              <Tooltip
                className={styles.tooltip}
                disabled={!isFormInvalid}
                label="Cannot set only one field to default"
              >
                <Button
                  className={styles.button}
                  disabled={isFormInvalid}
                  loading={
                    addState.isLoading ||
                    updateState.isLoading ||
                    deleteState.isLoading
                  }
                  onClick={handleSubmit(handleDialogSubmit)}
                  variant="filled"
                >
                  Apply
                </Button>
              </Tooltip>
            </Group>
          }
        />
      </>
    );
  },
);

interface Args {
  addCrewMix: (arg: AddCrewMixArgs) => Promise<void>;
  crewMixSelections: LineItemCrewMixFormValues;
  crewMixOnLineItem: LoadedCrewMix | undefined;
  deleteCrewMix: (arg: number) => Promise<void>;
  laborSource: LaborSource;
  record: BundleLineItem;
  packageId: number | undefined;
  updateCrewMix: (arg: UpdateCrewMixArgs) => Promise<void>;
}

async function saveLineItemCrewMix({
  addCrewMix,
  crewMixSelections,
  crewMixOnLineItem,
  deleteCrewMix,
  laborSource,
  record,
  packageId,
  updateCrewMix,
}: Args) {
  if (packageId == null || record.trade == null) {
    return;
  }

  const isWarehouse = record.trade === TRADE_OPTIONS_FOR_LABOR.warehouse;
  const isProjectManagement =
    record.trade === TRADE_OPTIONS_FOR_LABOR.projectManagement;

  if (
    (isWarehouse && crewMixSelections.hourType === HOUR_TYPE_OPTIONS[0]) ||
    (isProjectManagement &&
      crewMixSelections.classification === DEFAULT_CLASSIFICATION) ||
    (!isWarehouse &&
      !isProjectManagement &&
      crewMixSelections.classification === DEFAULT_CLASSIFICATION)
  ) {
    /* 
      user resets line item crew mix to defaults
    */
    if (record.crew_mix_id == null) return;
    deleteCrewMix(record.crew_mix_id);
  } else if (record.crew_mix_id == null) {
    /* 
      user sets line item crew mix instead of using package level crew mix
    */
    await addLineItemCrewMix({
      addCrewMix,
      crewMixSelections,
      laborSource,
      lineItemId: record.id,
      packageId,
      trade: record.trade,
    });
  } else if (record.crew_mix_id != null) {
    /* 
      user updates line item crew mix to have different options
    */
    const percentagesAttributes =
      crewMixOnLineItem?.attributes.crew_mix_percentages
        .map((obj): ClassificationLaborAttributes => {
          const isSelectedClassification =
            laborSource.attributes.labor_types.find(
              (laborType) =>
                laborType.name === crewMixSelections.classification,
            )?.id === obj.labor_type_id;

          const isSelectedHourType =
            Object.keys(HOUR_TYPE_LOOKUP)[
              Object.values(HOUR_TYPE_LOOKUP).indexOf(
                crewMixSelections.hourType,
              )
            ] === obj.labor_type_time;

          return {
            ...obj,
            labor_type_percentage: parseFloat(obj.labor_type_percentage),
            percentage: isSelectedClassification && isSelectedHourType ? 1 : 0,
            wage_total: parseFloat(obj.wage_total),
          };
        })
        .filter((entry) => entry != null);

    await updateCrewMix({
      crew_mix_id: record.crew_mix_id,
      differential:
        typeof crewMixSelections.differential == "string"
          ? 0
          : crewMixSelections.hourType ===
              HOUR_TYPE_LOOKUP[LaborTypeTime.StraightTime]
            ? crewMixSelections.differential / 100
            : 0,
      crew_mix_percentages_attributes: percentagesAttributes ?? EMPTY_ARRAY,
    });
  }
}
