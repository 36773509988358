import { ActionIcon, Group, Menu, Tooltip } from "@mantine/core";
import {
  IconCirclePlus,
  IconClipboardCopy,
  IconPlus,
} from "@tabler/icons-react";
import { memo, useCallback, useMemo, useState } from "react";
import { useSelectedLineItems } from "src/context/SelectedLineItemsProvider";
import { useCloneItemsMutation } from "src/data/api/api";
import { useScrollIntoView } from "src/hooks/useScrollIntoView";
import { SCROLLABLE_ELEMENT } from "src/utils/scrollableElement";
import { CONTEXT_MENU_KEYSTROKE_STYLES, KEYSTROKES } from "../../constants";
import { useCreateBundle } from "../../hooks/useCreateBundle";

interface Props {
  packageId: string;
  disabled: boolean;
}

const CreateBundleButtons = memo(({ packageId, disabled }: Props) => {
  const {
    action,
    selectedItems,
    setAction,
    setSelectedItems,
    selectedBundles,
    setSelectedBundles,
  } = useSelectedLineItems();
  const [opened, setOpened] = useState(false);
  const [cloneItems] = useCloneItemsMutation();
  const scrollIntoView = useScrollIntoView(SCROLLABLE_ELEMENT, 200);
  const { createLineItemBundle } = useCreateBundle(packageId);

  const handleContextMenu = useCallback(
    (event: React.MouseEvent<HTMLButtonElement>) => {
      event.preventDefault();

      setOpened(true);
    },
    [],
  );

  const handleClose = useCallback(() => {
    setOpened(false);
  }, []);

  const count = useMemo(() => {
    return selectedBundles.length + selectedItems.length;
  }, [selectedBundles.length, selectedItems.length]);

  const handlePaste = useCallback(() => {
    if (action != null && count > 0) {
      if (packageId == null) return;

      const currentPackageId =
        selectedBundles[0]?.package_id.toString() ||
        selectedItems[0]?.package_id.toString();

      if (currentPackageId == null) return;

      const unitBundles = [
        ...new Set(
          selectedItems
            .map((selected) => selected.bundle_id)
            .concat(selectedBundles.map((b) => b.id)),
        ),
      ];

      cloneItems({
        action_type: action,
        package_ids: [],
        bundle_ids: unitBundles.map((id) => ({ id })),
        line_item_ids: [],
        meta: {
          packageId: currentPackageId,
        },
        location: {
          type: "Package",
          id: Number(packageId),
        },
      }).then(() => {
        scrollIntoView();
      });

      setAction(null);
      setSelectedItems([]);
      setSelectedBundles([]);
    }
  }, [
    action,
    cloneItems,
    count,
    packageId,
    scrollIntoView,
    selectedBundles,
    selectedItems,
    setAction,
    setSelectedBundles,
    setSelectedItems,
  ]);

  const disabledPaste = useMemo(() => {
    if (action == null) return true;

    return selectedBundles.length < 1 && selectedItems.length < 1;
  }, [action, selectedBundles.length, selectedItems.length]);
  return (
    <Group
      bottom={40}
      gap="sm"
      left={10}
      pl="sm"
      pos="absolute"
      style={{
        zIndex: 110,
      }}
    >
      <Menu
        arrowPosition="side"
        closeOnItemClick
        onClose={handleClose}
        opened={opened}
        position="right-start"
        shadow="md"
        styles={{
          itemSection: CONTEXT_MENU_KEYSTROKE_STYLES,
        }}
        width={200}
      >
        <Menu.Target>
          <Tooltip label="Add new item">
            <ActionIcon
              disabled={disabled}
              onClick={createLineItemBundle}
              onContextMenu={handleContextMenu}
              radius="xl"
              size="xl"
              variant="filled"
            >
              <IconPlus />
            </ActionIcon>
          </Tooltip>
        </Menu.Target>

        <Menu.Dropdown>
          <Menu.Item
            onClick={createLineItemBundle}
            rightSection={KEYSTROKES.CTRL_A}
          >
            <Group gap="xs">
              <IconCirclePlus size="16px" />
              Add line item
            </Group>
          </Menu.Item>
          <Menu.Divider />
          <Menu.Item disabled={disabledPaste} onClick={handlePaste}>
            <Group gap="xs">
              <IconClipboardCopy size="16px" />
              {`Paste ${count} item(s)`}
            </Group>
          </Menu.Item>
        </Menu.Dropdown>
      </Menu>
    </Group>
  );
});

export default CreateBundleButtons;
