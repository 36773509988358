import { Group, Input } from "@mantine/core";
import React from "react";
import { useExpandableLineItems } from "src/context/ExpandableLineItemsProvider";
import { PackageBundle } from "src/data/api/types/getBundles";
import { UpdateBundleArgs } from "src/data/api/types/updateBundleArgs";
import { EMPTY_STRING } from "src/utils/empty";
import { ExpandIcon } from "../../ExpandIcon";
import { useCellKeystrokes } from "../../hooks/useCellKeystrokes";
import styles from "./GroupTitle.module.scss";

interface Props {
  bundle: PackageBundle;
  handleUpdate: (arg: UpdateBundleArgs) => void;
  disabled: boolean;
}

const GroupTitle = React.memo<Props>(function _GroupTitle({
  bundle,
  handleUpdate,
  disabled,
}) {
  const [titleWidth, setTitleWidth] = React.useState(50);
  const titleRef = React.useRef<HTMLSpanElement | null>(null);
  const inputRef = React.useRef<HTMLInputElement | null>(null);
  const [value, setValue] = React.useState(bundle.title ?? EMPTY_STRING);
  const { expandableGroups, setExpandableGroups } = useExpandableLineItems();

  const handleTitleUpdate = React.useCallback(() => {
    handleUpdate({
      bundle_id: bundle.id,
      line_item: {
        title: value,
      },
    });
  }, [bundle.id, handleUpdate, value]);

  const handleChange = React.useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      setValue(e.target.value);
    },
    [],
  );

  const handleExpandable = React.useCallback(() => {
    let newExpandables = expandableGroups.slice();

    if (expandableGroups.includes(bundle.id)) {
      newExpandables = newExpandables.filter((item) => item !== bundle.id);
    } else {
      newExpandables = newExpandables.concat(bundle.id);
    }

    setExpandableGroups(newExpandables);
  }, [bundle.id, expandableGroups, setExpandableGroups]);

  const { handleEnterEscapeBlur: handleKeyPresses } = useCellKeystrokes({
    ref: inputRef,
  });

  const handleInputFocus = React.useCallback((event: React.MouseEvent) => {
    event.stopPropagation();
  }, []);

  React.useEffect(() => {
    if (titleRef.current != null && value !== EMPTY_STRING) {
      const width = titleRef.current.offsetWidth + 30;
      setTitleWidth(width <= 200 ? width : 200);
    }
  }, [value]);

  return (
    <Group className={styles.root} onClick={handleExpandable}>
      <ExpandIcon
        className={styles.expandIcon}
        isExpanded={expandableGroups.includes(bundle.id)}
        onClick={handleExpandable}
      />
      <span ref={titleRef} className={styles.hidden}>
        {value ?? EMPTY_STRING}
      </span>
      <Input
        ref={inputRef}
        disabled={disabled}
        onBlur={handleTitleUpdate}
        onChange={handleChange}
        onClick={handleInputFocus}
        onKeyDown={handleKeyPresses}
        placeholder="Name"
        styles={{
          input: {
            width: `${titleWidth}px`,
            fontWeight: 700,
            fontSize: "11px",
            textTransform: "uppercase",
            textOverflow: "ellipsis",
          },
        }}
        value={value}
        variant="unstyled"
      />
    </Group>
  );
});

export default GroupTitle;
