import { ComboboxItem, Loader, Select, SelectProps } from "@mantine/core";
import { useDebouncedEffect } from "@react-hookz/web";
import { IconSearch } from "@tabler/icons-react";
import React from "react";
import { api } from "src/data/api/api";
import { Address } from "src/types/address";
import { EMPTY_ARRAY, EMPTY_STRING } from "src/utils/empty";
import { SEARCH_PLACEHOLDER } from "./constants";

type FieldValue = Address | null;

interface Props extends Omit<SelectProps, "onChange" | "value"> {
  readonly onChange: (value: FieldValue) => void;
  readonly value: FieldValue;
}

export const AddressField = React.memo<Props>(function _AddressField({
  onChange,
  value,
  ...props
}) {
  const [searchValue, setSearchValue] = React.useState(
    value?.text ?? EMPTY_STRING,
  );
  const [debouncedsearchValue, setDebouncedSearchValue] =
    React.useState(EMPTY_STRING);

  useDebouncedEffect(
    () => {
      if (searchValue.trim().length === 0) {
        return;
      }

      setDebouncedSearchValue(searchValue);
    },
    [searchValue],
    250,
  );

  const { currentData, isFetching, isLoading } =
    api.endpoints.getNormalizedAddresses.useQuery(debouncedsearchValue);

  const labelValues = React.useMemo((): ReadonlyArray<ComboboxItem> => {
    return (
      currentData?.collection.map((address) => {
        return {
          label: address.text,
          value: address.id,
        };
      }) ?? EMPTY_ARRAY
    );
  }, [currentData?.collection]);

  const handleChange = React.useCallback(
    (id: string | null) => {
      const payload = currentData?.collection.find((entry) => entry.id === id);

      if (payload == null) {
        return;
      }

      onChange(payload);
    },
    [currentData?.collection, onChange],
  );

  const handleBlur = React.useCallback(() => {
    setSearchValue(value?.text ?? EMPTY_STRING);
  }, [value?.text]);

  return (
    <Select
      checkIconPosition="right"
      data={labelValues}
      leftSection={<IconSearch size={16} />}
      onBlur={handleBlur}
      onChange={handleChange}
      onSearchChange={setSearchValue}
      placeholder={SEARCH_PLACEHOLDER}
      rightSection={isLoading || isFetching ? <Loader size={16} /> : undefined}
      searchValue={searchValue}
      searchable={true}
      value={value?.id ?? EMPTY_STRING}
      withScrollArea={false}
      {...props}
    />
  );
});
