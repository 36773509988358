import { modals } from "@mantine/modals";
import React from "react";
import {
  createSearchParams,
  useLocation,
  useNavigate,
  useSearchParams,
} from "react-router-dom";
import CreateNewVersionForm from "src/components/Forms/CreateNewVersionForm";
import { Paths } from "src/constants/paths";

export const useCreateEstimationVersion = (
  estimationId: string,
  toNavigate: boolean = false,
) => {
  const navigate = useNavigate();
  const location = useLocation();
  const [params] = useSearchParams();

  const onSuccess = React.useCallback(
    (newEstimationId: string) => {
      if (toNavigate === true) {
        const pathname =
          location.pathname.split("estimations")[0] +
          Paths.ESTIMATIONS +
          Paths.SLASH +
          newEstimationId;

        navigate({
          pathname,
          search: createSearchParams(params).toString(),
        });
      }
    },
    [location.pathname, navigate, params, toNavigate],
  );

  return React.useCallback(
    () =>
      modals.open({
        title: "Create new version",
        children: (
          <CreateNewVersionForm
            estimationId={estimationId}
            onClose={modals.closeAll}
            onSuccess={onSuccess}
          />
        ),
      }),
    [estimationId, onSuccess],
  );
};
