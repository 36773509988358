import { Button, ButtonProps, Menu, Stack } from "@mantine/core";
import { IconChevronDown, IconPlus } from "@tabler/icons-react";
import React from "react";
import { CONTEXT_MENU_KEYSTROKE_STYLES, KEYSTROKES } from "../../constants";
import { useCreateBundle } from "../../hooks/useCreateBundle";
import styles from "./AddNewItemButton.module.scss";

const ADD_NEW_HEADER_BUTTON_STYLES: ButtonProps["styles"] = {
  section: {
    marginInlineEnd: "0",
    marginInlineStart: "0",
  },
  label: {
    paddingLeft: "0.2rem",
    paddingRight: "0.5rem",
  },
};

interface Props {
  packageId: string;
  disabled: boolean;
}

const AddNewItemButton = React.memo<Props>(function _AddNewItemButton({
  packageId,
  disabled,
}) {
  const { createGroupBundle, createLineItemBundle } =
    useCreateBundle(packageId);

  return (
    <Stack align="end">
      <Menu
        shadow="md"
        styles={{
          itemSection: CONTEXT_MENU_KEYSTROKE_STYLES,
        }}
        width={185}
      >
        <Menu.Target>
          <Button
            className={styles.root}
            disabled={disabled}
            leftSection={<IconPlus size={16} />}
            rightSection={<IconChevronDown size={14} />}
            styles={ADD_NEW_HEADER_BUTTON_STYLES}
            variant="outline"
          >
            Add New
          </Button>
        </Menu.Target>

        <Menu.Dropdown>
          <Menu.Item
            onClick={createGroupBundle}
            rightSection={KEYSTROKES.CTRL_G}
          >
            Add group
          </Menu.Item>
          <Menu.Divider />
          <Menu.Item
            onClick={createLineItemBundle}
            rightSection={KEYSTROKES.CTRL_A}
          >
            Add line item
          </Menu.Item>
        </Menu.Dropdown>
      </Menu>
    </Stack>
  );
});

export default AddNewItemButton;
