import { TextInput, TextInputProps } from "@mantine/core";
import { ValueGetterParams } from "ag-grid-community";
import React from "react";
import { useCellKeystrokes } from "src/components/EstimationView/Table/hooks/useCellKeystrokes";
import { useMutateAttachment } from "src/components/EstimationView/Table/hooks/useMutateAttachment";
import { Attachment } from "src/data/api/types/shared/attachment";
import { EMPTY_STRING } from "src/utils/empty";
import { setAttachmentTableCell } from "src/utils/setAttachmentTableCell";
import { truncateFilename } from "src/utils/truncateFilename";
import { StateAttachment } from "./constants";
import styles from "./FilenameCell.module.scss";
import { AttachmentsColumnId } from "./utils";

const CHAR_WIDTH = 7;

function calcMaxNameLength(columnWidth: number) {
  // Conversion to max chars; 30px for L+R padding
  return Math.floor((columnWidth - 30) / CHAR_WIDTH);
}

const TEXT_INPUT_STYLES: TextInputProps["styles"] = {
  input: {
    borderRadius: 0,
    fontSize: "var(--mantine-font-size-xs)",
    color: "var(--mantine-color-gray-7)",
  },
};

interface Props {
  attachment: Attachment;
  cellData: ValueGetterParams<StateAttachment>;
}

export const FilenameCell = React.memo<Props>(function _FilenameCell({
  attachment,
  cellData,
}) {
  const inputRef = React.useRef<HTMLInputElement>(null);
  const [isRename, setIsRename] = React.useState<boolean>(false);
  const [isRenameEdited, setIsRenameEdited] = React.useState<boolean>(false);
  const [columnWidth, setColumnWidth] = React.useState<number | undefined>(
    undefined,
  );

  const [colWidthAtRename, setColWidthAtRename] = React.useState<
    number | undefined
  >(undefined);

  const [filename, setFilename] = React.useState<string>(
    columnWidth == null
      ? attachment.filename
      : truncateFilename(attachment.filename, calcMaxNameLength(columnWidth)),
  );

  const { onUpdate } = useMutateAttachment();

  React.useEffect(() => {
    if (cellData == null) return;
    const handleResize = () => {
      setColumnWidth(cellData.column.getActualWidth());
    };

    handleResize();
    cellData.api.addEventListener("columnResized", handleResize);
    return () => {
      if (!cellData.api.isDestroyed()) {
        cellData.api.removeEventListener("columnResized", handleResize);
      }
    };
  }, [cellData]);

  React.useEffect(() => {
    setIsRename(cellData.node?.data?.isRename ?? false);
  }, [cellData]);

  /* 
    Update truncation as column width changes
  */
  React.useEffect(() => {
    if (columnWidth == null) return;
    setFilename(
      truncateFilename(attachment.filename, calcMaxNameLength(columnWidth)),
    );
  }, [attachment.filename, columnWidth]);

  /*
    Manage displayed filename when renaming
  */
  React.useEffect(() => {
    if (isRename) {
      setFilename(attachment.filename); // Display BE value
      setColWidthAtRename(columnWidth);
    } else {
      setFilename((prev) => {
        const fullFilename =
          prev !== attachment.filename ? prev : attachment.filename;

        return colWidthAtRename == null
          ? fullFilename
          : // Display entered value to stop flashing as BE updates
            truncateFilename(fullFilename, calcMaxNameLength(colWidthAtRename));
      });
    }
  }, [attachment.filename, colWidthAtRename, columnWidth, isRename]);

  /*
    When renaming, highlight everything but extension
  */
  React.useEffect(() => {
    if (inputRef.current && !isRenameEdited && isRename) {
      const periodIndex = filename.lastIndexOf(".");
      const highlightStart = 0;
      const highlightEnd = periodIndex !== -1 ? periodIndex : filename.length;
      // eslint-disable-next-line immutable/no-mutation
      inputRef.current.value = filename;
      inputRef.current.setSelectionRange(highlightStart, highlightEnd);
      inputRef.current.focus();
    }
  }, [filename, isRename, isRenameEdited]);

  const handleFilenameChange = React.useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setIsRenameEdited((prev) => (prev === false ? true : prev));
      setFilename(event.currentTarget.value);
    },
    [],
  );

  const { handleEnterBlur: handleKeyPresses } = useCellKeystrokes({
    ref: inputRef,
  });

  /* 
    Prevent table's rowClick event
  */
  const handleClick = React.useCallback(
    (event: React.MouseEvent<HTMLInputElement>) => {
      event.preventDefault();
    },
    [],
  );

  const handleSetIsRename = React.useCallback(() => {
    setAttachmentTableCell({
      cellData,
      columnId: AttachmentsColumnId.ISRENAME,
      value: false,
    });
  }, [cellData]);

  const handleRenameBlur = React.useCallback(() => {
    handleSetIsRename();
    setIsRename(false);
    setIsRenameEdited(false);
    onUpdate({
      attachment,
      filename,
    });
  }, [attachment, filename, handleSetIsRename, onUpdate]);

  return (
    <>
      {isRename ? (
        <TextInput
          key={attachment.id}
          ref={inputRef}
          className={styles.renameField}
          onBlur={handleRenameBlur}
          onChange={handleFilenameChange}
          onClick={handleClick}
          onKeyDown={handleKeyPresses}
          rightSectionPointerEvents={filename !== EMPTY_STRING ? "all" : "none"}
          spellCheck={false}
          styles={TEXT_INPUT_STYLES}
          value={filename}
          variant="unstyled"
        />
      ) : (
        filename
      )}
    </>
  );
});
